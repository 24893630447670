import React, { useState, useEffect, useMemo } from 'react';
import { Modal } from 'react-bootstrap';
import Header from './Header';
import Tabs from './Tabs';
import ComponentDetails from './TabContent/ComponentDetails/ComponentDetails';
import ComponentAiCustomization from './TabContent/CodeCustomization/ComponentAiCustomization';
import styles from './ComponentSettingWrapper.module.scss';
import { mls } from 'lib/multilanguagesupport';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import {
  _supistaApiDelete,
  _supistaApiGet,
  _supistaApiPost,
} from 'lib/server-connection/connections';
import { useLocation } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import './componentSettingWrapper.scss';
import { toast, ToastContainer } from 'react-toastify';
import RoleAccess from './TabContent/RoleAccess/RoleAccess';
import 'react-toastify/dist/ReactToastify.css';
import './tostifyOverrideScss.scss';
import { getConnectedTables } from './TabContent/PDFTemplates/helperFuntion';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import AdditionalSettings from './TabContent/AdditionalSettings/AdditionalSettings';
import { componenetType } from './data/appComponentData';
import {
  getConnectedTablesNew,
  getTableListData,
  removedTableHandlingFunction,
} from './utils/componentSettingFunction';
import TemplatesWrapper from './TabContent/TemplatesWrapper/TemplatesWrapper';

const initialComponentSchema = ({ componentSchema, appDatatable }) => {
  let updatedComponentSchema = {
    componentID: componentSchema?.componentID ?? uuidv4(),
    name: componentSchema?.name ?? '',
    description: componentSchema?.description ?? '',
    type: componentSchema?.type ?? componenetType.table.key,
    table: '',
  };

  if (componentSchema?.table) {
    const defaultTableList = getTableListData({
      appDatatable,
      tableId: componentSchema?.table ?? '',
      giveAllColumns: true,
    });

    const updatedTableList = removedTableHandlingFunction({
      componentTableList: componentSchema?.tableList,
      defaultTableList,
    });
    const changeActionButtonDataToArray = ({ codeCustomization }) => {
      let newCodeCustomization = {};
      Object.keys(codeCustomization ?? {})?.forEach((codeCustomizationkey) => {
        const codeCustomizationElem = codeCustomization[codeCustomizationkey];
        let newActionButtonArray = [];
        Object.keys(codeCustomizationElem?.actionButton ?? {})?.forEach((actionButtonID) => {
          const actionButtonData = codeCustomizationElem?.actionButton[actionButtonID];
          newActionButtonArray = [...newActionButtonArray, { ...actionButtonData, actionButtonID }];
        });
        const updatedCodeCustomizationElem = {
          ...codeCustomizationElem,
          actionButtonArr: [
            ...(codeCustomizationElem?.actionButtonArr ?? []),
            ...newActionButtonArray,
          ],
        };
        delete updatedCodeCustomizationElem?.actionButton;
        newCodeCustomization = {
          ...newCodeCustomization,
          [codeCustomizationkey]: updatedCodeCustomizationElem,
        };
      });
      return newCodeCustomization;
    };
    const updatedCodeCustomization = changeActionButtonDataToArray({
      codeCustomization: componentSchema?.codeCustomization ?? {},
    });
    updatedComponentSchema = {
      ...updatedComponentSchema,
      ...(componentSchema ?? {}),
      tableList: updatedTableList,
      codeCustomization: updatedCodeCustomization,
    };
  }
  return updatedComponentSchema;
};

const fetchComponentData = async ({ componentID, appID }) => {
  const baseUrl = `app/${appID}/CRUD/${componentID}`;
  const filter = `data=%7B%22__d3__filterdata%22%3A%7B%22where%22%3A%7B%7D%7D%2C%22__d3__screenFilter%22%3A%7B%22where%22%3A%7B%7D%7D%7D&lang=English`;
  const finalUrl = `${baseUrl}?${filter}`;
  const componentRes = await _supistaApiGet(finalUrl);
  return componentRes;
};
const ComponentSettingWrapper = ({ data, show, handleClose, isNew, setIsNew }) => {
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);

  const appDatatable = appSchema?.appDatatable ?? {};
  const appComponent = appSchema?.appComponent ?? {};

  const appData = appSchema?.app || {};
  const [tempData, setTempData] = useState({
    app: appData,
    appComponent: appComponent,
  });
  const [componentSchema, setComponentSchema] = useState(() => ({
    ...(initialComponentSchema({ componentSchema: data?.componentSchema ?? {}, appDatatable }) ??
      {}),
    createdAt: data?.componentSchema?.createdAt ?? Date.now(),
    updatedAt: data?.componentSchema?.updatedAt ?? Date.now(),
  }));
  const [isComponetDetailsChange, setIsComponetDetailsChange] = useState(false);

  const [errors, setErrors] = useState({});
  const location = useLocation();
  const appID = location.pathname.split('/')[1];

  const handleComponentsUpdate = async () => {
    const apiUrl = `settings/${appID}/Components`;
    const payloadData = {
      data: {
        ...componentSchema,
        updatedAt: Date.now(),
      },
    };
    const componenetUpdateRes = await _supistaApiPost(apiUrl, payloadData);
    if (componenetUpdateRes.__d3__success) {
      return { skip: false, response: componenetUpdateRes?.response };
    }
    return { skip: true };
  };

  const handleSave = async (isClose = true) => {
    if (validateForm()) {
      const componentsSchmea = await handleComponentsUpdate();
      if (componentsSchmea.skip) {
        return;
      }
      await publishToProduction(appID);
      setIsNew(false);
      setIsComponetDetailsChange(false);
      toast.success(mls('Componenet Saved!'));
      if (isClose) {
        handleClose();
      }
    }
  };

  const handleDelete = () => {
    // Add your delete logic here
    _supistaApiDelete(`settings/${appID}/Components`, {
      data: {
        componentID: componentSchema.componentID,
        name: componentSchema.name,
      },
    })
      .then(async (res) => {
        await publishToProduction(appID);
        handleClose();
      })
      .catch((err) => {});
  };

  const validateForm = () => {
    let formErrors = {};
    if (!componentSchema.name) formErrors.name = 'Name is required';
    if (!componentSchema.type) formErrors.type = 'Component type is required';
    if (!componentSchema.table) formErrors.table = 'Connected table is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    let formErrors = { ...errors };
    if (!value) {
      formErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    } else {
      delete formErrors[name];
    }
    setErrors(formErrors);
  };
  const setConnectedTableList = (value) => {
    setComponentSchema((prevSchema) => ({
      ...prevSchema,
      connectedTableList: value,
    }));
  };
  const setRelatedTableList = (value) => {
    setComponentSchema((prevSchema) => ({
      ...prevSchema,
      tableList: value,
    }));
  };
  const [componentRowList, setComponentRowList] = useState([]);

  const connectedTablesData = useMemo(() => {
    const appDatatable = appSchema?.appDatatable ?? {};

    const connectedTablesData = getConnectedTables({
      tableId: componentSchema?.table ?? '',
      appDatatable,
    });
    return connectedTablesData;
  }, [componentSchema?.table, appSchema?.appDatatable]);
  const connectedTablesNew = useMemo(() => {
    const appDatatable = appSchema?.appDatatable ?? {};
    const connectedTablesData = getConnectedTablesNew({
      tableId: componentSchema?.table ?? '',
      appDatatable,
    });
    return connectedTablesData;
  }, [componentSchema?.table, appSchema?.appDatatable]);
  useUpdateEffect(() => {
    const relatedTableList = getTableListData({
      tableId: componentSchema?.table ?? '',
      appDatatable,
    });
    setRelatedTableList(relatedTableList);
    setConnectedTableList(Object.keys(connectedTablesData ?? {}) ?? []);
  }, [componentSchema?.table, appSchema?.appDatatable]);

  const updateRowListData = async ({ componentID, appID }) => {
    const componentRes = await fetchComponentData({ componentID, appID });
    setComponentRowList(componentRes?.rows ?? []);
  };
  useEffect(() => {
    if (!isNew && componentSchema?.table) {
      updateRowListData({ componentID: componentSchema?.componentID, appID });
    }
  }, [componentSchema?.componentID, appID, isNew]);
  // console.log('connectedTablesNew', connectedTablesNew);
  // console.log('connectedTablesData', connectedTablesData);

  return (
    <Modal
      show={show}
      dialogClassName={styles.modalFullscreen}
      size='fullscreen'
      className={`ComponentSettingWrapper ${styles.ComponentSettingWrapper}`}
    >
      <ToastContainer position={'bottom-left'} theme={'colored'} />
      <Modal.Header className={styles.header}>
        <Header
          title='Components'
          subtitle={componentSchema.name || 'Untitled Component'}
          handleClose={handleClose}
          handleSave={handleSave}
          handleDelete={handleDelete}
          isNew={isNew}
        />
      </Modal.Header>
      <Modal.Body className={styles.body}>
        <Tabs
          validateForm={validateForm}
          st
          isNew={isNew}
          isComponetDetailsChange={isComponetDetailsChange}
        >
          <div label={mls('Component Details')}>
            <ComponentDetails
              schema={componentSchema}
              setSchema={setComponentSchema}
              connectedTablesData={connectedTablesData}
              setConnectedTableList={setConnectedTableList}
              errors={errors}
              handleBlur={handleBlur}
              handleSave={handleSave}
              appDatatable={appDatatable}
              setIsComponetDetailsChange={setIsComponetDetailsChange}
              isComponetDetailsChange={isComponetDetailsChange}
              isNew={isNew}
              appID={appID}
            />
          </div>
          {/* <div label={mls('Screen Group Settings')}>
            <ScreenGroupSettings
              schema={connectedScreenGroupSchema}
              // setSchema={setConnectedScreenGroupSchema}
              setSchema={() => {}}
              componentSchemaList={componentSchemaList}
              setComponentSchemaList={setComponentSchemaList}
              appDatatable={appDatatable}
              appComponent={appComponent}
              tempData={tempData}
              setTempData={setTempData}
              selectedTable={componentSchema.table}
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              updatedConnectedScreeens={updatedConnectedScreeens}
              setUpdatedConnectedScreeens={setUpdatedConnectedScreeens}
            />
          </div> */}
          <div label={mls('Component AI Customization')}>
            <ComponentAiCustomization
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              appDatatable={appDatatable}
              componentRowList={componentRowList}
              // connectedTablesData={connectedTablesData}
              connectedTablesData={connectedTablesNew}
              flatedConnectedTablesData={connectedTablesData}
              appID={appID}
            />
          </div>
          {/* <div label={mls('Downloads')}>
            <PDFTemplateWrapper
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              appDatatable={appDatatable}
              appID={appID}
              appComponent={tempData.appComponent}
            />
          </div>
          <div label={mls('Notifications')}>
            <EmailTemplateWrapper
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              appDatatable={appDatatable}
              appID={appID}
              appComponent={tempData.appComponent}
            />
          </div> */}
          <div label={mls('Templates')}>
            <TemplatesWrapper
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              appDatatable={appDatatable}
              componentRowList={componentRowList}
              appID={appID}
              appComponent={tempData.appComponent}
            />
          </div>
          <div label={mls('Role Access')}>
            <RoleAccess
              componentSchema={componentSchema}
              setComponentSchema={setComponentSchema}
              appDatatable={appDatatable}
              appID={appID}
              appComponent={tempData.appComponent}
              connectedTablesData={connectedTablesNew}
              appSchema={appSchema}
            />
          </div>
          <div label={mls('Additional Settings')}>
            <AdditionalSettings
              componentSchema={componentSchema}
              groupedConnectedTablesData={componentSchema}
              connectedTablesData={connectedTablesNew}
              setConnectedTableList={setConnectedTableList}
              setRelatedTableList={setRelatedTableList}
              appDatatable={appDatatable}
              appSchema={appSchema}
              appComponent={tempData.appComponent}
            />
          </div>
        </Tabs>
      </Modal.Body>
    </Modal>
  );
};

export default ComponentSettingWrapper;
