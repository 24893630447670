import React, { useState } from 'react';
// import styles from '../ComponentSettingWrapper.module.scss';
import styles from './queryGenerator.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { Button, Form } from 'react-bootstrap';
import { CircularProgress } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { _supistaApiPost } from 'lib/server-connection/connections';
import AIToQuery from './AIToQuery';
import { FaHistory } from 'react-icons/fa';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import ExpandingTextarea from 'lib/reusable-components/Components/ExpandingTextarea/ExpandingTextarea';
import { getAIPayloadData } from './QueryGeneratorFunctions';

interface queryGeneratorProps {
  newConnectedTableData: any;
  selectedCode: any;
  appID: string;
  code: string;
  handleCodeChange: Function;
  activeButtonKey: string;
  buttonType: string;
  selectedTable: string;
  selectedParentId: any;
  connectedTablesData: { [key: string]: any };
  selectedId: any;
  componentSchema: any;
  inputData: string;
  tableSchema: { [key: string]: any }[];
}
const QueryGenerator = ({
  newConnectedTableData,
  appID,
  handleCodeChange,
  code,
  activeButtonKey,
  selectedCode,
  buttonType,
  selectedTable,
  connectedTablesData,
  selectedParentId,
  selectedId,
  componentSchema,
  inputData,
  tableSchema,
}: queryGeneratorProps) => {
  const [query, setQuery] = useState('');
  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);
  const [isAIQuaryPopup, setIsAIQuaryPopup] = useState(false);
  const [aiQuaryData, setAiQuaryData] = useState('');
  const [useCurrentCode, setUseCurrentCode] = useState(true);
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appDatatable = appSchema?.appDatatable ?? {};
  const handleQueryChange = (e: any) => {
    setQuery(e.target.value);
  };

  const handleAiRequest = async (e: any) => {
    e.preventDefault();
    const baseUrl = `aiQuery/${appID}`;
    const payloadData = getAIPayloadData({
      connectedTablesData,
      selectedCode,
      newConnectedTableData,
      appDatatable,
      selectedTable,
      query,
      buttonType,
      activeButtonKey,
      componentSchema,
      useCurrentCode,
      selectedParentId,
      selectedId,
      code,
      inputData,
      tableSchema,
    });

    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data: payloadData });
    if (componentRes?.[0]) {
      setIsAIQuaryPopup(true);
      setAiQuaryData(componentRes[0]?.response ?? '');
      setIsAiQueryLoading(false);
    } else {
      setIsAiQueryLoading(false);
    }
  };

  return (
    <div>
      {isAIQuaryPopup ? (
        <AIToQuery
          isAIQuaryPopup={isAIQuaryPopup}
          aiQuaryData={aiQuaryData}
          setIsAIQuaryPopup={setIsAIQuaryPopup}
          handleCodeChange={handleCodeChange}
          code={code}
        />
      ) : null}
      <Form className={styles.queryGenerator} onSubmit={handleAiRequest}>
        <div className={styles.queryHistoryButton} onClick={() => setIsAIQuaryPopup(true)}>
          <FaHistory />
        </div>
        {/* <div
          className={styles.useCurrentCodeTooggle}
          onClick={() => setUseCurrentCode(!useCurrentCode)}
          title={mls('Use Current Code!')}
        >
          <SlideSwitch isChecked={useCurrentCode} setIsChecked={() => {}} />
        </div> */}
        <Form.Group controlId='queryInput' className={styles.queryInput}>
          <div className={styles.queryInputInner}>
            <ExpandingTextarea
              // placeholder={`Generate Code using AI ...`}
              placeholder={mls(`Write steps to generate code...`)}
              name={'queryInput'}
              value={query}
              onChange={handleQueryChange}
            />
          </div>
        </Form.Group>
        <Button variant='primary' onClick={handleAiRequest} className={styles.generateButton}>
          {isAiQueryLoading ? (
            <>
              <CircularProgress color='inherit' size={12} />
              &nbsp;
            </>
          ) : (
            <SendIcon fontSize={'small'} />
          )}
          &nbsp;
          {mls('Send')}
        </Button>
      </Form>
    </div>
  );
};

export default QueryGenerator;
