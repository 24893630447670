import React, { useState } from 'react';
import styles from './AppAssets.module.scss';
import './AppAssets.scss';
import { mls } from 'lib/multilanguagesupport';
import {
  _supistaApiDelete,
  _supistaApiGetRawResponse,
  _supistaApiPost,
} from 'lib/server-connection/connections';
import { useLocation } from 'react-router-dom';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import { FaImage } from 'react-icons/fa';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import { SlOptionsVertical } from 'react-icons/sl';
import { IoMdDownload } from 'react-icons/io';
import ShowAssets from './ShowAssets';
import UploadAssets from './UploadAssets';
import { CircularProgress } from '@material-ui/core';
const AppAssets = () => {
  const [isNewAssets, setIsNewAssets] = useState(false);
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const [isSelectedAssetsPopup, setIsSelectedAssetsPopup] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [selectedAssetName, setSelectedAssetName] = useState('');
  const appData = appSchema?.app ?? {};

  return (
    <div className={`AppAssets ${styles.AppAssets}`}>
      <div className={styles.appAssetsListContainer}>
        <div className={styles.AppAssetsHeader}>
          <div className={styles.appAssetsListTitle}>{mls('Assets')}</div>
          {isNewAssets ? (
            <UploadAssets
              isNewAssets={isNewAssets}
              setIsNewAssets={setIsNewAssets}
              appID={appID}
              appData={appData}
            />
          ) : null}
          {isSelectedAssetsPopup ? (
            <ShowAssets
              isSelectedAssetsPopup={isSelectedAssetsPopup}
              setIsSelectedAssetsPopup={setIsSelectedAssetsPopup}
              selectedAsset={selectedAsset}
              setSelectedAsset={setSelectedAsset}
              setSelectedAssetName={setSelectedAssetName}
              selectedAssetName={selectedAssetName}
            />
          ) : null}
          <button
            className='primaryButton'
            type='button'
            onClick={() => {
              setIsNewAssets(true);
            }}
          >
            {mls('Upload Assest')}
          </button>
        </div>
        <div className={styles.appAssetsList}>
          {React.Children.toArray(
            Object.keys(appData?.assets ?? {}).map((appAssetskey: string, index: number) => {
              const appAssetsElem = appData?.assets?.[appAssetskey];
              return (
                <AppAssetsListElem
                  appAssetsElem={appAssetsElem}
                  index={index}
                  appData={appData}
                  appID={appID}
                  selectedAsset={selectedAsset}
                  setSelectedAsset={setSelectedAsset}
                  setIsSelectedAssetsPopup={setIsSelectedAssetsPopup}
                  setSelectedAssetName={setSelectedAssetName}
                />
              );
            })
          )}

          {(appData?.assets ?? []).length === 0 ? (
            <div style={{ padding: '1rem' }}>
              {mls("No assets available. Click 'Upload Assets' to add new assets.")}
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default AppAssets;
const AppAssetsListElem = ({
  appAssetsElem,
  index,
  appID,
  appData,
  selectedAsset,
  setSelectedAsset,
  setIsSelectedAssetsPopup,
  setSelectedAssetName,
}: {
  appAssetsElem: { [key: string]: any };
  appData: { [key: string]: any };
  index: number;
  appID: string;
  selectedAsset: string;
  setSelectedAsset: Function;
  setIsSelectedAssetsPopup: Function;
  setSelectedAssetName: Function;
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const handleShowImage = async () => {
    setIsLoading(true);
    const endpoint = `manageFile/${appID}/assets/${appID}/${appAssetsElem?.fileUrl}`;
    const response: any = await _supistaApiGetRawResponse(endpoint);
    const blob = await response.blob();
    const blobUrl = URL.createObjectURL(blob);
    setSelectedAsset(blobUrl);
    setIsSelectedAssetsPopup(true);
    const assetName = appAssetsElem?.fileName ?? 'Sample.png';
    setSelectedAssetName(assetName);
    setIsLoading(false);
  };

  const handleDownloadAsset = async () => {
    setIsLoading(true);
    const endpoint = `manageFile/${appID}/assets/${appID}/${appAssetsElem?.fileUrl}`;
    const response: any = await _supistaApiGetRawResponse(endpoint);
    const blob = await response.blob();

    const blobUrl = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = blobUrl;

    a.download = appAssetsElem?.fileName ?? 'Sample.png';

    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);

    URL.revokeObjectURL(blobUrl);
    setIsLoading(false);
  };
  const handleRemoveAssets = async () => {
    const endpoint = `manageFile/${appID}/assets/${appID}/${appAssetsElem?.fileUrl}`;
    setIsLoading(true);
    const response = await _supistaApiDelete(endpoint, {});
    if (response?.__d3__error) {
      setIsLoading(false);
      return { err: 'Could not upload file on the server!' };
    }

    const newAppAssets = { ...(appData?.assets ?? {}) };
    delete newAppAssets[appAssetsElem?.fileUrl];
    _supistaApiPost(`settings/${appID}/App`, {
      data: {
        ...appData,
        assets: newAppAssets,
      },
    })
      .then(async (res) => {
        await publishToProduction(appID);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };
  const optionObject = {
    view: {
      key: 'view',
      name: 'View',
    },
    download: {
      key: 'download',
      name: 'Download',
    },
    delete: {
      key: 'delete',
      name: 'Delete',
    },
  };
  const handleAssetsOption = (elem: any) => {
    if (optionObject.view.key === elem) {
      handleShowImage();
    }
    if (optionObject.download.key === elem) {
      handleDownloadAsset();
    }
    if (optionObject.delete.key === elem) {
      handleRemoveAssets();
    }
  };
  return (
    <div key={index} className={styles.appAssetsListElem}>
      {isLoading ? (
        <div className={styles.appAssetsLoader}>
          <CircularProgress />
        </div>
      ) : null}
      <div className={styles.appAssetsListElemHeader}>
        <div
          className={styles.appAssetsListCross}
          onClick={() => handleAssetsOption(optionObject.download.key)}
        >
          <IoMdDownload />
        </div>
        <div className={styles.appAssetsListOption}>
          <ObjectDropDown
            dropDownValue={''}
            object={optionObject}
            setdropDownValue={handleAssetsOption}
            ObjectInObject
            isChildernInsert
            className={'appAssetsListOptionDropDown'}
          >
            <div>
              <SlOptionsVertical />
            </div>
          </ObjectDropDown>
        </div>
      </div>
      <div
        className={styles.appAssetsImage}
        onClick={() => handleAssetsOption(optionObject.view.key)}
      >
        <FaImage />
      </div>
      <div
        className={styles.appAssetsListName}
        onClick={() => handleAssetsOption(optionObject.view.key)}
      >
        {appAssetsElem?.assetName}
      </div>
    </div>
  );
};
