import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import styles from '../columnSettings.module.scss';
import React from 'react';
import AddSelectOptions from './AddSelectOptions';
import OptionField from './OptionField';
import SelectMenuField from './SelectMenuField';
const typeOption = {
  text: {
    key: 'text',
    name: 'Text',
  },
  number: {
    key: 'number',
    name: 'Number',
  },
  decimal: {
    key: 'decimal',
    name: 'Decimal',
  },
};
interface selectDataTypeCaseProps {
  columnData: any;
  updateColumnData: Function;
}
const SelectDataTypeCase = ({ columnData, updateColumnData }: selectDataTypeCaseProps) => {
  const selectMenu = columnData?.options?.selectMenu ?? [];
  const type = columnData?.options?.type ?? typeOption.text.key;
  const updateDataByIndex = ({
    index,
    arrayData,
    newData,
  }: {
    index: number;
    arrayData: any[];
    newData: any;
  }) => {
    const copyArrayData = Array.from(arrayData);
    copyArrayData.splice(index, 1);
    copyArrayData.splice(index, 0, newData);
    return copyArrayData;
  };
  const udpateSelectMenu = ({
    optionData,
    optionIndex,
  }: {
    optionData: any;
    optionIndex: number;
  }) => {
    const updatedSelectMenu = updateDataByIndex({
      index: optionIndex,
      arrayData: selectMenu ?? [],
      newData: optionData,
    });
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };

  const deleteOptionValidation = (indx: number) => {
    const updatedSelectMenu = (selectMenu ?? []).filter((_: any, index: number) => index !== indx);
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };
  const updateIsMultiple = (action: boolean) => {
    updateColumnData({ key: 'isMultiple', value: action });
  };

  const handleColTypeChange = (e: any) => {
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), type: e.target.value, selectMenu: [] },
    });
  };
  const updateSelectMenu = ({ updatedSelectMenu }: { updatedSelectMenu: any }) => {
    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };
  return (
    <>
      <div className={styles.userOuterContainer}>
        <div className='fs-6 fw-bolder text-dark'>{mls('Options')}</div>
        <div className='row align-items-center'>
          <div className='col-1'>
            <div className='fs-6 fw-bolder text-dark'>{mls('Type')}:</div>
          </div>
          <div className='col-4'>
            <select
              name='dataType'
              onChange={handleColTypeChange}
              className='form-select form-control form-control-lg form-control-solid'
              value={type}
            >
              {React.Children.toArray(
                Object.keys(typeOption).map((typekey) => {
                  const dataTypeElem = typeOption[typekey as keyof typeof typeOption];
                  return <option value={dataTypeElem.key}>{mls(dataTypeElem.name)}</option>;
                })
              )}
            </select>
          </div>
        </div>
        <div className='fs-6 fw-bolder text-dark'>{mls('Select Menu')}</div>
        {(selectMenu ?? [])?.length === 0 ? (
          <div className='text-muted  mx-6 '>{mls('No options available.')}</div>
        ) : (
          <div className='row'>
            <div className='col-4 ' style={{ marginRight: 0, marginLeft: 60 }}>
              <label className='form-label mx-4 fs-6 fw-bolder text-dark'>{mls('Value')}</label>
            </div>
            <div className='col-4 ' style={{ marginRight: -20, marginLeft: -50 }}>
              <label className='form-label mx-6 fs-6 fw-bolder text-dark'>{mls('Label')}</label>
            </div>
            <div className='col-4 ' style={{ marginRight: -20, marginLeft: 20 }}>
              <label className='form-label mx-4 fs-6 fw-bolder text-dark'>{mls('Color')}</label>
            </div>
          </div>
        )}
        <SelectMenuField
          selectMenu={selectMenu}
          udpateSelectMenu={udpateSelectMenu}
          deleteOptionValidation={deleteOptionValidation}
          columnData={columnData}
          updateColumnData={updateColumnData}
        />

        <AddSelectOptions updateSelectMenu={updateSelectMenu} selectMenu={selectMenu} />
        <div className={styles.checkBoxOuterContainer}>
          <div className={styles.checkBoxInnerContainer}>
            <label className='fs-6 fw-bolder text-dark '>{mls('Is Multiple')}:</label>
            <CheckBox checked={columnData?.isMultiple ?? false} onClick={updateIsMultiple} />
          </div>
        </div>
      </div>
    </>
  );
};
export default SelectDataTypeCase;
