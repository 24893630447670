import { erpAllDataType, erpSimpleDataType } from 'lib/erp-data-table/components/data/erpData';
import { getDefaultCodeForFirstType } from '../utils/componentSettingFunction';
import { specialDataTypeName } from '../data/appComponentData';

export const getTableList = ({
  tableSchema,
  newConnectedTableData,
  appDatatable,
  // mainTableName,
  selectedParentTableData,
}: {
  tableSchema: any[];
  newConnectedTableData: { [key: string]: any };
  appDatatable: { [key: string]: any };
  selectedParentTableData: { [key: string]: any };
  // mainTableName: string;
}) => {
  let tableList: any[] = [];
  let specialCaseBelongsToManyTableList: any[] = [];
  (tableSchema ?? []).forEach(({ tableID, tableUsed }: { tableID: string; tableUsed: string }) => {
    const tableElem = appDatatable?.[tableID];

    const allBelongsToManyRealtionData = getBelongsToManyRealtionData({
      newConnectedTableData,
    });

    let relationDataToMainTable = newConnectedTableData?.[tableID];
    let extraRealtionData = {};
    let isSpecialCaseBelongsToMany = false;
    const belongsToManyData: any =
      allBelongsToManyRealtionData[tableID as keyof typeof allBelongsToManyRealtionData];

    if (belongsToManyData) {
      isSpecialCaseBelongsToMany = true;
      extraRealtionData = {
        relationID: belongsToManyData?.relationID,
        relationWithAnotherTable: belongsToManyData?.relationWithAnotherTable,
        relatedTableName: belongsToManyData?.relatedTableName,
        // relationDescription: belongsToManyData?.relationDescription,
      };
    }
    if (!relationDataToMainTable) {
      const realtionData: any = Object.values(selectedParentTableData?.relations ?? {}).find(
        (relationElem: any) => {
          return relationElem?.target === tableID || relationElem?.through === tableID;
        }
      );

      if (realtionData) {
        relationDataToMainTable = {
          relationType: realtionData?.type,
          relationID: realtionData?.as,
          // relationDescription: realtionData?.relationDescription,
        };
      }
    }
    if (relationDataToMainTable && !isSpecialCaseBelongsToMany) {
      extraRealtionData = {
        relationWithMainTable: relationDataToMainTable?.relationType,
        relationID: relationDataToMainTable?.relationID,
        // relationDescription: relationDataToMainTable?.relationDescription ?? '',
      };
    }
    if (tableElem) {
      let columns = '';
      (tableElem?.columnSchema ?? []).forEach((columnElem: any, index: number) => {
        let options = '';
        if (columnElem?.dataType === erpAllDataType.select.key) {
          (columnElem?.options?.selectMenu ?? []).forEach((selectMenuElem: any, index: number) => {
            if (index === 0) {
              options = ` <${selectMenuElem?.value}`;
            } else {
              options = `${options}, ${selectMenuElem?.value}`;
            }
          });
          if (options) {
            options = `${options}>`;
          }
        }
        const newDatatype =
          erpAllDataType?.[columnElem?.dataType as keyof typeof erpAllDataType]?.simpleDataType
            ?.key ?? erpSimpleDataType.text.key;
        if (index === 0) {
          columns = `${columnElem?.columnName} (${newDatatype})${options}`;
        } else {
          columns = `${columns}, ${columnElem?.columnName} (${newDatatype})${options}`;
        }
      });
      let finalTableListElem = {
        tableName: tableElem?.tableID,
        columns: columns,
        relationDescription: tableUsed,
        // [`${mainTableName} - ${tableElem?.tableID} - relations`]: tableUsed,
      };
      if (relationDataToMainTable || isSpecialCaseBelongsToMany) {
        finalTableListElem = {
          ...finalTableListElem,
          ...extraRealtionData,
        };
      }
      if (isSpecialCaseBelongsToMany) {
        specialCaseBelongsToManyTableList = [
          ...specialCaseBelongsToManyTableList,
          finalTableListElem,
        ];
      } else {
        tableList = [...tableList, finalTableListElem];
      }
    }
  });
  return { tableList: [...tableList, ...specialCaseBelongsToManyTableList] };
};
interface getAIPayloadDataProps {
  connectedTablesData: { [key: string]: any };
  selectedCode: { [key: string]: any };
  newConnectedTableData: { [key: string]: any };
  appDatatable: { [key: string]: any };
  selectedTable: string;
  query: string;
  buttonType: string;
  activeButtonKey: string;
  componentSchema: { [key: string]: any };
  useCurrentCode: boolean;
  selectedParentId: number | string;
  selectedId: number | string;
  code: string;
  inputData: string;
  tableSchema: any[];
}
export const getAIPayloadData = ({
  connectedTablesData,
  selectedCode,
  newConnectedTableData,
  appDatatable,
  selectedTable,
  query,
  buttonType,
  activeButtonKey,
  componentSchema,
  useCurrentCode,
  selectedParentId,
  selectedId,
  code,
  inputData,
  tableSchema,
}: getAIPayloadDataProps) => {
  const selectedParentTableData = connectedTablesData[componentSchema?.table];
  const { tableList } = getTableList({
    tableSchema: tableSchema,
    newConnectedTableData,
    appDatatable,
    selectedParentTableData: selectedParentTableData,
  });
  const selectedTableData = connectedTablesData[selectedTable];

  const columnString: string = getColumnSchemaInString({
    columnSchema: selectedTableData?.columnSchema ?? [],
  });
  let inputObject = {};
  try {
    inputObject = JSON.parse(inputData);
  } catch (error) {
    inputObject = {};
  }
  let payloadData: { [key: string]: any } = {
    queryArray: [
      {
        promptType: 'CRUD',
        query: query,
        apiType: buttonType === 'actionButton' ? 'PUT' : activeButtonKey,
      },
    ],
    code: '',
    input: inputObject,
    data: {
      tableList: tableList,
      selectedTable: {
        columns: columnString,
        tableName: selectedTableData?.tableID,
      },

      // mainTable: {
      //   columns: columnString,
      //   tableName: newConnectedTableData?.tableName?.[0] || newConnectedTableData?.tableName,
      // },
    },
    // data: {
    //   tableName: newConnectedTableData?.tableName,
    //   columnSchema: newConnectedTableData?.columnSchema,
    // },
  };

  const isRealationTypeIsNotMain = selectedTableData?.relationType !== 'MAIN';
  if (isRealationTypeIsNotMain) {
    const columns: string = getColumnSchemaInString({
      columnSchema: selectedParentTableData?.columnSchema ?? [],
    });
    payloadData = {
      ...payloadData,
      data: {
        ...(payloadData?.data ?? {}),
        // parentTable: componentSchema?.table,
        parentTable: {
          tableName: componentSchema?.table,
          columns,
          relationWithSelectedTable: selectedTableData?.relationType,
          relationDescription: selectedTableData?.relationDescription ?? '',
          relationID: selectedTableData?.relationID,
        },
        relationType: selectedTableData?.relationType,
      },
    };
  }
  if (useCurrentCode) {
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: code,
      buttonType,
      activeButtonKey,
      mainTableID: componentSchema?.table,
    });
    payloadData = {
      ...payloadData,
      code: finalCode,
    };
  }
  return payloadData;
};
export const getColumnSchemaInString = ({ columnSchema }: { columnSchema: any[] }) => {
  let columnString: string = '';
  (columnSchema || []).forEach((columnElem: any, index: number) => {
    const newDatatype =
      erpAllDataType?.[columnElem?.dataType as keyof typeof erpAllDataType]?.simpleDataType?.key ??
      erpSimpleDataType.text.key;

    if (index === 0) {
      columnString = `${columnElem?.columnName} (${newDatatype})`;
    } else {
      columnString = `${columnString}, ${columnElem?.columnName} (${newDatatype})`;
    }
  });
  return columnString;
};
const getBelongsToManyRealtionData = ({
  newConnectedTableData,
}: {
  newConnectedTableData: { [key: string]: any };
}) => {
  let mainBelongsToManyData: { [key: string]: any } = {};
  let belongsToManyData = {};
  Object.keys(newConnectedTableData).forEach((connectedTableKey) => {
    const connectedTableElem = newConnectedTableData[connectedTableKey];
    Object.keys(connectedTableElem?.relations ?? {}).forEach((relationsKey) => {
      const relationsElem = connectedTableElem?.relations[relationsKey];
      if (relationsElem?.type === specialDataTypeName.belongsToMany.key) {
        if (mainBelongsToManyData?.[relationsElem?.through]) {
          return;
        }
        if (connectedTableElem?.relationType === specialDataTypeName.MAIN.key) {
          mainBelongsToManyData = {
            ...mainBelongsToManyData,
            [relationsElem?.through]: relationsElem,
          };
          return;
        }

        const columns = '';
        const newData = {
          relationWithAnotherTable: specialDataTypeName.belongsToMany.key,
          relationID: relationsElem?.as,
          tableName: relationsElem?.through,
          relationDescription: relationsElem?.relationDescription ?? '',

          relatedTableName: connectedTableElem?.tableID,

          columns: columns,
        };

        belongsToManyData = {
          ...belongsToManyData,
          [relationsElem?.through]: newData,
        };
      }
      // console.log(relationsElem);
      // console.log(relationsElem?.type);
      // console.log(' relationTypeObjects.belongsToMany.key', relationTypeObjects.belongsToMany.key);
    });
  });
  return belongsToManyData;
};
