export const erpValidationTypes = {
  isRequired: { key: 'isRequired', name: ' Is Required', defaultMessage: 'This field is required' },
  isLength: {
    key: 'isLength',
    name: 'Is Length',
    defaultMessage: 'The length of the value should be between minmum and maximum',
  },
  isEmail: {
    key: 'isEmail',
    name: 'Is Email',
    defaultMessage: 'This field should be in the email format',
  },
  isNumeric: {
    key: 'isNumeric',
    name: 'Is Numeric',
    defaultMessage: 'This field should be in the Numeric format',
  },
  isAlphabet: {
    key: 'isAlphabet',
    name: 'Is Alphabet',
    defaultMessage: 'This field should be an alphabet',
  },
  isBool: { key: 'isBool', name: 'Is Bool', defaultMessage: 'Use Boolen in this filed' },
  isCreditCard: {
    key: 'isCreditCard',
    name: 'Is CreditCard',
    defaultMessage: 'This field should be in creditcard format',
  },
  isCurrency: {
    key: 'isCurrency',
    name: 'Is Currency',
    defaultMessage: 'This field should be in currency format',
  },
  isDate: { key: 'isDate', name: 'Is Date', defaultMessage: '' },
  isUppercase: {
    key: 'isUppercase',
    name: 'Is Uppercase',
    defaultMessage: 'Use Uppercase in this filed',
  },
  isLowercase: {
    key: 'isLowercase',
    name: 'Is Lowercase',
    defaultMessage: 'Use Lowercase in this filed',
  },
  isMobile: {
    key: 'isMobile',
    name: 'Is Mobile',
    defaultMessage: 'This field should be in mobile format',
  },
  isUri: { key: 'isUri', name: 'Is Uri', defaultMessage: 'This field should be in url format' },
};

export const erpSimpleDataType = {
  text: {
    key: 'text',
    name: 'Text',
  },
  number: {
    key: 'number',
    name: 'Number',
  },
  date: {
    key: 'date',
    name: 'Date',
  },
  ID: {
    key: 'ID',
    name: 'ID',
  },
  boolean: {
    key: 'boolean',
    name: 'Boolean',
  },
};
export const erpAllDataType = {
  ID: {
    key: 'ID',
    name: 'ID',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.ID,
  },
  text: {
    key: 'text',
    name: 'Text',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isLength: erpValidationTypes.isLength,
      isEmail: erpValidationTypes.isEmail,
      isAlphabet: erpValidationTypes.isAlphabet,
      isUppercase: erpValidationTypes.isUppercase,
      isLowercase: erpValidationTypes.isLowercase,
      isCreditCard: erpValidationTypes.isCreditCard,
      isUri: erpValidationTypes.isUri,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  number: {
    key: 'number',
    name: 'Number',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isLength: erpValidationTypes.isLength,
      isNumeric: erpValidationTypes.isNumeric,
      isCreditCard: erpValidationTypes.isCreditCard,
      isCurrency: erpValidationTypes.isCurrency,
      isMobile: erpValidationTypes.isMobile,
    },
    defaultValidation: {
      isNumeric: erpValidationTypes.isNumeric,
    },
    simpleDataType: erpSimpleDataType.number,
  },
  decimal: {
    key: 'decimal',
    name: 'Decimal',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isLength: erpValidationTypes.isLength,
      isNumeric: erpValidationTypes.isNumeric,
      isCurrency: erpValidationTypes.isCurrency,
      isCreditCard: erpValidationTypes.isCreditCard,
    },
    defaultValidation: {
      isNumeric: erpValidationTypes.isNumeric,
    },
    simpleDataType: erpSimpleDataType.number,
  },
  password: {
    key: 'password',
    name: 'Password',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isCreditCard: erpValidationTypes.isUppercase,
      isLowercase: erpValidationTypes.isLowercase,
      isLength: erpValidationTypes.isLength,
      isNumeric: erpValidationTypes.isNumeric,
      isAlphabet: erpValidationTypes.isAlphabet,
    },
    defaultValidation: {
      isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  foreignDataSelect: {
    key: 'foreignDataSelect',
    name: 'Foreign key',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  formula: {
    key: 'formula',
    name: 'Formula',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  multipleSelect: {
    key: 'multipleSelect',
    name: 'MultipleSelect',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isLength: erpValidationTypes.isLength,
      isNumeric: erpValidationTypes.isNumeric,
      isAlphabet: erpValidationTypes.isAlphabet,
      isUppercase: erpValidationTypes.isUppercase,
      isLowercase: erpValidationTypes.isLowercase,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  select: {
    key: 'select',
    name: 'Select',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isLength: erpValidationTypes.isLength,
      isNumeric: erpValidationTypes.isNumeric,
      isAlphabet: erpValidationTypes.isAlphabet,
      isUppercase: erpValidationTypes.isUppercase,
      isLowercase: erpValidationTypes.isLowercase,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  multiple: {
    key: 'multiple',
    name: 'Multiple',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  date: {
    key: 'date',
    name: 'Date',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    simpleDataType: erpSimpleDataType.date,
  },
  time: {
    key: 'time',
    name: 'Time',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    simpleDataType: erpSimpleDataType.date,
  },
  dateTime: {
    key: 'dateTime',
    name: 'Date and Time',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    simpleDataType: erpSimpleDataType.date,
  },
  datePicker: {
    key: 'datePicker',
    name: 'Date',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    simpleDataType: erpSimpleDataType.date,
  },
  checkbox: {
    key: 'checkbox',
    name: 'Checkbox',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isBool: erpValidationTypes.isBool,
    },
    defaultValidation: {
      isBool: erpValidationTypes.isBool,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  switch: {
    key: 'switch',
    name: 'Switch',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isBool: erpValidationTypes.isBool,
    },
    defaultValidation: {
      isBool: erpValidationTypes.isBool,
    },
    simpleDataType: erpSimpleDataType.boolean,
  },
  file: {
    key: 'file',
    name: 'File',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  image: {
    key: 'image',
    name: 'Image',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  user: {
    key: 'user',
    name: 'User',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  belongsTo: {
    key: 'belongsTo',
    name: 'Belongs To',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  relatedField: {
    key: 'relatedField',
    name: 'Related Field',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  multipleUser: {
    key: 'multipleUser',
    name: 'Multiple Users',
    validation: {
      isRequired: erpValidationTypes.isRequired,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  url: {
    key: 'url',
    name: 'Url',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isUri: erpValidationTypes.isUri,
    },
    defaultValidation: {
      isRequired: erpValidationTypes.isUri,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  detailedNotes: {
    key: 'detailedNotes',
    name: 'Detailed Notes',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isLength: erpValidationTypes.isLength,
      isUppercase: erpValidationTypes.isUppercase,
      isLowercase: erpValidationTypes.isLowercase,
      isAlphabet: erpValidationTypes.isAlphabet,
      isNumeric: erpValidationTypes.isNumeric,
    },
    defaultValidation: {
      // isRequired: erpValidationTypes.isRequired,
    },
    simpleDataType: erpSimpleDataType.text,
  },
  __d3__CreatedAtDate: {
    key: '__d3__CreatedAtDate',
    name: 'Create Date',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    defaultReadOnly: true,
    simpleDataType: erpSimpleDataType.date,
  },
  __d3__CreatedAtTime: {
    key: '__d3__CreatedAtTime',
    name: 'Create Time',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    defaultReadOnly: true,
    simpleDataType: erpSimpleDataType.date,
  },
  __d3__UpdatedAtDate: {
    key: '__d3__UpdatedAtDate',
    name: 'Update Date',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    defaultReadOnly: true,
    simpleDataType: erpSimpleDataType.date,
  },
  __d3__UpdatedAtTime: {
    key: '__d3__UpdatedAtTime',
    name: 'Update Time',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    defaultReadOnly: true,
    simpleDataType: erpSimpleDataType.date,
  },
  __d3__lastUpdatedBy: {
    key: '__d3__lastUpdatedBy',
    name: 'Last Updated By',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    defaultReadOnly: true,
    simpleDataType: erpSimpleDataType.date,
  },
  __d3__createdBy: {
    key: '__d3__createdBy',
    name: 'Created By',
    validation: {
      isRequired: erpValidationTypes.isRequired,
      isDate: erpValidationTypes.isDate,
    },
    defaultValidation: {
      isDate: erpValidationTypes.isDate,
    },
    defaultReadOnly: true,
    simpleDataType: erpSimpleDataType.date,
  },
};
export const erpNormalDataType = [
  erpAllDataType.text,
  erpAllDataType.number,
  erpAllDataType.decimal,
  erpAllDataType.password,
  // erpAllDataType.foreignDataSelect,
  // erpAllDataType.formula,
  // erpAllDataType.multipleSelect,
  erpAllDataType.select,
  // erpAllDataType.date,
  erpAllDataType.datePicker,
  // erpAllDataType.time,
  erpAllDataType.dateTime,
  // erpAllDataType.checkbox,
  erpAllDataType.switch,
  erpAllDataType.url,
  erpAllDataType.file,
  erpAllDataType.image,
  erpAllDataType.user,
  erpAllDataType.multipleUser,
  erpAllDataType.detailedNotes,
  erpAllDataType.__d3__CreatedAtDate,
  erpAllDataType.__d3__CreatedAtTime,
  erpAllDataType.__d3__UpdatedAtDate,
  erpAllDataType.__d3__UpdatedAtTime,
  erpAllDataType.__d3__lastUpdatedBy,
  erpAllDataType.__d3__createdBy,
];
export const erp__d3__supistaDataType = [
  erpAllDataType.__d3__CreatedAtDate,
  erpAllDataType.__d3__CreatedAtTime,
  erpAllDataType.__d3__UpdatedAtDate,
  erpAllDataType.__d3__UpdatedAtTime,
];

export const defaultColumnSchema = {
  columnName: '',
  dataType: erpAllDataType.text?.key,
  name: '',
  validation: [],
  readOnly: false,
};

// Options for the relation type
export const relationTypesObject = {
  hasOne: { value: 'hasOne', label: 'hasOne' },
  hasMany: { value: 'hasMany', label: 'hasMany' },
  belongsTo: { value: 'belongsTo', label: 'belongsTo' },
  belongsToMany: { value: 'belongsToMany', label: 'belongsToMany' },
};
export const relationTypeArrayOptions = [
  relationTypesObject.hasOne,
  relationTypesObject.hasMany,
  relationTypesObject.belongsTo,
  relationTypesObject.belongsToMany,
];
