import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import { Form, Col, Row } from 'react-bootstrap';
import './GeneralSettings.scss';
import reduxConstants from 'lib/reusable-components/reusableUtils/redux/reduxConstants';
import useStoreSchema from 'lib/reusable-components/reusableUtils/commons/useStoreSchema';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import { useLocation } from 'react-router-dom';
import { _supistaApiPost } from 'lib/server-connection/connections';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import { CircularProgress } from '@mui/material';

const GeneralSettings = () => {
  const location = useLocation();
  const appID = location.pathname.split('/')[1];
  const appSchema = useStoreSchema(reduxConstants.STORE_NAME, reduxConstants.config.APP_SCHEMA);
  const appData = appSchema?.app ?? {};
  const [authSchema, setAuthSchema] = useState<{ [key: string]: any }>({
    name: appData?.name ?? '',
    logo: appData?.logo ?? '',
    loginValidity: appData?.loginValidity ?? { days: 30 },
    authVerification: appData?.authVerification ?? {
      email: { active: false },
      mobile: { active: false },
    },
  });

  const [errors, setErrors] = useState<{ [key: string]: any }>({});

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setAuthSchema((prevSchema) => ({
      ...prevSchema,
      [name]: value,
    }));
  };
  const handleLoginValidity = (e: any) => {
    const { value } = e.target;

    setAuthSchema((prevSchema) => ({
      ...prevSchema,
      loginValidity: { ...(prevSchema?.loginValidity ?? {}), days: value },
    }));
  };
  const handleAuthValidation = (value: any) => {
    setAuthSchema((prevSchema) => ({
      ...prevSchema,
      authVerification: {
        ...(prevSchema?.authVerification ?? {}),
        email: { ...(prevSchema?.authVerification?.email ?? {}), active: value },
      },
    }));
  };
  const validateForm = () => {
    let formErrors: { [key: string]: any } = {};
    if (!authSchema.name) formErrors.name = 'Name is required';
    if (!authSchema?.loginValidity?.days)
      formErrors.loginValidityDays = 'Login Validity Days is required';

    setErrors(formErrors);
    return Object.keys(formErrors).length === 0;
  };

  const handleBlur = (e: any) => {
    const { name, value } = e.target;
    let formErrors = { ...errors };
    if (!value) {
      formErrors[name] = `${name.charAt(0).toUpperCase() + name.slice(1)} is required`;
    } else {
      delete formErrors[name];
    }
    setErrors(formErrors);
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSave = async () => {
    if (validateForm()) {
      setIsLoading(true);
      // Add your delete logic here
      _supistaApiPost(`settings/${appID}/App`, {
        data: {
          ...appData,
          ...authSchema,
        },
      })
        .then(async (res) => {
          await publishToProduction(appID);
          // handleClose();
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
      // await publishToProduction(appID);
      // setIsNew(false);
      // setIsComponetDetailsChange(false);
      // if (isClose) {
      //   handleClose();
      // }
    }
  };
  return (
    <div className='GeneralSettings'>
      <Form>
        <Form.Group as={Row} className='mb-3' controlId='formName'>
          <Form.Label column sm={2}>
            {mls('Name')}:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type='text'
              name='name'
              value={authSchema.name || ''}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.name}
            />
            <Form.Control.Feedback type='invalid'>{errors.name}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='formName'>
          <Form.Label column sm={2}>
            {mls('Logo Url')}:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type='text'
              name='logo'
              value={authSchema.logo || ''}
              onChange={handleChange}
              // onBlur={handleBlur}
              isInvalid={!!errors.logo}
            />
            <Form.Control.Feedback type='invalid'>{errors.logo}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        {/* <div
          style={{ display: 'flex', gap: '1rem', alignItems: 'center', justifyContent: 'center' }}
        >
          <hr style={{ width: 'calc(50% - 2rem)' }} />
          Or
          <hr style={{ width: 'calc(50% - 2rem)' }} />
        </div>
        <Form.Group as={Row} className='mb-3' controlId='formName'>
          <Form.Label column sm={2}>
            {mls('Logo Upload')}:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type='file'
              name='logoUpload'
              accept='image/png, image/jpeg'
              value={authSchema.logoUpload || ''}
              onChange={handleChange}
              // onBlur={handleBlur}
              isInvalid={!!errors.logoUpload}
            />
            <Form.Control.Feedback type='invalid'>{errors.logo}</Form.Control.Feedback>
          </Col>
        </Form.Group> */}
        <Form.Group as={Row} className='mb-3' controlId='formName'>
          <Form.Label column sm={2}>
            {mls('Login Validity')}:
          </Form.Label>
          <Col sm={10}>
            <Form.Control
              type='number'
              min={1}
              name='loginValidityDays'
              value={authSchema?.loginValidity?.days || ''}
              onChange={handleLoginValidity}
              onBlur={handleBlur}
              isInvalid={!!errors.loginValidityDays}
            />
            <Form.Control.Feedback type='invalid'>{errors.loginValidityDays}</Form.Control.Feedback>
          </Col>
        </Form.Group>
        <Form.Group as={Row} className='mb-3' controlId='formName'>
          <Form.Label column sm={2}>
            {mls('Auth Validation')}:
          </Form.Label>
          <Col sm={10} style={{ display: 'flex', alignItems: 'center' }}>
            {/* {mls('Email')}: */}
            <CheckBox
              checked={authSchema?.authVerification?.email?.active ?? false}
              onClick={handleAuthValidation}
            />
          </Col>
        </Form.Group>

        <button
          onClick={() => handleSave()}
          // className='primaryButton secondaryButtonColor'
          className='primaryButton'
          type='button'
        >
          {isLoading ? (
            <>
              <CircularProgress color='inherit' size={10} /> &nbsp;
            </>
          ) : null}
          {mls('Save')}
        </button>
      </Form>
    </div>
  );
};

export default GeneralSettings;
