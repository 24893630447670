import React, { useState } from 'react';
import styles from './addSelectedColumnPopup.module.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import { mls } from 'lib/multilanguagesupport';
import CheckBox from 'lib/reusable-components/Components/CheckBox/CheckBox';
import './addSelectedColumnPopup.scss';
import SearchInputDiv from 'lib/reusable-components/Components/SearchInputDiv/SearchInputDiv';
import { buttonTypeObject } from '../data/appComponentData';
const AddSelectedColumnPopup = ({
  isDisplayToPopup,
  setIsDisplayToPopup,
  selectedTeams,
  setSelectedTeams,
  selectedTableData,
  buttonType,
}: {
  isDisplayToPopup: boolean;
  setIsDisplayToPopup: Function;
  setSelectedTeams: Function;
  selectedTeams: string[];
  buttonType: string;
  selectedTableData: { [key: string]: any };
}) => {
  const [displayToValues, setDisplayToValues] = useState<string[]>(selectedTeams ?? []);
  const [teamFilter, setTeamFilter] = useState({ search: '' });
  const [columnSchema, setColumnSchema] = useState(selectedTableData?.columnSchema ?? []);

  const handleTeamSelection = ({ isSelected, key }: { isSelected: boolean; key: string }) => {
    if (isSelected) {
      const filterdData = (displayToValues ?? []).filter((item) => item !== key);
      setDisplayToValues(filterdData);
      return;
    }
    setDisplayToValues([...displayToValues, key]);
  };
  const handleSearch = (search: string) => {
    setTeamFilter((currentValue) => {
      let filterdData: any[] = [];
      (selectedTableData?.columnSchema ?? []).forEach((columnElem: any) => {
        const lowerCaseElemName = columnElem.name.toLowerCase();
        const lowerCaseValue = search.toLowerCase();
        if (lowerCaseElemName.includes(lowerCaseValue)) {
          filterdData = [...filterdData, columnElem];
        }
      });
      setColumnSchema(filterdData);
      return { ...currentValue, search: search };
    });
  };
  const handleClose = () => {
    setSelectedTeams(displayToValues);
    setIsDisplayToPopup(false);
  };
  return (
    <div className={styles.addSelectedColumnPopup}>
      {isDisplayToPopup ? (
        <PopUpDivChild
          initialValue={isDisplayToPopup}
          setPopUp={handleClose}
          popupTitle={mls('Select Columns')}
          className={'addSelectColumnForDisplay'}
        >
          <div className={styles.addSelectedColumnContainer}>
            <div className={styles.descriptionContainer}>
              {buttonType === buttonTypeObject.actionButton.key
                ? mls(
                    'Selected columns will be displayed after clicking the action button, which you can edit.'
                  )
                : mls(
                    'Selected columns will be displayed after clicking the action button to add a filter.'
                  )}
            </div>
            <div className={styles.searchBox}>
              <SearchInputDiv value={teamFilter.search} onChange={handleSearch} fullWidth border />
            </div>
            <div className={styles.addTeamsInnerContainer}>
              {React.Children.toArray(
                columnSchema.map((columnElem: any) => {
                  const isSelected = (displayToValues ?? []).some(
                    (elem) => elem === columnElem.columnName
                  );
                  if (columnElem?.dataType === 'belongsTo') {
                    return <></>;
                  }
                  return (
                    <div
                      className={styles.addTeamsContainerElem}
                      onClick={() =>
                        handleTeamSelection({ isSelected, key: columnElem.columnName })
                      }
                    >
                      <div className={styles.addTeamsContainerElemCheckBox}>
                        <CheckBox checked={isSelected} onClick={() => {}} />
                      </div>
                      <div className={styles.addTeamsContainerElemDetails}>
                        <div className={styles.addTeamsContainerElemName}>
                          {columnElem?.name} ( {columnElem?.dataType} )
                        </div>
                      </div>
                    </div>
                  );
                })
              )}
            </div>
          </div>
        </PopUpDivChild>
      ) : null}
    </div>
  );
};

export default AddSelectedColumnPopup;
