import React, { useMemo, useState } from 'react';
import MainConnectedTableSelection from './MainConnectedTableSelection';
import { Accordion } from 'react-bootstrap';
import styles from './additionalSettings.module.scss';
import ColumnDragableContainer from './ColumnDragableContainer';
import AppComponentFilter from '../../AppComponentFilter/AppComponentFilter';
import { mls } from 'lib/multilanguagesupport';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getAppComponentFilterColumnSchema,
  getFinalTableID,
} from '../../utils/componentSettingFunction';

interface AdditionalSettingsProps {
  componentSchema: any;
  connectedTablesData: { [key: string]: any };
  appDatatable: any;
  appSchema: any;
  setRelatedTableList: Function;
  appComponent: { [key: string]: any };
}
const AdditionalSettings = ({
  componentSchema,
  connectedTablesData,
  appDatatable,
  setRelatedTableList,
  appComponent,
}: AdditionalSettingsProps) => {
  const [filterData, setFilterData] = useState({
    popupStatus: false,
    filters: {},
    tableID: '',
    finalTableID: '',
    columnName: '',
    relatedTableIndex: 0,
    tableData: {
      isInnerTable: false,
      relatedFieldFilter: false,
      outerTableID: '',
      innerTableIndex: 0,
    },
  });
  const handleFilters = (action: any) => {
    setFilterData({ ...filterData, filters: action });
  };
  const relatedTableList = componentSchema?.tableList ?? [];

  const updateRelatedTable = ({
    updatedTableData,
    updatedRelatedTableList = relatedTableList,
    relatedTableIndex,
  }: {
    updatedTableData: { [key: string]: any };
    updatedRelatedTableList?: any;
    relatedTableIndex: number;
  }) => {
    const reorderedTableListView = Array.from(updatedRelatedTableList);
    reorderedTableListView.splice(relatedTableIndex, 1);
    reorderedTableListView.splice(relatedTableIndex, 0, updatedTableData);
    setRelatedTableList(reorderedTableListView);
  };

  const getUpdateInnerRelatedTable = ({
    updatedTableData,
    updatedRelatedTableList = relatedTableList,
    relatedTableIndex,
  }: {
    updatedTableData: { [key: string]: any };
    updatedRelatedTableList: any;
    relatedTableIndex: number;
  }) => {
    const reorderedTableListView = Array.from(updatedRelatedTableList);
    reorderedTableListView.splice(relatedTableIndex, 1);
    reorderedTableListView.splice(relatedTableIndex, 0, updatedTableData);
    return reorderedTableListView;
  };
  const handleFilterPopupStatus = () => {
    const filters = filterData?.filters;
    const relatedTableIndex = filterData?.relatedTableIndex;
    const columnName = filterData?.columnName;
    console.log('filterData', filterData);

    if (filterData?.tableData?.isInnerTable) {
      const relatedTable = relatedTableList[relatedTableIndex] ?? {};
      const innerTableIndex = filterData?.tableData?.innerTableIndex;
      const selectedTableListData = relatedTable?.tableList[innerTableIndex];
      const newDefaultView = (selectedTableListData?.defaultView ?? []).map(
        (columnNameElem: any) => {
          if (columnNameElem?.columnName === (columnName ?? '')) {
            return { ...columnNameElem, filters: filters };
          }
          return columnNameElem;
        }
      );

      const updatedTableData = {
        ...selectedTableListData,
        defaultView: newDefaultView,
      };
      const updatedTableList = getUpdateInnerRelatedTable({
        updatedTableData: updatedTableData,
        updatedRelatedTableList: relatedTable?.tableList ?? {},
        relatedTableIndex: innerTableIndex,
      });
      updateRelatedTable({
        updatedTableData: {
          ...relatedTable,
          tableList: updatedTableList,
        },
        relatedTableIndex,
      });
    } else if (filterData?.tableData?.relatedFieldFilter) {
      const relatedTable = relatedTableList[relatedTableIndex] ?? {};
      const innerTableIndex = filterData?.tableData?.innerTableIndex;
      const selectedTableListData = relatedTable?.relatedFields[innerTableIndex];
      const newDefaultView = (selectedTableListData?.defaultView ?? []).map(
        (columnNameElem: any) => {
          if (columnNameElem?.columnName === (columnName ?? '')) {
            return { ...columnNameElem, filters: filters };
          }
          return columnNameElem;
        }
      );

      const updatedTableData = {
        ...selectedTableListData,
        defaultView: newDefaultView,
      };

      const updatedTableList = getUpdateInnerRelatedTable({
        updatedTableData: updatedTableData,
        updatedRelatedTableList: relatedTable?.relatedFields ?? {},
        relatedTableIndex: innerTableIndex,
      });
      updateRelatedTable({
        updatedTableData: {
          ...relatedTable,
          relatedFields: updatedTableList,
        },
        relatedTableIndex,
      });
    } else {
      const relatedTable = relatedTableList[relatedTableIndex] ?? {};
      const newDefaultView = (relatedTable?.defaultView ?? []).map((columnNameElem: any) => {
        if (columnNameElem?.columnName === (columnName ?? '')) {
          return { ...columnNameElem, filters: filters };
        }
        return columnNameElem;
      });

      updateRelatedTable({
        updatedTableData: {
          ...relatedTable,
          defaultView: newDefaultView,
        },
        relatedTableIndex,
      });
    }

    setFilterData({ ...filterData, popupStatus: !filterData.popupStatus });
  };
  const columnSchema = useMemo(() => {
    const finalColumns = getAppComponentFilterColumnSchema({
      connectedTablesData,
      groupedConnectedTablesData: connectedTablesData,
      tableData: filterData.tableData,
      finalTableID: filterData.finalTableID,
    });
    return finalColumns;
  }, [
    // componentSchema?.tableList,
    connectedTablesData,
    filterData.tableData,
    filterData.finalTableID,
  ]);

  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedRelatedTableList = Array.from(relatedTableList);
    const [movedRelatedTableList] = reorderedRelatedTableList.splice(result.source.index, 1);
    reorderedRelatedTableList.splice(result.destination.index, 0, movedRelatedTableList);
    setRelatedTableList(reorderedRelatedTableList);
  };
  const finalTableID = getFinalTableID({
    tableID: ((relatedTableList ?? [])?.[0] ?? {})?.tableID,
    relationType: ((relatedTableList ?? [])?.[0] ?? {})?.relationType,
    relationID: ((relatedTableList ?? [])?.[0] ?? {})?.relationID,
  });
  return (
    <div className={styles.AdditionalSettings}>
      <div className={styles.mainTableContainer}>
        <div className={styles.mainTableTitle}>{mls('Main Table')}:</div>
        <div className={styles.selectColumnContainer}>
          <Accordion>
            <ColumnDragableContainer
              relatedTable={(relatedTableList ?? [])?.[0] ?? {}}
              relatedTableIndex={0}
              appDatatable={appDatatable}
              setRelatedTableList={setRelatedTableList}
              connectedTablesData={connectedTablesData}
              finalTableID={finalTableID}
              filterData={filterData}
              setFilterData={setFilterData}
              relatedTableList={relatedTableList}
              appComponent={appComponent}
              componentSchema={componentSchema}
            />
          </Accordion>
        </div>
      </div>
      <div className={styles.connectedTablesContainer}>
        <div className={styles.chooseConnectedTables}>
          <MainConnectedTableSelection
            connectedTablesData={connectedTablesData}
            componentSchema={componentSchema}
            relatedTableList={relatedTableList ?? []}
            setRelatedTableList={setRelatedTableList}
          />
          {filterData.popupStatus ? (
            <AppComponentFilter
              setChartFilter={handleFilters}
              chartFilter={filterData?.filters ?? {}}
              allTableData={columnSchema}
              appID={''}
              sheetID={''}
              isJoinedTable={false}
              isPopUp={filterData.popupStatus}
              setIsPopUp={handleFilterPopupStatus}
            />
          ) : null}
        </div>
        <div className={styles.selectColumnContainer}>
          <Accordion>
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId='droppable-1'>
                {(provided) => (
                  <div
                    className='accordion'
                    id='kt_accordion_1'
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                  >
                    {React.Children.toArray(
                      (relatedTableList ?? []).map(
                        (relatedTable: { [key: string]: any }, relatedTableIndex: number) => {
                          if (componentSchema?.table === relatedTable?.tableID) {
                            return <></>;
                          }
                          const finalTableID = getFinalTableID({
                            tableID: relatedTable?.tableID,
                            relationType: relatedTable?.relationType,
                            relationID: relatedTable?.relationID,
                          });

                          return (
                            <Draggable
                              key={finalTableID}
                              draggableId={finalTableID}
                              index={relatedTableIndex}
                            >
                              {(providedDrag: any, _: any) => (
                                <div
                                  ref={providedDrag.innerRef}
                                  {...providedDrag.draggableProps}
                                  key={finalTableID}
                                >
                                  <ColumnDragableContainer
                                    relatedTable={relatedTable}
                                    relatedTableIndex={relatedTableIndex}
                                    appDatatable={appDatatable}
                                    setRelatedTableList={setRelatedTableList}
                                    filterData={filterData}
                                    finalTableID={finalTableID}
                                    setFilterData={setFilterData}
                                    providedDrag={providedDrag}
                                    relatedTableList={relatedTableList}
                                    connectedTablesData={connectedTablesData}
                                    appComponent={appComponent}
                                    componentSchema={componentSchema}
                                  />
                                </div>
                              )}
                            </Draggable>
                          );
                        }
                      )
                    )}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Accordion>
        </div>
      </div>
    </div>
  );
};

export default AdditionalSettings;
