import React, { useEffect, useMemo, useState } from 'react';
import { Row, Col, Button, ListGroup } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { v4 as uuid } from 'uuid';
import QueryGenerator from '../../QueryGenerator/QueryGenerator';
import {
  buttonTypeObject,
  codeContainerTypes,
  crudTypeButtonObject,
  defaultCode,
  defaultOutputData,
  specialDataTypeName,
} from '../../data/appComponentData';
import {
  getDefaultCode,
  getDefaultCodeForFirstType,
  getDefaultInput,
} from '../../utils/componentSettingFunction';
import useUpdateEffect from 'lib/reusable-components/hooks/useUpdateEffect';
import AICustomizationEditorContainer from './AICustomizationEditorContainer/AICustomizationEditorContainer';
import { MdDelete } from 'react-icons/md';
import ConfirmReset from './ConfirmReset';
import ActionButtonTabs from './ActionButtonTabs';
import TableWiseActionTabs from './TableWiseActionTabs';
import UploadCSVTabs from './UploadCSVTabs';

const AIGeneratedCodeEditor = ({
  componentSchema,
  appID,
  newConnectedTableData,
  connectedTablesData,
  groupedConnectedTablesData,
  setComponentSchema,
  componentRowList,
  selectedTable,
  selectedCode,
  selectedId,
  setSelectedId,
  updateCodeCustomization,
  selectedTableData,
  sampleData,
  isCodeDataChange,
  setIsCodeDataChange,
  tableSchema,
}) => {
  const [buttonType, setButtonType] = useState(buttonTypeObject.CRUD.key);
  const [activeButtonKey, setActiveButtonKey] = useState(crudTypeButtonObject.GET.key);
  const [codeContainerType, setCodeContainerType] = useState(codeContainerTypes.code.key);
  const [outputData, setOutputData] = useState(defaultOutputData);
  const [selectedParentId, setSelectedParentId] = useState(componentRowList?.[0]?.id ?? '');

  const [inputData, setInputData] = useState(
    getDefaultInput({
      connectedTablesData: connectedTablesData,
      selectedTable,
      sampleData,
      actionButtonKey: activeButtonKey,
      buttonType: buttonType,
      selectedId: selectedId,
      parentId: selectedParentId,
    }) ?? '{}'
  );

  useEffect(() => {
    setInputData(
      getDefaultInput({
        connectedTablesData: connectedTablesData,
        selectedTable,

        sampleData,
        actionButtonKey: activeButtonKey,
        buttonType: buttonType,
        selectedId: selectedId,
        // parentId: selectedParentId,
        parentId: selectedId,
      }) ?? '{}'
    );
  }, [
    selectedId,
    selectedParentId,
    selectedTable,
    buttonType,
    sampleData,
    activeButtonKey,
    connectedTablesData,
  ]);

  const [isInputChanged, setIsInputChanged] = useState(false);

  const { selectedData, codePresent } = useMemo(
    () =>
      getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: activeButtonKey,
        buttonType,
      }),
    [activeButtonKey, buttonType, componentSchema, selectedTable]
  );
  const [code, setCode] = useState(() => {
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: selectedData?.functionCode,
      buttonType,
      activeButtonKey,
      mainTableID: componentSchema?.table,
    });
    // console.log(finalCode);
    return finalCode;
    // return selectedData?.functionCode ?? defaultCode;
  });

  useUpdateEffect(() => {
    setButtonType(buttonTypeObject.CRUD.key);
    setActiveButtonKey(crudTypeButtonObject.GET.key);
    setCodeContainerType(codeContainerTypes.code.key);
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: crudTypeButtonObject.GET.key,
      buttonType: buttonTypeObject.CRUD.key,
    });
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: selectedData?.functionCode,
      buttonType: buttonTypeObject.CRUD.key,
      activeButtonKey: crudTypeButtonObject.GET.key,
      mainTableID: componentSchema?.table,
    });
    setCode(finalCode || '');
    setIsCodeDataChange(true);
  }, [selectedTable]);

  const [title, setTitle] = useState(() => {
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });

    return selectedData?.title ?? 'New Action';
  });
  const [errors, setErrors] = useState({});

  const handleTypeSelect = ({ targetButtonKey, buttonType }) => {
    setButtonType(buttonType);
    setActiveButtonKey(targetButtonKey);
    setErrors({});
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: targetButtonKey,
      buttonType: buttonType,
    });
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: selectedData?.functionCode,
      buttonType,
      activeButtonKey: targetButtonKey,
      mainTableID: componentSchema?.table,
    });
    setCode(finalCode || '');
    setOutputData(defaultOutputData);
    const inputValue = getDefaultInput({
      connectedTablesData: connectedTablesData,
      selectedTable,
      sampleData,
      actionButtonKey: targetButtonKey,
      buttonType: buttonType,
      selectedId: selectedId,
      parentId: selectedParentId,
    });

    setInputData(inputValue);
    setIsInputChanged(false);
    setCodeContainerType(codeContainerTypes.code.key);
    if (buttonType === buttonTypeObject.actionButton.key) {
      setTitle(selectedData?.title || '');
    }
    if (buttonType === buttonTypeObject.tablewiseAction.key) {
      setTitle(selectedData?.title || '');
    }
    if (buttonType === buttonTypeObject.cronJob.key) {
      setTitle(selectedData?.title || '');
    }
    setIsCodeDataChange(true);
  };
  const handleCodeChange = (value) => {
    setCode(value);
  };
  const actionButtonDataArr = selectedCode?.actionButtonArr ?? [];
  const tablewiseActionData = selectedCode?.tablewiseAction ?? [];
  const cronJobData = selectedCode?.cronJob ?? {};

  const handleAddCronJob = () => {
    const cronJobID = uuid();
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: cronJobID,
      buttonType: buttonTypeObject.cronJob.key,
    });
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: selectedData?.functionCode,
      buttonType: buttonTypeObject.cronJob.key,
      activeButtonKey: cronJobID,
      mainTableID: componentSchema?.table,
    });
    setButtonType(buttonTypeObject.cronJob.key);
    setActiveButtonKey(cronJobID);
    setErrors({});
    setCode(finalCode || '');
    setTitle(selectedData?.title || '');
    updateCodeCustomization({
      selectedTable,
      codeCustomizationData: {
        ...selectedCode,
        cronJob: { ...(selectedCode?.cronJob ?? {}), [cronJobID]: selectedData },
      },
    });
    const inputValue = getDefaultInput({
      connectedTablesData: connectedTablesData,
      selectedTable,
      sampleData,
      actionButtonKey: cronJobID,
      buttonType: buttonType,
      selectedId: selectedId,
      parentId: selectedParentId,
    });

    setInputData(inputValue);
    setIsCodeDataChange(true);
  };

  const [confirmResetModal, setConfirmResetModal] = useState(false);
  const handleResetCode = () => {
    if (buttonType === buttonTypeObject.CRUD.key) {
      const curdOp = { ...(selectedCode?.crudOp ?? {}) };
      delete curdOp[activeButtonKey];
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          crudOp: curdOp,
        },
      });
    } else if (buttonType === buttonTypeObject.formValidation.key) {
      const updatedSelectedCode = { ...(selectedCode ?? {}) };
      delete updatedSelectedCode?.formValidation;
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: updatedSelectedCode,
      });
    } else if (buttonType === buttonTypeObject.actionButton.key) {
      // const actionButton = { ...(selectedCode?.actionButton ?? {}) };
      // delete actionButton[activeButtonKey];
      const updatedActionButtonArr = actionButtonDataArr.filter(
        (elem) => elem.actionButtonID !== activeButtonKey
      );
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          // actionButton: actionButton,
          actionButtonArr: updatedActionButtonArr,
        },
      });
      setButtonType(buttonTypeObject.CRUD.key);
      setActiveButtonKey(crudTypeButtonObject.GET.key);
    } else if (buttonType === buttonTypeObject.tablewiseAction.key) {
      const updatedActionButtonArr = tablewiseActionData.filter(
        (elem) => elem.actionButtonID !== activeButtonKey
      );
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          tablewiseAction: updatedActionButtonArr,
        },
      });
      setButtonType(buttonTypeObject.CRUD.key);
      setActiveButtonKey(crudTypeButtonObject.GET.key);
    } else if (buttonType === buttonTypeObject.cronJob.key) {
      const cronJob = { ...(selectedCode?.cronJob ?? {}) };
      delete cronJob[activeButtonKey];
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: {
          ...selectedCode,
          cronJob: cronJob,
        },
      });
      setButtonType(buttonTypeObject.CRUD.key);
      setActiveButtonKey(crudTypeButtonObject.GET.key);
    }
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: activeButtonKey,
      buttonType,
    });
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: selectedData?.functionCode,
      buttonType: buttonTypeObject.CRUD.key,
      activeButtonKey: crudTypeButtonObject.GET.key,
      mainTableID: componentSchema?.table,
    });

    setCode(finalCode || '');
    setConfirmResetModal(false);
    setIsCodeDataChange(true);
  };

  return (
    <div className={styles.aiCustomizationOuter}>
      <Row className={styles.aiCustomizationInner}>
        <Col md={3} className={styles.apiTypes}>
          <ListGroup>
            {selectedTableData?.relationType === specialDataTypeName?.belongsTo?.key ? (
              <>
                <ListGroup.Item
                  key={crudTypeButtonObject.GET.key}
                  action
                  active={
                    buttonType === buttonTypeObject.CRUD.key &&
                    activeButtonKey === crudTypeButtonObject.GET.key
                  }
                  onClick={() =>
                    handleTypeSelect({
                      targetButtonKey: crudTypeButtonObject.GET.key,
                      buttonType: buttonTypeObject.CRUD.key,
                    })
                  }
                >
                  {mls(crudTypeButtonObject.GET.label)}
                </ListGroup.Item>
              </>
            ) : (
              <>
                <ConfirmReset
                  confirmResetModal={confirmResetModal}
                  setConfirmResetModal={setConfirmResetModal}
                  handleReset={handleResetCode}
                  buttonType={buttonType}
                  isDelete={true}
                />
                {Object.values(crudTypeButtonObject).map((type) => (
                  <ListGroup.Item
                    key={type.key}
                    action
                    active={
                      buttonType === buttonTypeObject.CRUD.key && activeButtonKey === type.key
                    }
                    onClick={() =>
                      handleTypeSelect({
                        targetButtonKey: type.key,
                        buttonType: buttonTypeObject.CRUD.key,
                      })
                    }
                  >
                    {mls(type.label)}
                  </ListGroup.Item>
                ))}
                <ListGroup.Item
                  key={buttonTypeObject.formValidation.key}
                  action
                  active={
                    buttonType === buttonTypeObject.formValidation.key &&
                    activeButtonKey === buttonTypeObject.formValidation.key
                  }
                  onClick={() =>
                    handleTypeSelect({
                      targetButtonKey: buttonTypeObject.formValidation.key,
                      buttonType: buttonTypeObject.formValidation.key,
                    })
                  }
                >
                  {mls(buttonTypeObject.formValidation.name)}
                </ListGroup.Item>
                <ListGroup.Item
                  key={buttonTypeObject.bulkCreate.key}
                  action
                  active={
                    buttonType === buttonTypeObject.bulkCreate.key &&
                    activeButtonKey === buttonTypeObject.bulkCreate.key
                  }
                  onClick={() =>
                    handleTypeSelect({
                      targetButtonKey: buttonTypeObject.bulkCreate.key,
                      buttonType: buttonTypeObject.bulkCreate.key,
                    })
                  }
                >
                  {mls(buttonTypeObject.bulkCreate.name)}
                </ListGroup.Item>
                <ActionButtonTabs
                  selectedCode={selectedCode}
                  buttonType={buttonType}
                  activeButtonKey={activeButtonKey}
                  handleTypeSelect={handleTypeSelect}
                  setConfirmResetModal={setConfirmResetModal}
                  componentSchema={componentSchema}
                  selectedTable={selectedTable}
                  setButtonType={setButtonType}
                  setActiveButtonKey={setActiveButtonKey}
                  setCode={setCode}
                  setErrors={setErrors}
                  setTitle={setTitle}
                  updateCodeCustomization={updateCodeCustomization}
                  connectedTablesData={connectedTablesData}
                  selectedParentId={selectedParentId}
                  selectedId={selectedId}
                  sampleData={sampleData}
                  setInputData={setInputData}
                />
                <TableWiseActionTabs
                  selectedCode={selectedCode}
                  buttonType={buttonType}
                  activeButtonKey={activeButtonKey}
                  handleTypeSelect={handleTypeSelect}
                  setConfirmResetModal={setConfirmResetModal}
                  componentSchema={componentSchema}
                  selectedTable={selectedTable}
                  setButtonType={setButtonType}
                  setActiveButtonKey={setActiveButtonKey}
                  setCode={setCode}
                  setErrors={setErrors}
                  setTitle={setTitle}
                  updateCodeCustomization={updateCodeCustomization}
                  connectedTablesData={connectedTablesData}
                  selectedParentId={selectedParentId}
                  selectedId={selectedId}
                  sampleData={sampleData}
                  setInputData={setInputData}
                />
                {/* <UploadCSVTabs
                  selectedCode={selectedCode}
                  buttonType={buttonType}
                  activeButtonKey={activeButtonKey}
                  handleTypeSelect={handleTypeSelect}
                  setConfirmResetModal={setConfirmResetModal}
                  componentSchema={componentSchema}
                  selectedTable={selectedTable}
                  setButtonType={setButtonType}
                  setActiveButtonKey={setActiveButtonKey}
                  setCode={setCode}
                  setErrors={setErrors}
                  setTitle={setTitle}
                  updateCodeCustomization={updateCodeCustomization}
                  connectedTablesData={connectedTablesData}
                  selectedParentId={selectedParentId}
                  selectedId={selectedId}
                  sampleData={sampleData}
                  setInputData={setInputData}
                /> */}

                {selectedTableData?.relationType === specialDataTypeName?.MAIN?.key ? (
                  <ListGroup.Item className={styles.actionButtonContainer}>
                    <b>{mls('Schedulers')}</b>
                    <hr />
                    <ListGroup>
                      {Object.keys(cronJobData ?? {}).map((cornJobKey, index) => {
                        const cronJobElem = cronJobData[cornJobKey];
                        return (
                          <ListGroup.Item
                            key={index}
                            action
                            active={
                              buttonType === buttonTypeObject.cronJob.key &&
                              activeButtonKey === cornJobKey
                            }
                            className={`
                            ${styles.actionButtonDeleteButtonContainer}
                            ${
                              buttonType === buttonTypeObject.cronJob.key &&
                              activeButtonKey === cornJobKey
                                ? styles.actionButtonDeleteButonActive
                                : ''
                            }`}
                            onClick={() =>
                              handleTypeSelect({
                                targetButtonKey: cornJobKey,
                                buttonType: buttonTypeObject.cronJob.key,
                              })
                            }
                          >
                            {cronJobElem?.title}
                            <div
                              className={styles.actionButtonDeleteButon}
                              onClick={() => setConfirmResetModal(true)}
                            >
                              <MdDelete />
                            </div>
                          </ListGroup.Item>
                        );
                      })}
                    </ListGroup>
                    <Button
                      variant='link'
                      onClick={() => handleAddCronJob()}
                      className={styles.addButton}
                    >
                      {mls('Add Scheduler')}
                      <Plus />
                    </Button>
                  </ListGroup.Item>
                ) : null}
              </>
            )}
          </ListGroup>
        </Col>
        <AICustomizationEditorContainer
          errors={errors}
          codePresent={codePresent}
          AICustomizationEditorContainer
          componentRowList={componentRowList}
          componentSchema={componentSchema}
          setTitle={setTitle}
          activeButtonKey={activeButtonKey}
          setComponentSchema={setComponentSchema}
          title={title}
          setErrors={setErrors}
          selectedCode={selectedCode}
          selectedTable={selectedTable}
          updateCodeCustomization={updateCodeCustomization}
          buttonType={buttonType}
          codeContainerType={codeContainerType}
          setCodeContainerType={setCodeContainerType}
          outputData={outputData}
          code={code}
          handleCodeChange={handleCodeChange}
          inputData={inputData}
          setInputData={setInputData}
          setIsInputChanged={setIsInputChanged}
          appID={appID}
          setOutputData={setOutputData}
          selectedId={selectedId}
          setSelectedId={setSelectedId}
          selectedParentId={selectedParentId}
          setSelectedParentId={setSelectedParentId}
          selectedTableData={selectedTableData}
          connectedTablesData={connectedTablesData}
          groupedConnectedTablesData={groupedConnectedTablesData}
          isInputChanged={isInputChanged}
          setCode={setCode}
          setButtonType={setButtonType}
          setActiveButtonKey={setActiveButtonKey}
          isCodeDataChange={isCodeDataChange}
          setIsCodeDataChange={setIsCodeDataChange}
        />
      </Row>
      <QueryGenerator
        newConnectedTableData={newConnectedTableData}
        appID={appID}
        handleCodeChange={handleCodeChange}
        code={code}
        buttonType={buttonType}
        selectedCode={selectedCode}
        activeButtonKey={activeButtonKey}
        selectedTable={selectedTable}
        connectedTablesData={connectedTablesData}
        selectedParentId={selectedParentId}
        selectedId={selectedId}
        componentSchema={componentSchema}
        inputData={inputData}
        tableSchema={tableSchema}
      />
    </div>
  );
};

export default AIGeneratedCodeEditor;
