import React from 'react';
import { Button, ListGroup } from 'react-bootstrap';
import { Plus } from 'react-bootstrap-icons';
import styles from './codeCustomization.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { v4 as uuid } from 'uuid';
import { buttonTypeObject } from '../../data/appComponentData';
import {
  getDefaultCode,
  getDefaultCodeForFirstType,
  getDefaultInput,
} from '../../utils/componentSettingFunction';
import { MdDelete, MdOutlineDragHandle } from 'react-icons/md';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface actionButtonTabsProps {
  selectedCode: { [key: string]: any };
  buttonType: string;
  activeButtonKey: string;
  handleTypeSelect: Function;
  setConfirmResetModal: Function;
  handleAddActionButton: Function;
  componentSchema: any;
  selectedTable: string;
  setButtonType: Function;
  setActiveButtonKey: Function;
  setErrors: Function;
  setCode: Function;
  setTitle: Function;
  updateCodeCustomization: Function;
  connectedTablesData: { [key: string]: any };
  selectedParentId: string;
  selectedId: string;
  sampleData: { [key: string]: any };
  setInputData: Function;
}
const ActionButtonTabs = ({
  selectedCode,
  buttonType,
  activeButtonKey,
  handleTypeSelect,
  setConfirmResetModal,
  componentSchema,
  selectedTable,
  setButtonType,
  setActiveButtonKey,
  setCode,
  setErrors,
  setTitle,
  updateCodeCustomization,
  connectedTablesData,
  selectedParentId,
  selectedId,
  sampleData,
  setInputData,
}: actionButtonTabsProps) => {
  const actionButtonDataArr = selectedCode?.actionButtonArr ?? [];

  const handleAddActionButton = () => {
    const actionButtonID = uuid();
    const { selectedData } = getDefaultCode({
      componentSchema,
      selectedTable,
      actionButtonKey: actionButtonID,
      buttonType: buttonTypeObject.actionButton.key,
    });
    const finalCode = getDefaultCodeForFirstType({
      selectTableName: selectedTable,
      connectedTablesData,
      selectedParentId,
      selectedId,
      currentCode: selectedData?.functionCode,
      buttonType: buttonTypeObject.actionButton.key,
      activeButtonKey: actionButtonID,
      mainTableID: componentSchema?.table,
    });
    setButtonType(buttonTypeObject.actionButton.key);
    setActiveButtonKey(actionButtonID);
    setErrors({});
    setCode(finalCode || '');
    setTitle(selectedData?.title || '');
    const inputValue = getDefaultInput({
      connectedTablesData: connectedTablesData,
      selectedTable,
      sampleData,
      actionButtonKey: actionButtonID,
      buttonType: buttonType,
      selectedId: selectedId,
      parentId: selectedParentId,
    });

    setInputData(inputValue);
    updateCodeCustomization({
      selectedTable,
      codeCustomizationData: {
        ...selectedCode,
        actionButtonArr: [...(selectedCode?.actionButtonArr ?? []), selectedData],
      },
    });
  };
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const reorderedActionButton = Array.from(actionButtonDataArr);
    const [movedScreen] = reorderedActionButton.splice(result.source.index, 1);
    reorderedActionButton.splice(result.destination.index, 0, movedScreen);

    updateCodeCustomization({
      selectedTable,
      codeCustomizationData: {
        ...selectedCode,
        actionButtonArr: reorderedActionButton,
      },
    });
  };
  return (
    <ListGroup.Item className={styles.actionButtonContainer}>
      <b>{mls('Action Buttons')}</b>
      <hr />
      <ListGroup>
        <DragDropContext onDragEnd={handleDragEnd}>
          <Droppable droppableId='droppable'>
            {(provided, snapshot) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {actionButtonDataArr.map(
                  (actionButtonElem: { [key: string]: any }, index: number) => {
                    const actionButtonKey = actionButtonElem?.actionButtonID;
                    return (
                      <Draggable key={actionButtonKey} draggableId={actionButtonKey} index={index}>
                        {(provided, snapshot) => (
                          <>
                            <ListGroup.Item
                              key={index}
                              action
                              active={
                                buttonType === buttonTypeObject.actionButton.key &&
                                activeButtonKey === actionButtonKey
                              }
                              className={`
                            ${styles.actionButtonDeleteButtonContainer}
                            ${
                              buttonType === buttonTypeObject.actionButton.key &&
                              activeButtonKey === actionButtonKey
                                ? styles.actionButtonDeleteButonActive
                                : ''
                            }`}
                              onClick={() =>
                                handleTypeSelect({
                                  targetButtonKey: actionButtonKey,
                                  buttonType: buttonTypeObject.actionButton.key,
                                })
                              }
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                              }}
                            >
                              <div
                                className={styles.dragIconOuter}
                                {...(provided?.dragHandleProps ?? {})}
                              >
                                <MdOutlineDragHandle className={styles.dragIcon} />
                              </div>
                              {actionButtonElem?.title}
                              <div
                                className={styles.actionButtonDeleteButon}
                                onClick={() => setConfirmResetModal(true)}
                              >
                                <MdDelete />
                              </div>
                            </ListGroup.Item>
                          </>
                        )}
                      </Draggable>
                    );
                  }
                )}

                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ListGroup>

      <Button variant='link' onClick={() => handleAddActionButton()} className={styles.addButton}>
        {mls('Add Actions')}
        <Plus />
      </Button>
    </ListGroup.Item>
  );
};

export default ActionButtonTabs;
