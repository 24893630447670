import React from 'react';
import OptionField from './OptionField';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface selectMenuFieldProps {
  selectMenu: any;
  udpateSelectMenu: Function;
  deleteOptionValidation: Function;
  columnData: { [key: string]: any };
  updateColumnData: Function;
}
const SelectMenuField = ({
  selectMenu,
  udpateSelectMenu,
  deleteOptionValidation,
  columnData,
  updateColumnData,
}: selectMenuFieldProps) => {
  const handleDragEnd = (result: any) => {
    if (!result.destination) {
      return;
    }
    const updatedSelectMenu = Array.from(selectMenu);
    const [movedScreen] = updatedSelectMenu.splice(result.source.index, 1);
    updatedSelectMenu.splice(result.destination.index, 0, movedScreen);
    console.log('updatedSelectMenu', updatedSelectMenu);

    updateColumnData({
      key: 'options',
      value: { ...(columnData?.options ?? {}), selectMenu: updatedSelectMenu },
    });
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId='droppable'>
          {(provided: any, snapshot: any) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {(selectMenu ?? [])?.map((option: any, key: number) => {
                const updateOptionData = ({ optionData }: { optionData: any }) => {
                  udpateSelectMenu({ optionData, optionIndex: key });
                };
                const handleChangeOption = (e: any, name: string) => {
                  const newData = {
                    ...option,
                    [name]: e.target.value,
                  };
                  udpateSelectMenu({ optionData: newData, optionIndex: key });
                };
                const handleDelete = () => {
                  deleteOptionValidation(key);
                };
                return (
                  <>
                    <Draggable
                      key={`${key} ${option?.value}`}
                      draggableId={`${key} ${option?.value}`}
                      index={key}
                    >
                      {(provided, snapshot) => (
                        <OptionField
                          optionData={option}
                          handleChangeOption={handleChangeOption}
                          updateOptionData={updateOptionData}
                          handleDelete={handleDelete}
                          isDeleteOption={true}
                          selectMenu={selectMenu}
                          provided={provided}
                          dragable={true}
                        />
                      )}
                    </Draggable>
                  </>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
};

export default SelectMenuField;
