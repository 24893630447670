import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
import React from 'react';
import styles from './AppAssets.module.scss';

const ShowAssets = ({
  isSelectedAssetsPopup,
  setIsSelectedAssetsPopup,
  selectedAsset,
  setSelectedAsset,
  selectedAssetName,
  setSelectedAssetName,
}: {
  isSelectedAssetsPopup: boolean;
  setIsSelectedAssetsPopup: Function;
  selectedAsset: string;
  setSelectedAsset: Function;
  selectedAssetName: string;
  setSelectedAssetName: Function;
}) => {
  const closeAssets = () => {
    setIsSelectedAssetsPopup(false);
    URL.revokeObjectURL(selectedAsset);
    setSelectedAsset('');
    setSelectedAssetName('');
  };

  return (
    <PopUpDivChild
      initialValue={isSelectedAssetsPopup}
      setPopUp={() => {
        closeAssets();
      }}
      className={'uploadAssetsPopup'}
      popupTitle={selectedAssetName}
    >
      <div className={styles.uploadAssetsContainer}>
        <img src={selectedAsset} alt='selectedAsset' />
      </div>
    </PopUpDivChild>
  );
};

export default ShowAssets;
