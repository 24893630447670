import clsx from 'clsx';
import React, { useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { ConfirmDelete } from '../../modal/add-database/DeleteColumn';
import { mls } from 'lib/multilanguagesupport';
import Accordion from '@material-ui/core/Accordion';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import RelationsComponent from '../RelationsComponent';
import AddNewColumn from './AddNewColumn';
import styles from './editTableSettingsPopup.module.scss';
import ColumnSettings from './ColumnSettings/ColumnSettings';
import { updateDataByIndex } from '../util/utilFunctions';
import { erpAllDataType } from '../data/erpData';
interface editTableSettingProps {
  allDataTableData: any;
  errorMessage?: string;
  relatedTableList: any;
  setRelatedTableList: Function;
  tableUpdates: any;
  setTableUpdates: Function;
  dataTableSchema: any;
  setDataTableSchema: Function;
}
const EditTableSetting = ({
  allDataTableData,
  errorMessage = '',
  relatedTableList,
  setRelatedTableList,
  tableUpdates,
  setTableUpdates,
  dataTableSchema,
  setDataTableSchema,
}: editTableSettingProps) => {
  const [colSchIndex, setColSchIndex] = useState(0);
  const [confirmDeleteModal, setConfirmDeleteModal] = useState(false);
  const [deleteColIndex, setDeleteColIndex] = useState<any>();
  const accordionRef = useRef<any>([]);

  const handleTableFieldsChange = (e: any) => {
    setDataTableSchema((old: any) => {
      return { ...old, [e.target.name]: e.target.value };
    });
  };
  const handleAddColumn = (columnData: any) => {
    setDataTableSchema((old: any) => {
      const type = columnData?.dataType;
      let newColumn = {
        ...columnData,
      };

      if (['multipleSelect', 'select'].includes(type)) {
        newColumn.options = { selectMenu: [], type: 'text', ...(newColumn?.options ?? {}) };
      }

      if (type === 'foreignField') {
        newColumn.fetchForeignData = { ...(newColumn?.fetchForeignData ?? {}) };
      }

      if (type === 'formula') {
        newColumn.formula = [...(newColumn?.formula ?? [])];
      }
      setTableUpdates({
        ...tableUpdates,
        addColumns: [...(tableUpdates?.addColumns ?? []), newColumn],
      });
      return {
        ...old,
        columnSchema: [...old.columnSchema, newColumn],
      };
    });
  };

  const handleTableUpdate = (tableUpdateData: any) => {
    setTableUpdates(tableUpdateData);
  };
  const handleUpdateTableDelete = ({ columnData }: { columnData: any }) => {
    const columnName = columnData?.columnName;
    let isAddColumnDelete = false;
    const addColumns = (tableUpdates?.addColumns ?? []).filter((col: any) => {
      const isNotFound = col?.columnName !== columnName;
      if (!isNotFound) {
        isAddColumnDelete = true;
      }
      return isNotFound;
    });
    const updateColumns = (tableUpdates?.updateColumns ?? []).filter(
      (elem: any) => elem?.columnName !== columnName
    );
    let deleteColumns = tableUpdates?.deleteColumns ?? [];
    if (!isAddColumnDelete) {
      deleteColumns = [...deleteColumns, columnData];
    }
    handleTableUpdate({
      ...tableUpdates,
      addColumns: addColumns,
      updateColumns: updateColumns,
      deleteColumns: deleteColumns,
    });
  };
  const handleDelete = (indx: number) => {
    setDataTableSchema((old: any) => {
      const newCols = [...old.columnSchema];
      newCols.splice(indx, 1);
      setDataTableSchema((old: any) => {
        return { ...old, columnSchema: newCols };
      });
    });
    const columnData = dataTableSchema?.columnSchema[indx];
    handleUpdateTableDelete({ columnData: columnData });
  };

  const handleOnDragEnd = (param: any) => {
    if (!param.destination) return;
    if (
      param.source.droppableId === param.destination.droppableId &&
      param.source.index === param.destination.index
    )
      return;
    const srcIndex = param.source.index;
    const destIndex = param.destination.index;
    let tmp = { ...dataTableSchema };
    let colSch = [...dataTableSchema.columnSchema];
    colSch.splice(destIndex, 0, colSch.splice(srcIndex, 1)[0]);
    tmp.columnSchema = colSch;
    setDataTableSchema(tmp);
  };

  const [currentExpandedAccordion, setCurrentExpandedAccordion] = useState('');

  return (
    <div className={styles.EditTableSettings}>
      <ConfirmDelete
        confirmDeleteModal={confirmDeleteModal}
        setConfirmDeleteModal={setConfirmDeleteModal}
        accordionRef={accordionRef}
        handleDelete={handleDelete}
        deleteColIndex={deleteColIndex}
        dataTableSchema={dataTableSchema}
        colSchIndex={colSchIndex}
      />

      <div className='mb-5' style={{ position: 'relative', top: 10 }}>
        <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Table name')}</label>
        <input
          value={dataTableSchema?.name}
          placeholder='Table name'
          className={clsx('form-control form-control-lg form-control-solid')}
          type='text'
          name='name'
          autoComplete='off'
          disabled
        />

        <div className='d-flex justify-content-between'>
          <label className='form-label fs-6 fw-bolder text-dark mt-5'>{mls('Description')}</label>
          <label className='form-label text-muted fs-6 fw-bolder text-dark mt-5'>
            {dataTableSchema?.description?.length || 0}/100
          </label>
        </div>
        <textarea
          maxLength={100}
          style={{ resize: 'none', height: 100 }}
          onChange={handleTableFieldsChange}
          value={dataTableSchema?.description}
          placeholder='Description'
          className={clsx('form-control form-control-lg form-control-solid')}
          name='description'
          autoComplete='off'
        />
      </div>
      <div>
        <div className=''>
          <label className='form-label fs-6 fw-bolder text-dark mt-3'>{mls('Columns')}</label>
          <DragDropContext
            onDragEnd={(param) => {
              handleOnDragEnd(param);
            }}
            // positionOffset={{ x: '-50%', y: '-50%' }}
          >
            <Droppable droppableId='droppable-1'>
              {(provided, snapshot) => (
                <div
                  className='accordion'
                  id='kt_accordion_1'
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {dataTableSchema?.columnSchema?.length === 0 && (
                    <p className='text-muted mx-8 mt-3'>{mls('No column schema available')}.</p>
                  )}
                  {dataTableSchema?.columnSchema?.map(
                    (sch: any, key: number, originalArray: any) => {
                      const setColumnData = (newData: any) => {
                        const updateColumnData = updateDataByIndex({
                          index: key,
                          arrayData: dataTableSchema?.columnSchema ?? [],
                          newData: newData,
                        });

                        setDataTableSchema((old: any) => {
                          return { ...old, columnSchema: updateColumnData };
                        });
                      };
                      // if (sch.dataType === erpAllDataType.belongsTo.key) {
                      //   return <></>;
                      // }
                      const columnData = dataTableSchema?.columnSchema?.[key];
                      const dataTypeElem =
                        erpAllDataType[columnData?.dataType as keyof typeof erpAllDataType];

                      if (sch.dataType === erpAllDataType.belongsTo.key) {
                        return (
                          <>
                            <Draggable
                              key={sch.columnName}
                              draggableId={`draggable-${key}`}
                              index={key}
                            >
                              {(providedDrag, _) => (
                                <Accordion
                                  elevation={1}
                                  ref={providedDrag.innerRef}
                                  {...providedDrag.draggableProps}
                                  key={sch.columnName}
                                  // style={{
                                  //   boxShadow: 'none',
                                  //   backgroundColor: '#f5f8fa',
                                  //   color: '#5e6278',
                                  // }}
                                  expanded={currentExpandedAccordion === sch.columnName}
                                  className={styles.accordionStyle}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    onClick={() => {
                                      setCurrentExpandedAccordion((current) => {
                                        if (current === sch.columnName) {
                                          return '';
                                        }
                                        return sch.columnName;
                                      });
                                    }}
                                  >
                                    <div
                                      ref={(el) => (accordionRef.current[key] = el)}
                                      id='kt_accordion_1'
                                      className='fs-5 fw-bold collapsed fw-bold mb-1 d-flex align-items-center'
                                      // type='button'
                                      data-bs-toggle='collapse'
                                      aria-expanded='false'
                                      aria-controls='kt_accordion_1_body_1'
                                      data-bs-target={`#edit_card_${key}`}
                                    >
                                      <i
                                        {...providedDrag.dragHandleProps}
                                        style={{ marginRight: 10 }}
                                        className='bi fs-3 bi-grip-horizontal mt-1'
                                      ></i>
                                      {mls(columnData?.name)}&nbsp;(
                                      {dataTypeElem?.name})
                                    </div>
                                  </AccordionSummary>
                                  <AccordionDetails className={styles.accordionDetails}>
                                    <ColumnSettings
                                      dataTableSchema={dataTableSchema}
                                      columnData={sch}
                                      setColumnData={setColumnData}
                                      tableUpdates={tableUpdates}
                                      setTableUpdates={setTableUpdates}
                                      setCurrentExpandedAccordion={setCurrentExpandedAccordion}
                                    >
                                      <button
                                        data-bs-toggle='modal'
                                        data-bs-target='#kt_modal_confirm_delete'
                                        id='kt_toolbar_primary_button'
                                        className='btn btn-sm mt-5  btn-danger btn-light-primary'
                                        onClick={(e) => {
                                          e.preventDefault();
                                          setColSchIndex(key);
                                          setConfirmDeleteModal(true);
                                          setDeleteColIndex(key);
                                        }}
                                      >
                                        {mls('Delete column')}
                                      </button>
                                    </ColumnSettings>
                                  </AccordionDetails>
                                </Accordion>
                              )}
                            </Draggable>
                          </>
                        );
                      }

                      return (
                        <>
                          <Draggable
                            key={sch.columnName}
                            draggableId={`draggable-${key}`}
                            index={key}
                          >
                            {(providedDrag, _) => (
                              <Accordion
                                elevation={1}
                                ref={providedDrag.innerRef}
                                {...providedDrag.draggableProps}
                                key={sch.columnName}
                                // style={{
                                //   boxShadow: 'none',
                                //   backgroundColor: '#f5f8fa',
                                //   color: '#5e6278',
                                // }}
                                expanded={currentExpandedAccordion === sch.columnName}
                                className={styles.accordionStyle}
                              >
                                <AccordionSummary
                                  expandIcon={<ExpandMoreIcon />}
                                  onClick={() => {
                                    setCurrentExpandedAccordion((current) => {
                                      if (current === sch.columnName) {
                                        return '';
                                      }
                                      return sch.columnName;
                                    });
                                  }}
                                >
                                  <div
                                    ref={(el) => (accordionRef.current[key] = el)}
                                    id='kt_accordion_1'
                                    className='fs-5 fw-bold collapsed fw-bold mb-1 d-flex align-items-center'
                                    // type='button'
                                    data-bs-toggle='collapse'
                                    aria-expanded='false'
                                    aria-controls='kt_accordion_1_body_1'
                                    data-bs-target={`#edit_card_${key}`}
                                  >
                                    <i
                                      {...providedDrag.dragHandleProps}
                                      style={{ marginRight: 10 }}
                                      className='bi fs-3 bi-grip-horizontal mt-1'
                                    ></i>
                                    {mls(columnData?.name)}&nbsp;(
                                    {dataTypeElem?.name})
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails className={styles.accordionDetails}>
                                  <ColumnSettings
                                    dataTableSchema={dataTableSchema}
                                    columnData={sch}
                                    setColumnData={setColumnData}
                                    tableUpdates={tableUpdates}
                                    setTableUpdates={setTableUpdates}
                                    setCurrentExpandedAccordion={setCurrentExpandedAccordion}
                                  >
                                    <button
                                      data-bs-toggle='modal'
                                      data-bs-target='#kt_modal_confirm_delete'
                                      id='kt_toolbar_primary_button'
                                      className='btn btn-sm mt-5  btn-danger btn-light-primary'
                                      onClick={(e) => {
                                        e.preventDefault();
                                        setColSchIndex(key);
                                        setConfirmDeleteModal(true);
                                        setDeleteColIndex(key);
                                      }}
                                    >
                                      {mls('Delete column')}
                                    </button>
                                  </ColumnSettings>
                                </AccordionDetails>
                              </Accordion>
                            )}
                          </Draggable>
                        </>
                      );
                    }
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
        {errorMessage && <p className='text-danger mx-4'>{errorMessage}</p>}
        <AddNewColumn
          handleAddColumn={handleAddColumn}
          dataTableSchema={dataTableSchema}
          tableUpdates={tableUpdates}
          setTableUpdates={setTableUpdates}
        />
      </div>

      <div>
        <RelationsComponent
          allDataTableData={allDataTableData}
          dataTableSchema={dataTableSchema}
          setDataTableSchema={setDataTableSchema}
          setRelatedTableList={setRelatedTableList}
          setTableUpdates={setTableUpdates}
          tableUpdates={tableUpdates}
          relatedTableList={relatedTableList}
        />
      </div>
    </div>
  );
};

export default EditTableSetting;

const tableList = [
  {
    columnList: ['Deletetion Nested 1'],
  },
  {
    columnList: ['Deletetion Main', 'Deletetion Nested 1'],
  },
  {
    columnList: ['Nested 1 Inner 2', 'Deletetion Main', 'Deletetion Nested 1'],
  },
];
const tableList2 = [
  {
    relationType: 'MAIN',
    tableID: 'Test Datatable For Deletetion Nested 1 Inner 2',
    tableList: [],
    defaultView: [
      {
        name: 'Email',
        description: '',
        filters: {},
        columnName: 'email',
      },
      {
        name: 'Erp Role',
        description: '',
        filters: {},
        columnName: 'erpRole',
      },
      {
        name: 'First Name',
        description: '',
        filters: {},
        columnName: 'firstName',
      },
      {
        name: 'Last Name',
        description: '',
        filters: {},
        columnName: 'lastName',
      },
      {
        name: 'Phone',
        description: '',
        filters: {},
        columnName: 'phone',
      },
      {
        name: 'PARTName',
        description: '',
        filters: {},
        columnName: 'PARTName',
      },
      {
        name: 'PARTName2',
        description: '',
        filters: {},
        columnName: 'PARTName2',
      },
      {
        name: '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
        description: '',
        filters: {},
        columnName:
          '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
        columnList: ['Deletetion Main', 'Deletetion Nested 1'],
        defaultView: [
          {
            name: 'Last Name',
            description: '',
            filters: {},
            columnName: 'lastName',
          },
          {
            name: 'Phone',
            description: '',
            filters: {},
            columnName: 'phone',
          },
          {
            name: 'PARTName',
            description: '',
            filters: {},
            columnName: 'PARTName',
          },
          {
            name: 'PARTName2',
            description: '',
            filters: {},
            columnName: 'PARTName2',
          },
        ],
      },
      {
        name: '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
        description: '',
        filters: {},
        columnName:
          '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
        columnList: [
          'Nested 1 Inner 2',
          'Deletetion Main',
          'Deletetion Nested 1',
          'Deletetion Nested 2',
        ],
        defaultView: [
          {
            name: 'Last Name',
            description: '',
            filters: {},
            columnName: 'lastName',
          },
          {
            name: 'PARTName2',
            description: '',
            filters: {},
            columnName: 'PARTName2',
          },
        ],
      },
      {
        name: '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
        description: '',
        filters: {},
        columnName:
          '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
        columnList: [
          'Nested 1 Inner 2',
          'Deletetion Main',
          'Deletetion Nested 1',
          'Deletetion Nested 2',
          'Deletetion Nested 3',
        ],
        defaultView: [
          {
            name: 'Last Name',
            description: '',
            filters: {},
            columnName: 'lastName',
          },
          {
            name: 'PARTName2',
            description: '',
            filters: {},
            columnName: 'PARTName2',
          },
        ],
      },
    ],
  },
  {
    relationType: 'belongsTo',
    defaultView: [
      {
        name: 'id',
        description: '',
        filters: {},
        columnName: 'id',
      },
      {
        name: 'Test Datatable For Deletetion Nested 1 ID',
        description: '',
        filters: {},
        columnName:
          '__d3id__Test Datatable For Deletetion Nested 1Id__Test Datatable For Deletetion Nested 1 ID',
      },
    ],
    tableID: 'Test Datatable For Deletetion Main',
    description: '',
    tableList: [
      {
        relationType: 'belongsTo',
        defaultView: [
          {
            name: 'id',
            description: '',
            filters: {},
            columnName: 'id',
          },
          {
            name: 'Test Datatable For Deletetion Nested 1 Inner 2 ID',
            description: '',
            filters: {},
            columnName:
              '__d3id__Test Datatable For Deletetion Nested 1 Inner 2Id__Test Datatable For Deletetion Nested 1 Inner 2 ID',
          },
          {
            name: 'Test Datatable For Deletetion Nested 1 Inner 1 ID',
            description: '',
            filters: {},
            columnName:
              '__d3id__Test Datatable For Deletetion Nested 1 Inner 1Id__Test Datatable For Deletetion Nested 1 Inner 1 ID',
          },
        ],
        tableID: 'Test Datatable For Deletetion Nested 1',
        description: '',
        tableList: [
          {
            relationType: 'belongsTo',
            defaultView: [
              {
                name: 'id',
                description: '',
                filters: {},
                columnName: 'id',
              },
              {
                name: '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
                description: '',
                filters: {},
                columnName:
                  '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
              },
            ],
            tableID: 'Test Datatable For Deletetion Nested 1 Inner 2',
            description: '',
            relationID: '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
            screenName: 'Test Datatable For Deletetion Nested 1 Inner 2',
            parentRelationIDList: [
              'c5ffea0d-62af-43fb-a9f1-521b0f5bbe9f',
              '78dc3d2d-b4b2-4a09-a3bc-75231593ff0b',
            ],
            parentRelationID: 'c5ffea0d-62af-43fb-a9f1-521b0f5bbe9f',
          },
        ],
        relationID: 'c5ffea0d-62af-43fb-a9f1-521b0f5bbe9f',
        screenName: 'Test Datatable For Deletetion Nested 1',
        parentRelationIDList: ['78dc3d2d-b4b2-4a09-a3bc-75231593ff0b'],
        parentRelationID: '78dc3d2d-b4b2-4a09-a3bc-75231593ff0b',
      },
    ],
    relationID: '78dc3d2d-b4b2-4a09-a3bc-75231593ff0b',
    screenName: 'Test Datatable For Deletetion Main',
  },
  {
    relationType: 'hasMany',
    defaultView: [
      {
        name: 'Email',
        description: '',
        filters: {},
        columnName: 'email',
      },
      {
        name: 'Erp Role',
        description: '',
        filters: {},
        columnName: 'erpRole',
      },
      {
        name: 'First Name',
        description: '',
        filters: {},
        columnName: 'firstName',
      },
      {
        name: 'Last Name',
        description: '',
        filters: {},
        columnName: 'lastName',
      },
      {
        name: 'Phone',
        description: '',
        filters: {},
        columnName: 'phone',
      },
      {
        name: 'PARTName',
        description: '',
        filters: {},
        columnName: 'PARTName',
      },
      {
        name: 'PARTName2',
        description: '',
        filters: {},
        columnName: 'PARTName2',
      },
      {
        name: 'Test Datatable For Deletetion Nested 1 Inner 2 ID',
        description: '',
        filters: {},
        columnName:
          '__d3id__Test Datatable For Deletetion Nested 1 Inner 2Id__Test Datatable For Deletetion Nested 1 Inner 2 ID',
      },
      {
        name: 'Test Datatable For Deletetion Nested 1 Inner 1 ID',
        description: '',
        filters: {},
        columnName:
          '__d3id__Test Datatable For Deletetion Nested 1 Inner 1Id__Test Datatable For Deletetion Nested 1 Inner 1 ID',
      },
    ],
    tableID: 'Test Datatable For Deletetion Nested 1',
    description: '',
    tableList: [
      {
        relationType: 'belongsTo',
        defaultView: [
          {
            name: 'id',
            description: '',
            filters: {},
            columnName: 'id',
          },
          {
            name: '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
            description: '',
            filters: {},
            columnName:
              '__d3id__Test Datatable For Deletetion MainId__Test Datatable For Deletetion Nested 1 Inner 2 ID',
          },
        ],
        tableID: 'Test Datatable For Deletetion Nested 1 Inner 2',
        description: '',
        tableList: [
          {
            relationType: 'belongsTo',
            defaultView: [
              {
                name: 'id',
                description: '',
                filters: {},
                columnName: 'id',
              },
              {
                name: 'Test Datatable For Deletetion Nested 1 ID',
                description: '',
                filters: {},
                columnName:
                  '__d3id__Test Datatable For Deletetion Nested 1Id__Test Datatable For Deletetion Nested 1 ID',
              },
            ],
            tableID: 'Test Datatable For Deletetion Main',
            description: '',
            relationID: '78dc3d2d-b4b2-4a09-a3bc-75231593ff0b',
            screenName: 'Test Datatable For Deletetion Main',
            parentRelationIDList: [
              '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
              '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
            ],
            parentRelationID: '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
          },
        ],
        relationID: '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
        screenName: 'Test Datatable For Deletetion Nested 1 Inner 2',
        parentRelationIDList: ['28671f66-68e6-4d14-bd22-3bf21bf2c9fc'],
        parentRelationID: '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
      },
    ],
    relationID: '28671f66-68e6-4d14-bd22-3bf21bf2c9fc',
    screenName: 'Test Datatable For Deletetion Nested 1',
  },
];
