import { CircularProgress } from '@mui/material';
import { mls } from 'lib/multilanguagesupport';
import { publishToProduction } from 'lib/reusable-components/reusableFunction/publishToProductions';
import { _supistaApiPost, _supistaUploadFileToServer } from 'lib/server-connection/connections';
import React, { useState } from 'react';
import styles from './AppAssets.module.scss';
import PopUpDivChild from 'lib/reusable-components/PopUpDivChild/PopUpDivChild';
const UploadAssets = ({
  isNewAssets,
  setIsNewAssets,
  appID,
  appData,
}: {
  isNewAssets: boolean;
  appID: string;
  appData: { [key: string]: any };
  setIsNewAssets: Function;
}) => {
  const [fileName, setFileName] = useState('');
  const [file, setFile] = useState<File | null>(null);
  const [error, setError] = useState<{ [key: string]: any } | null>({});
  const [uploadedImages, setUploadedImages] = useState<any>();

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedFile = event.target.files?.[0];

    if (!selectedFile) {
      setError({ file: 'No file selected' });
      return;
    }

    // Validate file type
    const validTypes = ['image/png', 'image/jpeg'];
    if (!validTypes.includes(selectedFile.type)) {
      setError({ file: 'Invalid file type. Only PNG and JPEG are allowed.' });
      setFile(null);
      return;
    }

    // Validate file size (10 MB = 10 * 1024 * 1024 bytes)
    const maxSize = 10 * 1024 * 1024;
    if (selectedFile.size > maxSize) {
      setError({ file: 'File size exceeds 10 MB limit.' });
      setFile(null);
      return;
    }

    // If validation passes
    setError({});
    setFile(selectedFile);
  };
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async () => {
    if (isLoading) {
      return;
    }
    if (validation()) {
      return;
    }

    let error: { [key: string]: any } = {};
    if (file) {
      setIsLoading(true);
      // const endpoint = `manageFile/${appID}/assets/component`;
      const endpoint = `manageFile/${appID}/assets/${appID}`;
      const formData = new FormData();
      formData.append('__d3__file', file);
      formData.append('fileName', fileName);

      const response = await _supistaUploadFileToServer(endpoint, formData);
      if (response?.__d3__error || !response?.data?.fileName) {
        error = { ...error, file: 'Could not upload file on the server!' };
        return;
      }
      const fileData = {
        assetName: fileName,
        fileName: response?.data?.fileName,
        fileUrl: response?.data?.fileID,
      };
      const newAssets = { ...(appData?.assets ?? {}), [fileData.fileUrl]: fileData };
      _supistaApiPost(`settings/${appID}/App`, {
        data: {
          ...appData,
          assets: newAssets,
        },
      })
        .then(async (res) => {
          await publishToProduction(appID);
          setIsNewAssets(false);
          setIsLoading(false);
        })
        .catch((err) => {
          setIsLoading(false);
        });
      // setIsLoading(false);
    } else {
      error = { ...error, file: 'No file selected' };
      setError(error);
      setIsLoading(false);
    }
  };
  const validation = () => {
    let error: { [key: string]: any } = {};
    let isError = false;
    if (!fileName) {
      error = { ...error, name: 'Please Enter File Name' };
      isError = true;
    }
    const fileNamePresent = Object.values(appData?.assets ?? {}).some(
      (asset: any) => asset.assetName === fileName.trim()
    );

    if (fileNamePresent) {
      error = { ...error, name: 'File Name Already Present!' };
      isError = true;
    }
    // if (!file) {
    //   error = { ...error, file: 'No file selected' };
    //   isError = true;
    // }
    setError(error);
    return isError;
  };
  const removeImage = () => {
    setUploadedImages(null);
  };
  return (
    <PopUpDivChild
      initialValue={isNewAssets}
      setPopUp={() => {
        setIsNewAssets(false);
      }}
      className={'uploadAssetsPopup'}
      popupTitle={mls('Upload Assest')}
    >
      <div className={styles.uploadAssetsContainer}>
        <label className={styles.assestNameLabel}>{mls('Assest Name')}</label>
        <input
          type='text'
          placeholder={mls('Assest Name')}
          className='primaryInput'
          value={fileName}
          onChange={(e) => {
            setFileName(e.target.value);
          }}
          onBlur={() => {
            validation();
          }}
        />
        {error?.name && <div style={{ color: 'red' }}>{error?.name}</div>}
        <input
          type='file'
          accept='.png, .jpeg'
          className='primaryInput'
          onChange={handleFileChange}
        />
        {error?.file && <div style={{ color: 'red' }}>{error?.file}</div>}
        {uploadedImages ? (
          <div className={styles.imageContainer}>
            <img src={uploadedImages} alt={`Previous`} className={styles.image} />
            <button className={styles.removeButton} onClick={() => removeImage()}>
              Remove
            </button>
          </div>
        ) : null}
        {/* {file && <p>Selected file: {file.name}</p>} */}
        <div className={styles.uploadAssetsButtonContainer}>
          <button
            className='primaryButton'
            type='button'
            onClick={() => {
              handleSubmit();
            }}
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                <CircularProgress size={10} color='inherit' /> &nbsp;
              </>
            ) : null}
            {mls('Upload Assest')}
          </button>
        </div>
      </div>
    </PopUpDivChild>
  );
};

export default UploadAssets;
