import React, { useState, useMemo, useEffect } from 'react';
import AICodeForm from './AICodeForm';
import AIGeneratedCodeEditor from './AIGeneratedCodeEditor';
// import styles from '../../ComponentSettingWrapper.module.scss';
import styles from './codeCustomization.module.scss';
import { _supistaApiGet, _supistaApiPost } from 'lib/server-connection/connections';
import { Row, Col, Form } from 'react-bootstrap';

import { v4 as uuid } from 'uuid';
import {
  buttonTypeObject,
  crudTypeButtonObject,
  specialDataTypeName,
} from '../../data/appComponentData';
import { mls } from 'lib/multilanguagesupport';
import ObjectDropDown from 'lib/reusable-components/Components/DropDowns/ObjectDropDown';
import {
  getDefaultCode,
  getDefaultGeneratedCode,
  getFinalTableID,
  getGroupOptionData,
  getSelectTableFinalKey,
} from '../../utils/componentSettingFunction';
import SampleDataPopup from './SampleDataPopup';
import TableSchemaSelection from './AICustomizationEditorContainer/TableSchemaSelection';
import './selectTableDropdown.scss';
import { relationNestedLevel } from 'lib/erp-data-table/components/util/componentUpdateFunction';
const isCrudCustomizedFunction = ({ componentSchema, selectedTable }) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  return selectedCode ? true : false;
};
const ComponentAiCustomization = ({
  schema,
  componentRowList,
  componentSchema,
  appID,
  appDatatable,
  setComponentSchema,
  connectedTablesData,
}) => {
  const [selectedTable, setSelectedTable] = useState(
    Object.keys(connectedTablesData ?? {})?.[0] ?? ''
  );
  const [isCodeDataChange, setIsCodeDataChange] = useState(true);

  const connectedTablesDataForSelectTable = useMemo(() => {
    let selectedConnectedTableData = {};
    (componentSchema?.tableList ?? []).forEach((tableListElem) => {
      const finalTableID = getFinalTableID({
        tableID: tableListElem?.tableID,
        relationType: tableListElem?.relationType,
        relationID: tableListElem?.relationID,
      });
      if (connectedTablesData?.[finalTableID]) {
        selectedConnectedTableData = {
          ...selectedConnectedTableData,
          [finalTableID]: connectedTablesData?.[finalTableID] ?? {},
        };
      }
    });
    return selectedConnectedTableData;
  }, [connectedTablesData, componentSchema?.tableList]);
  const { groupOptionData, updatedConnectedTablesData } = useMemo(() => {
    const { groupOptionData, updatedConnectedTablesData } = getGroupOptionData({
      connectedTablesData: connectedTablesDataForSelectTable,
      nestedLevel: 3,
    });
    return { groupOptionData: groupOptionData, updatedConnectedTablesData };
  }, [connectedTablesDataForSelectTable]);
  const selectedTableData = updatedConnectedTablesData[selectedTable];
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];

  const [generatedCode, setGeneratedCode] = useState(
    getDefaultGeneratedCode({ componentSchema, selectedTable })
  );

  const [isSchemaInitialized, setIsSchemaInitialized] = useState(
    isCrudCustomizedFunction({ componentSchema, selectedTable })
  );
  const [tableSchema, setTableSchema] = useState([]);

  const updateCodeCustomization = ({ selectedTable, codeCustomizationData }) => {
    const updatedComonentSchema = {
      ...(componentSchema ?? {}),
      codeCustomization: {
        ...(componentSchema?.codeCustomization ?? {}),
        [selectedTable]: codeCustomizationData,
      },
    };
    setComponentSchema(updatedComonentSchema);
  };
  const newConnectedTableData = useMemo(() => {
    let tableName = [];
    let columnSchema = [];
    Object.keys(connectedTablesData ?? {}).forEach((connectedTablesKey) => {
      tableName = [...tableName, connectedTablesKey];
      const connectedTablesElem = connectedTablesData[connectedTablesKey];
      const newColumSchema = (connectedTablesElem?.columnSchema ?? []).map((columnElem) => {
        return { ...columnElem, tableID: connectedTablesKey };
      });
      columnSchema = [...columnSchema, ...newColumSchema];
    });
    return {
      tableName: tableName,
      columnSchema: columnSchema,
    };
  }, [connectedTablesData]);

  const [isAiQueryLoading, setIsAiQueryLoading] = useState(false);

  const handleGenerateCode = async (requests) => {
    // Initialize default schema
    const baseUrl = `aiQuery/${appID}`;
    const data = {
      queryArray: [
        {
          promptType: 'CRUD',
          query: requests?.create ?? '',
          apiType: 'POST',
        },
        {
          promptType: 'CRUD',
          query: requests?.read ?? '',
          apiType: 'GET',
        },
        {
          promptType: 'CRUD',
          query: requests?.delete ?? '',
          apiType: 'DELETE',
        },
        {
          promptType: 'CRUD',
          query: requests?.update ?? '',
          apiType: 'PUT',
        },
        {
          promptType: 'CRUD',
          query: requests?.formValidation ?? '',
          apiType: 'PUT',
        },
        {
          promptType: 'CRUD',
          query: requests?.actionButtons ?? '',
          apiType: 'PUT',
        },
      ],
      data: {
        tableName: selectedTable,
        columnSchema: newConnectedTableData?.columnSchema,
      },
    };

    setIsAiQueryLoading(true);
    const componentRes = await _supistaApiPost(baseUrl, { data });
    if (componentRes) {
      const queryData = {
        create: componentRes[0] ?? '',
        read: componentRes[1] ?? '',
        delete: componentRes[2] ?? '',
        update: componentRes[3] ?? '',
        formValidation: componentRes[4] ?? '',
        actionButtons: componentRes[5] ?? '',
      };
      const POSTData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.POST.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const GETData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.GET.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const DELETEData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.DELETE.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const PUTData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: crudTypeButtonObject.PUT.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const formCustomizationData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: buttonTypeObject.formCustomization.key,
        buttonType: buttonTypeObject.CRUD.key,
      });
      const actionButtonID = uuid();

      const actionButtonData = getDefaultCode({
        componentSchema,
        selectedTable,
        actionButtonKey: actionButtonID,
        buttonType: buttonTypeObject.actionButton.key,
      });
      let crudOp = {};
      if (selectedTableData?.relationType === specialDataTypeName?.belongsTo?.key) {
        crudOp = {
          GET: { ...GETData, functionCode: queryData?.read?.response ?? '' },
        };
      } else {
        crudOp = {
          POST: { ...POSTData, functionCode: queryData?.create?.response ?? '' },
          GET: { ...GETData, functionCode: queryData?.read?.response ?? '' },
          DELETE: { ...DELETEData, functionCode: queryData?.delete?.response ?? '' },
          PUT: { ...PUTData, functionCode: queryData?.update?.response ?? '' },
        };
      }
      const formValidation = {
        ...formCustomizationData,
        functionCode: queryData?.formCustomization?.response ?? '',
      };
      const actionButton = {
        ...actionButtonData,
        functionCode: queryData?.actionButtons?.response ?? '',
      };
      let codeCustomizationData = {};
      if (selectedTableData?.relationType === specialDataTypeName?.belongsTo?.key) {
        codeCustomizationData = {
          crudOp,
        };
      } else {
        codeCustomizationData = {
          crudOp,
          formValidation,
          actionButton: { [actionButtonID]: actionButton },
        };
      }
      updateCodeCustomization({
        selectedTable,
        codeCustomizationData: codeCustomizationData,
      });
      setIsAiQueryLoading(false);
      setIsSchemaInitialized(true);
    } else {
      setIsSchemaInitialized(true);
      setIsAiQueryLoading(false);
    }
  };
  const handleAddCodeManually = () => {
    setIsSchemaInitialized(true);
  };
  const handleEditCode = (name, value) => {
    setGeneratedCode((prevCode) => ({
      ...prevCode,
      [name]: { ...prevCode[name], functionCode: value },
    }));
  };
  const handleActionButtonsEdit = (actionButtons) => {
    if (schema) {
      schema.actionButton = actionButtons;
      setGeneratedCode((prevCode) => ({
        ...prevCode,
        actionButton: schema.actionButton,
      }));
    }
  };

  const handleTableSelection = (action) => {
    setSelectedTable(action);
    setIsCodeDataChange(true);
  };
  useEffect(() => {
    setIsSchemaInitialized(isCrudCustomizedFunction({ componentSchema, selectedTable }));
  }, [selectedTable]);

  const [selectedId, setSelectedId] = useState(componentRowList?.[0]?.id ?? '');
  const handleComponentChange = (e) => {
    setSelectedId(e.target.value);
  };
  const [isSampleDataPopup, setIsSampleDataPopup] = useState(false);
  const [sampleData, setSampleData] = useState([]);
  useEffect(() => {
    (async () => {
      let urlData = {};
      urlData = {
        __d3__filterdata: {
          where: {},
        },
      };
      if (selectedId || selectedId === '0' || selectedId === 0) {
        urlData = {
          __d3__filterdata: {
            where: { id: selectedId },
          },
        };
      }
      if (selectedTableData?.relationType !== 'MAIN') {
        urlData = {
          ...urlData,
          __d3__filterdata: {
            where: {},
          },
          __d3__parentId: selectedId ?? null,
          __d3__relationId: selectedTableData?.relationID,
        };
      }
      const filterUrl = `?data=${JSON.stringify(urlData)}`;
      const componentID = componentSchema?.componentID;
      const baseUrl = `app/${appID}/CRUD/${componentID}`;
      const finalUrl = `${baseUrl}${filterUrl}`;
      const componentRes = await _supistaApiGet(finalUrl);
      setSampleData(componentRes?.rows ?? []);
    })();
  }, [selectedId, componentSchema?.componentID, appID]);
  const [isTableSchema, setIsTableSchema] = useState(false);
  const updateTableSchemaFunction = (tableSchema) => {
    // updateCodeCustomization({
    //   selectedTable,
    //   codeCustomizationData: {
    //     ...selectedCode,
    //     tableSchema,
    //   },
    // });
    setTableSchema(tableSchema);
  };

  return (
    <div className={styles.aiCustomization}>
      <div className={styles.aiCustomizationHeader}>
        <div className={styles.selectedTableContainer}>
          <div className={styles.selectedTableTitle}>{mls('Select Table')}</div>
          <div className={styles.selectedTableDropDown}>
            <ObjectDropDown
              object={groupOptionData}
              dropDownValue={selectedTable}
              setdropDownValue={handleTableSelection}
              groupDropDown
              groupDropDownType={'groupAndOptionSelectable'}
              groupDropDownNestingLevel={relationNestedLevel - 2}
              inputPlaceHolder={mls('Select Table')}
              search
              className='ComponentSelectTableDropdown'
            />
          </div>
        </div>
        <div style={{ width: 'auto', display: 'flex', textAlign: 'end' }}>
          <Form.Group as={Row} className='' style={{ marginLeft: 0, marginRight: 0 }}>
            <Form.Label column sm={3}>
              {mls('Id')}:
            </Form.Label>
            <Col sm={9}>
              <Form.Control
                as='select'
                name='selectId'
                value={selectedId}
                style={{ textAlign: 'end' }}
                onChange={(e) => handleComponentChange(e)}
              >
                <option value='' disabled>
                  {mls('Select Id')}
                </option>
                {React.Children.toArray(
                  (componentRowList ?? []).map((componentRowData) => {
                    return (
                      <option value={componentRowData?.id ?? ''} disabled={false}>
                        {componentRowData?.id ?? ''}
                      </option>
                    );
                  })
                )}
              </Form.Control>
            </Col>
          </Form.Group>
        </div>
        <div
          style={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <div>
            {isSampleDataPopup ? (
              <SampleDataPopup
                isSampleDataPopup={isSampleDataPopup}
                setIsSampleDataPopup={setIsSampleDataPopup}
                sampleData={sampleData}
              />
            ) : null}
            <button
              className='primaryButton secondaryButtonColor'
              onClick={() => setIsSampleDataPopup(true)}
              type='button'
            >
              {mls('Selected Row Data')}
            </button>
          </div>
        </div>
        <div
          style={{
            width: 'auto',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: '1rem',
          }}
        >
          <button
            className='primaryButton secondaryButtonColor'
            onClick={() => setIsTableSchema(true)}
          >
            {mls('Table Schema')}
          </button>
          {isTableSchema ? (
            <TableSchemaSelection
              isTableSchema={isTableSchema}
              appDatatable={appDatatable}
              setIsTableSchema={setIsTableSchema}
              connectedTablesData={connectedTablesData}
              componentSchema={componentSchema}
              updateTableSchemaFunction={updateTableSchemaFunction}
              // tableSchema={selectedCode?.tableSchema ?? []}
              tableSchema={tableSchema}
              selectedData={selectedCode}
              selectedCustomizationTable={selectedTable}
            />
          ) : null}
        </div>
      </div>
      <div className={styles.aiCustomizationInner}>
        {!isSchemaInitialized && false ? (
          <AICodeForm
            onGenerateCode={handleGenerateCode}
            appID={appID}
            isAiQueryLoading={isAiQueryLoading}
            handleAddCodeManually={handleAddCodeManually}
            componentSchema={componentSchema}
            setComponentSchema={setComponentSchema}
            componentRowList={componentRowList}
            selectedTableData={selectedTableData}
          />
        ) : (
          <AIGeneratedCodeEditor
            schema={{ crudOp: generatedCode }}
            onEdit={handleEditCode}
            onActionButtonsEdit={handleActionButtonsEdit}
            componentSchema={componentSchema}
            setComponentSchema={setComponentSchema}
            appDatatable={appDatatable}
            componentRowList={componentRowList}
            sampleData={sampleData}
            connectedTablesData={updatedConnectedTablesData}
            groupedConnectedTablesData={connectedTablesData}
            newConnectedTableData={updatedConnectedTablesData}
            // connectedTablesData={connectedTablesData}
            // newConnectedTableData={newConnectedTableData}
            appID={appID}
            selectedTable={selectedTable}
            selectedId={selectedId}
            setSelectedId={setSelectedId}
            selectedCode={selectedCode}
            selectedTableData={selectedTableData}
            updateCodeCustomization={updateCodeCustomization}
            isCodeDataChange={isCodeDataChange}
            setIsCodeDataChange={setIsCodeDataChange}
            tableSchema={tableSchema}
          />
        )}
      </div>
    </div>
  );
};

export default ComponentAiCustomization;
