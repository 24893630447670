import { erpAllDataType } from 'lib/erp-data-table/components/data/erpData';
import {
  buttonTypeObject,
  crudOpType,
  crudTypeButtonObject,
  defaultCode,
  defaultValidationCode,
} from '../data/appComponentData';
import { v4 as uuid } from 'uuid';
import {
  defaultTemplateCode,
  templateTypeObject,
} from '../TabContent/TemplatesWrapper/templateData';
import { relationNestedLevel } from 'lib/erp-data-table/components/util/componentUpdateFunction';
import { defaultTimeInterval } from '../TabContent/CodeCustomization/TimeInterval/timeIntervalData';

export const getDefaultCode = ({
  componentSchema,
  actionButtonKey,
  buttonType,
  selectedTable,
}: {
  componentSchema: { [key: string]: any };
  actionButtonKey: string;
  buttonType: string;
  selectedTable: string;
}) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const crudOpData = selectedCode?.crudOp ?? {};
  const formValidationData = selectedCode?.formValidation ?? {};
  const bulkCreateData = selectedCode?.bulkCreate ?? {};
  const actionButtonDataArr = selectedCode?.actionButtonArr ?? [];
  const tablewiseActionData = selectedCode?.tablewiseAction ?? [];
  const uploadCSVData = selectedCode?.uploadCSV ?? [];
  const cronJobData = selectedCode?.cronJob ?? {};
  let selectedData: { [key: string]: any } = {};
  let codePresent = false;
  if (buttonType === buttonTypeObject.CRUD.key) {
    if (crudOpData?.[actionButtonKey]?.type === crudOpType.CUSTOM.key) {
      selectedData = crudOpData?.[actionButtonKey];
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'displayFunc',
        functionID: functionID,
        functionCode: defaultCode,
        params: [],
        type: crudOpType.CUSTOM.key,
      };
      selectedData = updatedCrudFunction;
    }
  } else if (buttonType === buttonTypeObject.formValidation.key) {
    if (formValidationData?.type === crudOpType.CUSTOM.key) {
      selectedData = formValidationData;
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'displayFunc',
        functionID: functionID,
        functionCode: defaultValidationCode,
        displayWhen: {},
        displayTo: [],
        title: 'New Action',
      };

      selectedData = updatedCrudFunction;
    }
  } else if (buttonType === buttonTypeObject.bulkCreate.key) {
    if (bulkCreateData?.type === crudOpType.CUSTOM.key) {
      selectedData = bulkCreateData;
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'displayFunc',
        functionID: functionID,
        functionCode: defaultValidationCode,
        displayWhen: {},
        displayTo: [],
        title: 'New Bulk Create',
      };
      selectedData = updatedCrudFunction;
    }
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    const actionButtonDataElem = actionButtonDataArr.find(
      (actionButtonElem: any) => actionButtonElem.actionButtonID === actionButtonKey
    );
    if (actionButtonDataElem) {
      selectedData = actionButtonDataElem;
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'actionFunc',
        functionID: functionID,
        functionCode: defaultCode,
        actionButtonID: actionButtonKey,
        displayWhen: {},
        displayTo: [],
        title: 'New Action',
      };
      selectedData = updatedCrudFunction;
    }
  } else if (buttonType === buttonTypeObject.tablewiseAction.key) {
    const actionButtonDataElem = tablewiseActionData.find(
      (actionButtonElem: any) => actionButtonElem.actionButtonID === actionButtonKey
    );
    if (actionButtonDataElem) {
      selectedData = actionButtonDataElem;
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'actionFunc',
        functionID: functionID,
        functionCode: defaultCode,
        actionButtonID: actionButtonKey,
        displayWhen: {},
        displayTo: [],
        title: 'New Tablewise Action',
      };
      selectedData = updatedCrudFunction;
    }
  } else if (buttonType === buttonTypeObject.uploadCSV.key) {
    const actionButtonDataElem = uploadCSVData.find(
      (actionButtonElem: any) => actionButtonElem.actionButtonID === actionButtonKey
    );
    if (actionButtonDataElem) {
      selectedData = actionButtonDataElem;
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'actionFunc',
        functionID: functionID,
        functionCode: defaultCode,
        actionButtonID: actionButtonKey,
        displayWhen: {},
        displayTo: [],
        title: 'New Upload CSV Button',
      };
      selectedData = updatedCrudFunction;
    }
  } else if (buttonType === buttonTypeObject.cronJob.key) {
    if (cronJobData?.[actionButtonKey]) {
      selectedData = cronJobData?.[actionButtonKey];
      codePresent = true;
    } else {
      const functionID = uuid();
      const updatedCrudFunction = {
        fuctionName: 'actionFunc',
        functionID: functionID,
        functionCode: defaultCode,
        scheduler: defaultTimeInterval,
        displayWhen: {},
        displayTo: [],
        title: 'New Scheduler',
      };

      selectedData = updatedCrudFunction;
    }
  }
  return { selectedData, codePresent };
};
export const getDefaultInput = ({
  connectedTablesData,
  selectedTable,
  buttonType,
  sampleData,
  parentId,
  selectedId,
  actionButtonKey,
}: {
  connectedTablesData: { [key: string]: any };
  selectedTable: string;
  buttonType: string;
  sampleData: any;
  parentId: string | number;
  selectedId: string | number;
  actionButtonKey: string;
}) => {
  const selectedTableData = connectedTablesData[selectedTable];
  const isRelationID = selectedTableData?.relationType !== 'MAIN';
  const relationID = selectedTableData?.relationID;

  if (buttonType === buttonTypeObject.CRUD.key) {
    if (actionButtonKey === crudTypeButtonObject.POST.key) {
      const newDataInString = getSampleDataFromColumnSchemaForAI({
        columnSchema: selectedTableData?.columnSchema ?? [],
      });
      if (isRelationID) {
        return `{
    "__d3__newData": ${newDataInString},
    "__d3__parentId":${parentId || 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__newData": ${newDataInString}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.GET.key) {
      if (isRelationID) {
        return `{
    "__d3__filterdata": {"where": {}},
    "__d3__parentId": ${parentId || 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__filterdata": {"where": {}}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.PUT.key) {
      let newDataInString = '{}';
      if (sampleData?.[0]) {
        newDataInString = getStringDataFromSampleData({
          sampleData: sampleData?.[0] ?? {},
          selectedTableData,
        });
      }

      if (isRelationID) {
        return `{
    "__d3__newData": ${newDataInString},
    "__d3__id": ${selectedId || 0},
    "__d3__parentId": ${parentId || 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
      "__d3__newData": ${newDataInString},
      "__d3__id": ${selectedId || 0}
}`;
    }
    if (actionButtonKey === crudTypeButtonObject.DELETE.key) {
      if (isRelationID) {
        return `{
    "__d3__parentId": ${parentId || 0},
    "__d3__id": ${selectedId || 0},
    "__d3__relationId": "${relationID}"
}`;
      }
      return `{
    "__d3__id": ${selectedId || 0}
}`;
    }
  } else if (buttonType === buttonTypeObject.formValidation.key) {
    let newDataInString = '{}';
    if (sampleData?.[0]) {
      newDataInString = getStringDataFromSampleData({
        sampleData: sampleData?.[0] ?? {},
        selectedTableData,
      });
    }
    if (isRelationID) {
      return `{
  "__d3__formData":${newDataInString},
  "__d3__parentId": ${parentId || 0},
  "__d3__relationId": "${relationID}",
  "__d3__error": {},
  "__d3__schema": {}
}`;
    }
    return `{
    "__d3__formData":${newDataInString},
    "__d3__error": {},
    "__d3__schema": {}
}`;
  } else if (buttonType === buttonTypeObject.bulkCreate.key) {
    if (sampleData?.[0]) {
      const newSampleData = (sampleData ?? []).map((sampleDataElem: { [key: string]: any }) => {
        return getNewSampleDataForAI({
          sampleData: sampleDataElem,
          selectedTableData,
          isIDRemove: false,
        });
      });
      return JSON.stringify(newSampleData);
    }
    if (isRelationID) {
      return `{
  "__d3__newData": {},
  "__d3__parentId": ${parentId || 0},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__newData": {}
}`;
  } else if (buttonType === buttonTypeObject.actionButton.key) {
    if (sampleData?.[0]) {
      const newSampleData = (sampleData ?? []).map((sampleDataElem: { [key: string]: any }) => {
        return getNewSampleDataForAI({
          sampleData: sampleDataElem,
          selectedTableData,
          isIDRemove: false,
        });
      });
      return JSON.stringify(newSampleData);
    }
    if (isRelationID) {
      return `{
  "__d3__newData": {},
  "__d3__parentId": ${parentId || 0},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__newData": {}
}`;
  } else if (buttonType === buttonTypeObject.tablewiseAction.key) {
    if (isRelationID) {
      return `{
    "__d3__filterdata": {"where": {}},
    "__d3__parentId": ${parentId || 0},
    "__d3__relationId": "${relationID}"
}`;
    }
    return `{
      "__d3__filterdata": {"where": {}}
}`;
  } else if (buttonType === buttonTypeObject.uploadCSV.key) {
    if (isRelationID) {
      return `{
    "__d3__filterdata": {"where": {}},
    "__d3__parentId": ${parentId || 0},
    "__d3__relationId": "${relationID}"
}`;
    }
    return `{
      "__d3__filterdata": {"where": {}}
}`;
  } else if (buttonType === buttonTypeObject.cronJob.key) {
    if (isRelationID) {
      return `{
  "__d3__newData": {},
  "__d3__parentId": ${parentId || 0},
  "__d3__relationId": "${relationID}"
}`;
    }
    return `{
    "__d3__newData": {}
}`;
  }
  return '{}';
};
export const getDefaultGeneratedCode = ({
  componentSchema,
  selectedTable,
}: {
  componentSchema: { [key: string]: any };
  selectedTable: string;
}) => {
  const codeCustomization = componentSchema?.codeCustomization ?? {};
  const selectedCode = codeCustomization?.[selectedTable];
  const crudOpData = selectedCode?.crudOp ?? {};
  const formValidationData = selectedCode?.formValidation ?? {};
  const bulkCreateData = selectedCode?.bulkCreate ?? {};
  const actionButtonData = selectedCode?.actionButton ?? {};
  const finalCode = {
    POST:
      crudOpData?.POST?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.POST,
    GET:
      crudOpData?.GET?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.GET,
    DELETE:
      crudOpData?.DELETE?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.DELETE,
    PUT:
      crudOpData?.PUT?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : crudOpData?.PUT,
    [buttonTypeObject.formValidation.key]:
      formValidationData?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : formValidationData,
    [buttonTypeObject.bulkCreate.key]:
      bulkCreateData?.type === crudOpType.DEFAULT.key
        ? { functionCode: defaultCode }
        : bulkCreateData,
    actionButton: actionButtonData ?? {},
    tablewiseAction: actionButtonData ?? {},
  };
  return finalCode;
};
export const getIDColumnName = (tableID = '', relationName = '') => {
  if (relationName) {
    return `__d3id__${tableID}Id__${relationName}`;
  }
  return `__d3id__${tableID}Id`;
};
const getNewSampleDataForAI = ({
  sampleData,
  selectedTableData,
  isIDRemove = true,
  isRelatedIDRemove = true,
}: {
  sampleData: { [key: string]: any };
  selectedTableData: { [key: string]: any };
  isIDRemove?: boolean;
  isRelatedIDRemove?: boolean;
}) => {
  let finalSampleDataObject = {};
  Object.keys(sampleData ?? {}).forEach((sampleDataKey) => {
    const sampleDataElem = sampleData[sampleDataKey];
    if (sampleDataKey === 'id' && isIDRemove) {
      return;
    }
    if (sampleDataKey.endsWith('.id') && isRelatedIDRemove) {
      const relationID = sampleDataKey.slice(0, -3);
      const isMatch = Object.values(selectedTableData?.relations ?? {})?.some(
        (relationElem: any) => {
          return relationElem?.as === relationID;
        }
      );
      if (isMatch) {
        return;
      }
    }
    finalSampleDataObject = {
      ...finalSampleDataObject,
      [sampleDataKey]: sampleDataElem,
    };
  });
  return finalSampleDataObject;
};
const getStringDataFromSampleData = ({
  sampleData,
  selectedTableData,
  isIDRemove = true,
  isRelatedIDRemove = true,
}: {
  sampleData: { [key: string]: any };
  selectedTableData: { [key: string]: any };
  isIDRemove?: boolean;
  isRelatedIDRemove?: boolean;
}) => {
  const finalSampleDataObject = getNewSampleDataForAI({
    sampleData,
    selectedTableData,
    isIDRemove,
    isRelatedIDRemove,
  });
  return JSON.stringify(finalSampleDataObject);
};
const getSampleDataFromColumnSchemaForAI = ({ columnSchema }: { columnSchema: any[] }) => {
  let sampleData = {};
  (columnSchema ?? []).forEach((columnElem) => {
    let value: any = 'Sample';
    if (
      columnElem?.dataType === erpAllDataType.number.key ||
      columnElem?.dataType === erpAllDataType.decimal.key
    ) {
      value = 101;
    }
    if (columnElem?.dataType === erpAllDataType.ID.key) {
      value = 1;
    }
    if (
      columnElem?.dataType === erpAllDataType.date.key ||
      columnElem?.dataType === erpAllDataType.dateTime.key ||
      columnElem?.dataType === erpAllDataType.datePicker.key ||
      columnElem?.dataType === erpAllDataType.time.key
    ) {
      value = new Date();
    }
    if (
      columnElem?.dataType === erpAllDataType.select.key ||
      columnElem?.dataType === erpAllDataType.multipleSelect.key
    ) {
      const selectMenuValue = columnElem?.options?.selectMenu?.[0]?.value ?? value;
      value = selectMenuValue;
    }
    sampleData = { ...sampleData, [columnElem.name]: value };
  });

  return JSON.stringify(sampleData);
};
export const getDefaultCodeForFirstType = ({
  selectTableName,
  connectedTablesData,
  selectedParentId,
  selectedId,
  currentCode,
  buttonType,
  activeButtonKey,
  mainTableID,
}: {
  selectTableName: string;
  connectedTablesData: { [key: string]: any };
  selectedParentId: any;
  selectedId: any;
  currentCode: string;
  buttonType: string;
  activeButtonKey: string;
  mainTableID: string;
}) => {
  const selectedTableData = connectedTablesData?.[selectTableName];
  const textColumnData = (selectedTableData?.columnSchema ?? []).find((columnElem: any) => {
    return columnElem?.dataType === erpAllDataType.text.key;
  });
  const parentId = selectedParentId;
  const relationID = selectedTableData?.relationID;
  const isRealationTypeIsNotMain = selectedTableData?.relationType !== 'MAIN';
  const codeChanged =
    buttonTypeObject.formValidation.key === buttonType
      ? defaultValidationCode !== currentCode
      : defaultCode !== currentCode;

  if (codeChanged) {
    return currentCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.POST.key
  ) {
    const createOpDefaultCode = `
    async function customizeERP(userData, apiOperations) {
    // Your Code Starts Here
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
    const { createOp } = apiOperations;
    return new Promise(async (resolve, reject) => {
        const payload = {
            __d3__newData: {...userData?.__d3__newData }${
              isRealationTypeIsNotMain
                ? `,
                __d3__parentId:userData?.__d3__parentId,
                __d3__relationId:"${relationID ?? `''`}"`
                : ''
            }
          }
        const createdData = await createOp(${
          isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
        },payload);
            if (createdData?.id) {
      resolve(createdData);
    } else {
      reject('Could not able to create data! Please try again later.');
    }
})
}`;
    return createOpDefaultCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.PUT.key
  ) {
    const updateOpDefaultCode = `
    async function customizeERP(userData, apiOperations) {
    // Your Code Starts Here
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
    const { updateOp } = apiOperations;
    return new Promise(async (resolve, reject) => {
            const payload = {
            __d3__id:userData?.__d3__id,
            __d3__newData: {...userData?.__d3__newData } ${
              isRealationTypeIsNotMain
                ? `,
                __d3__parentId:userData?.__d3__parentId,
                __d3__relationId:"${relationID ?? `''`}"`
                : ''
            }
          }
        const updatedData = await updateOp(${
          isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
        },payload);
    if (updatedData?.[0]) {
      resolve(updatedData);
    } else {
      reject('Could not able to update data! Please try again later.');
    }
    })
}`;
    return updateOpDefaultCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.DELETE.key
  ) {
    const deleteOpDefaultCode = `
    async function customizeERP(userData, apiOperations) {
    // Your Code Starts Here
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
    const { removeOp } = apiOperations;
    return new Promise(async (resolve, reject) => {
        const payload ={
            __d3__id:[userData?.__d3__id] ${
              isRealationTypeIsNotMain
                ? `,
                __d3__parentId:userData?.__d3__parentId,
                __d3__relationId:"${relationID ?? `''`}"`
                : ''
            }
          }
        const deletedData = await removeOp(${
          isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
        }, payload);
             if (deletedData?.deletedObjNo) {
      resolve(deletedData);
    } else {
      reject('Could not able to update data! Please try again later.');
    }
      })
}`;
    return deleteOpDefaultCode;
  }
  if (
    buttonTypeObject.CRUD.key === buttonType &&
    activeButtonKey === crudTypeButtonObject.GET.key
  ) {
    const readOpDefaultCode = `
    async function customizeERP(userData, apiOperations) {
    // Your Code Starts Here
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
    const { readOp } = apiOperations;
    return new Promise(async (resolve, reject) => {
         const payload ={
            __d3__filterdata: { where: {
             "columnName": "columnValue"
  }}${
    isRealationTypeIsNotMain
      ? `,
              __d3__parentId:userData?.__d3__parentId,
              __d3__relationId:"${relationID ?? `''`}"`
      : ''
  }}
        const rowsData = await readOp( ${
          isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
        }, payload);
          if (rowsData?.count) {
      resolve(rowsData);
    } else {
      reject('Could not able to read data! Please try again later.');
    }
        })
}`;
    return readOpDefaultCode;
  }
  if (buttonTypeObject.actionButton.key === buttonType) {
    const actionButtonDefaultCode = `
 async function customizeERP(userData, apiOperations) {
  
 // Your Code Starts Here
  const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
      if (userData && userData.length > 0) {
      const returnData = await Promise.all(userData.map(async(row) => {
    return new Promise(async (resolve, reject) => {
            const payload = {
            __d3__id:userData?.__d3__id,
            __d3__newData: {...userData?.__d3__newData } ${
              isRealationTypeIsNotMain
                ? `,
                __d3__parentId:userData?.__d3__parentId,
                __d3__relationId:"${relationID ?? `''`}"`
                : ''
            }
          }
             const updatedData = await updateOp(${
               isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
             },payload);
        if (updatedData?.[0]) {
          resolve(updatedData);
        } else {
          reject('Could not able to update data! Please try again later.');
        }
  })
      }));
    return returnData;
}
}`;
    return actionButtonDefaultCode;
  }
  if (buttonTypeObject.tablewiseAction.key === buttonType) {
    const actionButtonDefaultCode = `
 async function customizeERP(userData, apiOperations) {
  
 // Your Code Starts Here
  const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
      if (userData && userData.length > 0) {
      const returnData = await Promise.all(userData.map(async(row) => {
    return new Promise(async (resolve, reject) => {
            const payload = {
            __d3__id:userData?.__d3__id,
            __d3__newData: {...userData?.__d3__newData } ${
              isRealationTypeIsNotMain
                ? `,
                __d3__parentId:userData?.__d3__parentId,
                __d3__relationId:"${relationID ?? `''`}"`
                : ''
            }
          }
             const updatedData = await updateOp(${
               isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
             },payload);
        if (updatedData?.[0]) {
          resolve(updatedData);
        } else {
          reject('Could not able to update data! Please try again later.');
        }
  })
      }));
    return returnData;
}
}`;
    return actionButtonDefaultCode;
  }
  if (buttonTypeObject.uploadCSV.key === buttonType) {
    const actionButtonDefaultCode = `
 async function customizeERP(userData, apiOperations) {
  
 // Your Code Starts Here
  const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
      if (userData && userData.length > 0) {
      const returnData = await Promise.all(userData.map(async(row) => {
    return new Promise(async (resolve, reject) => {
            const payload = {
            __d3__id:userData?.__d3__id,
            __d3__newData: {...userData?.__d3__newData } ${
              isRealationTypeIsNotMain
                ? `,
                __d3__parentId:userData?.__d3__parentId,
                __d3__relationId:"${relationID ?? `''`}"`
                : ''
            }
          }
             const updatedData = await updateOp(${
               isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
             },payload);
        if (updatedData?.[0]) {
          resolve(updatedData);
        } else {
          reject('Could not able to update data! Please try again later.');
        }
  })
      }));
    return returnData;
}
}`;
    return actionButtonDefaultCode;
  }
  if (buttonTypeObject.cronJob.key === buttonType) {
    const cronJobDefaultCode = `
 async function customizeERP(userData,apiOperations) {
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
	  const payload = {}
    const { readOp } = apiOperations;
    const rowsData = await readOp(${
      isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'
    }, payload)
    if (rowsData?.count) {
      resolve(rowsData);
    } else {
      reject('Could not able to read data! Please try again later.');
    }
}`;
    return cronJobDefaultCode;
  }
  if (buttonTypeObject.formValidation.key === buttonType) {
    const formCustomisationDefaultCode = `function validateData(data) {
    return data;
}

function validateSchema(data) {
    return data;
}

function changeData (data) {
    return data;
}

/**
 * @param {data}: { errors, formData, formSchema }
 */
 async function customizeERP(userData, apiOperations) {
    const { __d3__formData, __d3__error, __d3__schema } = userData
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
    // Your Code Starts Here
    let newData;
    newData = validateData(userData);
    newData = validateSchema(userData);
    newData = changeData (userData);

    return newData;
}`;
    return formCustomisationDefaultCode;
  }
  if (buttonTypeObject.bulkCreate.key === buttonType) {
    const formCustomisationDefaultCode = `function validateData(data) {
    return data;
}

function validateSchema(data) {
    return data;
}

function changeData (data) {
    return data;
}

/**
 * @param {data}: { errors, formData, formSchema }
 */
 async function customizeERP(userData, apiOperations) {
    const { __d3__formData, __d3__error, __d3__schema } = userData
    const ${isRealationTypeIsNotMain ? 'parentTableName' : 'tableName'} = "${mainTableID}";
    // Your Code Starts Here
    let newData;
    newData = validateData(userData);
    newData = validateSchema(userData);
    newData = changeData (userData);

    return newData;
}`;
    return formCustomisationDefaultCode;
  }
  return currentCode;
};
export const getDefaultCodeForTemplate = ({
  selectTableName,
  currentCode,
  templateType,
}: {
  selectTableName: string;
  currentCode: string;
  templateType: string;
}) => {
  const codeChanged = defaultTemplateCode !== currentCode;
  if (codeChanged) {
    return currentCode;
  }
  if (templateType === templateTypeObject.email.key) {
    const actionButtonDefaultCode = ` async function customizeERP(userData, apiOperations) {
    const tableName = "${selectTableName}";
    let recipients = {
		  to: ["examples@domain.com"],
		}
        if (userData && userData.length > 0) {
        const returnData = await Promise.all(userData.map(async(row) => {
              // Your Code Starts Here
          
        }));
     return {
		data: returnData,
            recipients: recipients,
	}

  }
  }`;
    return actionButtonDefaultCode;
  }
  if (templateType === templateTypeObject.pdf.key) {
    const actionButtonDefaultCode = ` async function customizeERP(userData, apiOperations) {
    const tableName = "${selectTableName}";
        if (userData && userData.length > 0) {
        const returnData = await Promise.all(userData.map(async(row) => {
              // Your Code Starts Here
          
        }));
      return returnData;
  }
  }`;
    return actionButtonDefaultCode;
  }
  return currentCode;
};
export const getTableListData = ({
  tableId,
  appDatatable,
  giveAllColumns = false,
}: {
  tableId: string;
  appDatatable: { [key: string]: any };
  giveAllColumns: boolean;
}) => {
  const connectedTablesData = getConnectedTablesNew({
    tableId: tableId,
    appDatatable,
  });
  let relatedTableList: any[] = [];
  Object.keys(connectedTablesData ?? {}).forEach((tableID) => {
    const tableDataElem = tableDataToRelatedTableData({
      tableData: connectedTablesData[tableID],
      giveAllColumns,
    });
    const getInnerTableList = ({
      innerBelongsToTable,
      nestingLevel,
    }: {
      innerBelongsToTable: { [key: string]: any };
      nestingLevel: number;
    }) => {
      let innerTableList: any[] = [];
      Object.keys(innerBelongsToTable ?? {}).forEach((innerBelongsToElemKey: string) => {
        const innerBelongsToElem = innerBelongsToTable[innerBelongsToElemKey];
        const tableDataElem = tableDataToRelatedTableData({
          tableData: innerBelongsToElem,
          giveAllColumns,
        });

        let updatedTableDataElem: { [key: string]: any } = { ...tableDataElem };
        if (nestingLevel > 0) {
          const innerBelongsToTable = getInnerTableList({
            innerBelongsToTable: innerBelongsToElem?.innerBelongsToTable ?? {},
            nestingLevel: nestingLevel - 1,
          });
          updatedTableDataElem = {
            ...updatedTableDataElem,
            tableList: innerBelongsToTable,
          };
        }

        innerTableList = [...innerTableList, updatedTableDataElem];
      });
      return innerTableList;
    };
    let innerTableList: any[] = getInnerTableList({
      innerBelongsToTable: connectedTablesData?.[tableID]?.innerBelongsToTable ?? {},
      nestingLevel: relationNestedLevel - 2,
    });

    relatedTableList = [...relatedTableList, { ...tableDataElem, tableList: innerTableList }];
  });
  return relatedTableList;
};
export const getDefaultViewElem = ({ columnData }: { columnData: { [key: string]: any } }) => {
  return {
    columnName: columnData?.columnName ?? '',
    filters: {},
    name: columnData?.name ?? '',
    dataType: columnData?.dataType ?? '',
    description: '',
  };
};
export const tableDataToRelatedTableData = ({
  tableData,
  giveAllColumns = false,
}: {
  tableData: any;
  giveAllColumns?: boolean;
}) => {
  let defaultView: any[] = [];
  (tableData?.columnSchema ?? []).forEach((elem: any) => {
    const isStartWithD3 = (elem?.columnName ?? '').startsWith('__d3__');
    if (!isStartWithD3) {
      // for belongsTo Table add only belongsTo type columns
      if (tableData?.relationType === 'belongsTo' && !giveAllColumns) {
        if (elem?.dataType === 'belongsTo') {
          defaultView = [
            ...defaultView,
            getDefaultViewElem({ columnData: elem }),
            // {
            //   columnName: elem?.columnName ?? '',
            //   filters: {},
            //   name: elem?.name ?? '',
            //   description: '',
            // },
          ];
        }
      } else {
        // otherWise add all type of columns
        defaultView = [
          ...defaultView,
          getDefaultViewElem({ columnData: elem }),
          // {
          //   columnName: elem?.columnName ?? '',
          //   filters: {},
          //   name: elem?.name ?? '',
          //   description: '',
          // },
        ];
      }
    }
  });

  const parentRelationID = tableData?.parentRelationID
    ? { parentRelationID: tableData?.parentRelationID }
    : {};
  const parentRelationIDList = tableData?.parentRelationIDList
    ? { parentRelationIDList: tableData?.parentRelationIDList }
    : {};
  if (tableData?.relationType === 'belongsTo') {
    const id = {
      columnName: 'id',
      filters: {},
      name: 'id',
      description: '',
    };
    return {
      defaultView: [id, ...defaultView],
      screenName: tableData?.screenName,
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      relationID: tableData?.relationID,
      description: '',
      ...parentRelationID,
      ...parentRelationIDList,
    };
  } else if (tableData?.relationType === 'MAIN') {
    return {
      defaultView: defaultView ?? [],
      tableID: tableData?.tableID,
      relationType: tableData?.relationType,
      // relationID: tableData?.relationID,
      ...parentRelationIDList,
      ...parentRelationID,
    };
  }
  return {
    defaultView: defaultView ?? [],
    relationType: tableData?.relationType,
    tableID: tableData?.tableID,
    screenName: tableData?.screenName,
    relationID: tableData?.relationID,
    description: '',
    ...parentRelationID,
    ...parentRelationIDList,
  };
};
export const getConnectedTablesNew = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: any;
}) => {
  let connectedTableList: { [key: string]: any } = {};
  const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
    tableId,
    appDatatable,
  });

  if (finalTabeData?.tableID) {
    const finalTableID = getFinalTableID({
      tableID: finalTabeData?.tableID,
    });
    // Added main table to the connectedTableList object
    connectedTableList = {
      ...connectedTableList,
      [finalTableID]: {
        ...finalTabeData,
        relationType: 'MAIN',
        screenName: finalTabeData?.name,
        nameWithRelationType: `${finalTabeData?.name} ( MAIN )`,
      },
    };
    const getConnectedTableList = ({
      belongsToTableId,
      finalConectedTableId,
      parentRelationIDList,
      nestingLevel,
    }: {
      belongsToTableId?: any[];
      finalConectedTableId?: any[];
      parentRelationIDList?: string[];
      nestingLevel: number;
    }) => {
      let connectedTableList: { [key: string]: any } = {};
      // adding belongs to relation of main table to the connectedTableList object
      (belongsToTableId ?? []).forEach(
        ({ tableId, relationType, relationID, relationName, screenName }: any) => {
          const { belongsToTableId } = getInnerData({
            tableId,
            appDatatable,
          });

          // if (!connectedTableList[tableId]) {
          const finalTableID = getFinalTableID({
            tableID: tableId,
            relationType: relationType,
            relationID: relationID,
          });

          // adding belongsToTables of belongs to table related to main table
          let innerBelongsToTable: { [key: string]: any } = {};
          (belongsToTableId ?? []).forEach(
            ({
              tableId,
              relationType,
              relationID: innerRelationID,
              screenName,
              relationName,
            }: any) => {
              const finalTableID = getFinalTableID({
                tableID: tableId,
                relationType: relationType,
                relationID: innerRelationID,
              });
              innerBelongsToTable = {
                ...innerBelongsToTable,
                [finalTableID]: {
                  ...(appDatatable[tableId] ?? {}),
                  relationType,
                  relationID: innerRelationID,
                  parentRelationID: relationID,
                  relationName,
                  screenName,
                  nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                },
              };
            }
          );
          const columnSchema = appDatatable[tableId]?.columnSchema ?? [];
          const filterBelongsToTable = columnSchema.filter((elem: any) => {
            return elem?.dataType !== 'belongsTo';
          });
          let tableElem = {
            ...(appDatatable[tableId] ?? {}),
            columnSchema: filterBelongsToTable,
            relationType,
            relationID,
            relationName,
            screenName,
            nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
          };
          // if (nestingLevel > 0) {
          //   const innerBelongsToTable = getConnectedTableList({
          //     belongsToTableId,
          //     parentRelationIDList: [relationID, ...(parentRelationIDList ?? [])],
          //     nestingLevel: nestingLevel - 1,
          //   });
          //   tableElem = {
          //     ...tableElem,
          //     innerBelongsToTable: innerBelongsToTable,
          //   };
          // }

          if (parentRelationIDList?.[0]) {
            tableElem = {
              ...tableElem,
              parentRelationID: parentRelationIDList?.[0],
              parentRelationIDList,
            };
          }
          connectedTableList = {
            ...connectedTableList,
            [finalTableID]: tableElem,
          };

          // }
        }
      );

      // adding hasMany, hasOne, belongsToMany related tables to the connectedTableList object
      (finalConectedTableId ?? []).forEach(
        ({ tableId, relationType, relationID, screenName, relationName }: any) => {
          const { finalTabeData, belongsToTableId } = getInnerData({
            tableId,
            appDatatable,
          });
          if (finalTabeData?.tableID) {
            if (!connectedTableList[finalTabeData?.tableID]) {
              const finalTableID = getFinalTableID({
                tableID: finalTabeData?.tableID,
                relationType: relationType,
                relationID: relationID,
              });

              // adding belongsTo related tables of the related tables( hasMany, hasOne, belongsTOmany) that is related to main table; Thus making it to level 3
              let connectedTableBelongsToTables: { [key: string]: any } = {};

              (belongsToTableId ?? []).forEach(
                ({
                  tableId,
                  relationType,
                  relationID: innerRelationID,
                  screenName,
                  relationName,
                }: any) => {
                  // if (!connectedTableList[tableId]) {
                  const finalTableID = getFinalTableID({
                    tableID: tableId,
                    relationType: relationType,
                    relationID: innerRelationID,
                  });
                  connectedTableBelongsToTables = {
                    ...connectedTableBelongsToTables,
                    [finalTableID]: {
                      ...(appDatatable[tableId] ?? {}),
                      relationType,
                      relationName,
                      relationID: innerRelationID,
                      parentRelationID: relationID,
                      screenName,
                      nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                    },
                  };
                  // }
                }
              );
              let tableElem: { [key: string]: any } = {
                ...(finalTabeData ?? {}),
                relationType,
                relationID,
                relationName,
                screenName,
                nameWithRelationType: `${
                  appDatatable[finalTabeData?.tableID]?.name
                } ( ${relationType} )`,
              };
              if (nestingLevel > 0) {
                const innerBelongsToTable = getConnectedTableList({
                  belongsToTableId,
                  parentRelationIDList: [relationID, ...(parentRelationIDList ?? [])],
                  nestingLevel: nestingLevel - 1,
                });
                tableElem = { ...tableElem, innerBelongsToTable: innerBelongsToTable };
              }
              if (parentRelationIDList?.[0]) {
                tableElem = {
                  ...tableElem,
                  parentRelationID: parentRelationIDList?.[0],
                  parentRelationIDList,
                };
              }
              connectedTableList = {
                ...connectedTableList,

                [finalTableID]: tableElem,
              };
            }
          }
        }
      );
      return connectedTableList;
    };
    const innerConnectedTableList = getConnectedTableList({
      belongsToTableId,
      finalConectedTableId,
      nestingLevel: relationNestedLevel - 1,
    });
    connectedTableList = { ...connectedTableList, ...innerConnectedTableList };
  }

  return connectedTableList;
};
export const getConnectedTablesNew2 = ({
  tableId,
  appDatatable,
}: {
  tableId: string;
  appDatatable: any;
}) => {
  let connectedTableList: { [key: string]: any } = {};
  const { finalTabeData, finalConectedTableId, belongsToTableId } = getInnerData({
    tableId,
    appDatatable,
  });

  if (finalTabeData?.tableID) {
    const finalTableID = getFinalTableID({
      tableID: finalTabeData?.tableID,
    });
    // Added main table to the connectedTableList object
    connectedTableList = {
      ...connectedTableList,
      [finalTableID]: {
        ...finalTabeData,
        relationType: 'MAIN',
        screenName: finalTabeData?.name,
        nameWithRelationType: `${finalTabeData?.name} ( MAIN )`,
      },
    };
    // adding belongs to relation of main table to the connectedTableList object
    (belongsToTableId ?? []).forEach(
      ({ tableId, relationType, relationID, relationName, screenName }: any) => {
        const { belongsToTableId } = getInnerData({
          tableId,
          appDatatable,
        });

        // if (!connectedTableList[tableId]) {
        const finalTableID = getFinalTableID({
          tableID: tableId,
          relationType: relationType,
          relationID: relationID,
        });
        // adding belongsToTables of belongs to table related to main table
        let innerBelongsToTable: { [key: string]: any } = {};
        (belongsToTableId ?? []).forEach(
          ({
            tableId,
            relationType,
            relationID: innerRelationID,
            screenName,
            relationName,
          }: any) => {
            // if (!connectedTableList[tableId]) {
            const finalTableID = getFinalTableID({
              tableID: tableId,
              relationType: relationType,
              relationID: innerRelationID,
            });
            innerBelongsToTable = {
              ...innerBelongsToTable,
              [finalTableID]: {
                ...(appDatatable[tableId] ?? {}),
                relationType,
                relationID: innerRelationID,
                parentRelationID: relationID,
                relationName,
                screenName,
                nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
              },
            };
            // }
          }
        );
        connectedTableList = {
          ...connectedTableList,
          [finalTableID]: {
            ...(appDatatable[tableId] ?? {}),
            relationType,
            relationID,
            relationName,
            innerBelongsToTable,
            screenName,
            nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
          },
        };
        // }
      }
    );

    // adding hasMany, hasOne, belongsToMany related tables to the connectedTableList object
    (finalConectedTableId ?? []).forEach(
      ({ tableId, relationType, relationID, screenName, relationName }: any) => {
        const { finalTabeData, belongsToTableId } = getInnerData({
          tableId,
          appDatatable,
        });
        if (finalTabeData?.tableID) {
          if (!connectedTableList[finalTabeData?.tableID]) {
            const finalTableID = getFinalTableID({
              tableID: finalTabeData?.tableID,
              relationType: relationType,
              relationID: relationID,
            });

            // adding belongsTo related tables of the related tables( hasMany, hasOne, belongsTOmany) that is related to main table; Thus making it to level 3
            let connectedTableBelongsToTables: { [key: string]: any } = {};

            (belongsToTableId ?? []).forEach(
              ({
                tableId,
                relationType,
                relationID: innerRelationID,
                screenName,
                relationName,
              }: any) => {
                // if (!connectedTableList[tableId]) {
                const finalTableID = getFinalTableID({
                  tableID: tableId,
                  relationType: relationType,
                  relationID: innerRelationID,
                });
                connectedTableBelongsToTables = {
                  ...connectedTableBelongsToTables,
                  [finalTableID]: {
                    ...(appDatatable[tableId] ?? {}),
                    relationType,
                    relationName,
                    relationID: innerRelationID,
                    parentRelationID: relationID,
                    screenName,
                    nameWithRelationType: `${appDatatable[tableId]?.name} ( ${relationType} )`,
                  },
                };
                // }
              }
            );

            connectedTableList = {
              ...connectedTableList,
              [finalTableID]: {
                ...finalTabeData,
                relationType,
                relationID,
                relationName,
                screenName,
                innerBelongsToTable: connectedTableBelongsToTables,
                nameWithRelationType: `${
                  appDatatable[finalTabeData?.tableID]?.name
                } ( ${relationType} )`,
              },
            };
          }
        }
      }
    );
  }
  return connectedTableList;
};
const specialDataType = ['hasOne', 'hasMany', 'belongsToMany'];
export const getFinalTableID = ({
  tableID,
  relationID,
  relationType,
}: {
  tableID: string;
  relationID?: string;
  relationType?: string;
}) => {
  if (!relationType || !relationID) {
    const finalTableID = `${tableID}`;
    return finalTableID;
  }
  const finalTableID = `${tableID}-${relationType}-${relationID}`;
  return finalTableID;
};
export const getInnerData = ({ tableId, appDatatable }: { tableId: string; appDatatable: any }) => {
  let finalTabeData: { [key: string]: any } = {};
  let finalConectedTableId: any[] = [];
  let belongsToTableId: any[] = [];
  const tableData = appDatatable[tableId];
  if (tableData) {
    finalTabeData = tableData;
    if (tableData?.relations) {
      Object.values(tableData?.relations ?? {}).forEach((tableElem: any) => {
        const isSpecialDataType = specialDataType.some((elem) => elem === tableElem?.type);
        if (isSpecialDataType) {
          const tableData = appDatatable?.[tableElem?.target];
          if (tableData) {
            finalConectedTableId = [
              ...finalConectedTableId,
              {
                tableId: tableElem?.target,
                relationName: tableElem?.relationName,
                relationType: tableElem?.type,
                screenName: `${tableElem?.target}`,
                relationID: tableElem?.as,
                // screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
        if ('belongsTo' === tableElem?.type) {
          const belongsToTableData = appDatatable?.[tableElem?.target];
          if (belongsToTableData) {
            belongsToTableId = [
              ...belongsToTableId,
              {
                tableId: tableElem?.target,
                relationType: tableElem?.type,
                relationID: tableElem?.as,
                screenName: `${tableElem?.target}`,
                relationName: tableElem?.relationName,
                // screenName: `Screen - ${tableElem?.target}`,
              },
            ];
          }
        }
      });
    }
  }

  return { finalTabeData, finalConectedTableId, belongsToTableId };
};

interface removedTableHandlingFunctionProps {
  componentTableList: { [key: string]: any }[];
  defaultTableList: { [key: string]: any }[];
}
export const removedTableHandlingFunction = ({
  componentTableList,
  defaultTableList,
}: removedTableHandlingFunctionProps) => {
  if (!componentTableList) {
    return defaultTableList;
  }
  let newTableList: { [key: string]: any }[] = [];
  (componentTableList ?? []).forEach((tableElem: any) => {
    const selectedDefaultTableList = (defaultTableList ?? []).find(
      (defaultTableListElem: { [key: string]: any }) => {
        return defaultTableListElem?.relationID === tableElem?.relationID;
      }
    );

    if (selectedDefaultTableList) {
      const newDefaultView = (tableElem?.defaultView ?? []).filter(
        (tableElemDefaultViewElem: { [key: string]: any }) => {
          let isPresent = false;
          isPresent = (selectedDefaultTableList?.defaultView ?? []).some(
            (selectedDefaultTableListDefaultViewElem: { [key: string]: any }) => {
              return (
                selectedDefaultTableListDefaultViewElem?.columnName ===
                tableElemDefaultViewElem?.columnName
              );
            }
          );
          if (!isPresent) {
            isPresent = (tableElem?.relatedFields ?? []).some(
              (relatedFieldElem: { [key: string]: any }) => {
                return relatedFieldElem?.relatedFieldId === tableElemDefaultViewElem?.columnName;
              }
            );
          }
          return isPresent;
        }
      );
      let newInnerTableList: { [key: string]: any }[] = [];
      (tableElem?.tableList ?? []).forEach((tableElem: { [key: string]: any }) => {
        const selectedDefaultInnerTableList = (selectedDefaultTableList?.tableList).find(
          (defaultTableListElem: { [key: string]: any }) => {
            return defaultTableListElem?.relationID === tableElem?.relationID;
          }
        );
        if (selectedDefaultInnerTableList) {
          const newDefaultView = (tableElem?.defaultView ?? []).filter(
            (tableElemDefaultViewElem: { [key: string]: any }) => {
              const isPresent = (selectedDefaultInnerTableList?.defaultView ?? []).some(
                (selectedDefaultTableListDefaultViewElem: { [key: string]: any }) => {
                  return (
                    selectedDefaultTableListDefaultViewElem?.columnName ===
                    tableElemDefaultViewElem?.columnName
                  );
                }
              );
              return isPresent;
            }
          );
          newInnerTableList = [...newInnerTableList, { ...tableElem, defaultView: newDefaultView }];
        }
      });
      newTableList = [
        ...newTableList,
        { ...tableElem, defaultView: newDefaultView, tableList: newInnerTableList },
      ];
    }
  });
  return newTableList;
};

export const getAppComponentFilterColumnSchema = ({
  connectedTablesData,
  groupedConnectedTablesData = {},
  tableData,
  finalTableID,
}: {
  connectedTablesData: { [key: string]: any };
  groupedConnectedTablesData: { [key: string]: any };
  tableData: any;
  finalTableID: string;
}) => {
  let allColumnSchema: any[] = [];
  let connectedTableElem: null | { [key: string]: any } = null;
  let addedConnectedTables: { [key: string]: any } = {};
  if (tableData?.isInnerTable) {
    const selectedOuterTable = connectedTablesData?.[tableData?.outerTableID];
    connectedTableElem = selectedOuterTable?.innerBelongsToTable?.[finalTableID];
  } else {
    connectedTableElem = connectedTablesData?.[finalTableID];
  }

  if (connectedTableElem) {
    const tableName = connectedTableElem?.tableID;
    let relationData: { [key: string]: any } = { relationType: connectedTableElem?.relationType };
    if (connectedTableElem?.relationType !== 'MAIN') {
      relationData = {
        ...relationData,
        relationID: connectedTableElem?.relationID,
      };
    }
    const newColumnSchema = (connectedTableElem?.columnSchema ?? [])?.map(
      (columnSchemaElem: any) => ({
        ...columnSchemaElem,
        tableName: tableName,
        ...relationData,
      })
    );
    allColumnSchema = [...allColumnSchema, ...newColumnSchema];
    addedConnectedTables = {
      ...addedConnectedTables,
      [tableName]: connectedTableElem,
    };
    const getInnerSchema = ({
      innerBelongsToTable,
      nestedLevel,
      addedConnectedTables,
    }: {
      innerBelongsToTable: any;
      addedConnectedTables: { [key: string]: any };
      nestedLevel: number;
    }) => {
      let allColumnSchema: { [key: string]: any }[] = [];
      let innerAddedConnectedTables: { [key: string]: any } = { ...(addedConnectedTables ?? {}) };
      Object.keys(innerBelongsToTable ?? {}).forEach((innerBelongsToTableElemKey: string) => {
        const innerBelongsToTableElem = innerBelongsToTable?.[innerBelongsToTableElemKey];
        const tableName = innerBelongsToTableElem?.tableID;
        if (innerAddedConnectedTables[tableName]) {
          return;
        }

        innerAddedConnectedTables = {
          ...innerAddedConnectedTables,
          [tableName]: innerBelongsToTableElem,
        };
        let relationData: { [key: string]: any } = {
          relationType: innerBelongsToTableElem?.relationType,
        };
        const newColumnSchema = (innerBelongsToTableElem?.columnSchema ?? [])?.map(
          (columnSchemaElem: any) => ({
            ...columnSchemaElem,
            tableName: tableName,
            ...relationData,
          })
        );
        allColumnSchema = [...allColumnSchema, ...newColumnSchema];
        if (nestedLevel > 0) {
          const { finalColumnsSchema, finalAddedConnectedTables } = getInnerSchema({
            innerBelongsToTable: innerBelongsToTableElem?.innerBelongsToTable ?? {},
            nestedLevel: nestedLevel - 1,
            addedConnectedTables: innerAddedConnectedTables,
          });
          allColumnSchema = [...allColumnSchema, ...finalColumnsSchema];
          innerAddedConnectedTables = {
            ...innerAddedConnectedTables,
            ...finalAddedConnectedTables,
          };
        }
      });

      return {
        finalColumnsSchema: allColumnSchema,
        finalAddedConnectedTables: innerAddedConnectedTables,
      };
    };
    if (connectedTableElem?.relationType === 'MAIN') {
      const { finalColumnsSchema, finalAddedConnectedTables } = getInnerSchema({
        innerBelongsToTable: groupedConnectedTablesData ?? {},
        addedConnectedTables,
        nestedLevel: relationNestedLevel - 1,
      });
      allColumnSchema = [...allColumnSchema, ...finalColumnsSchema];
      addedConnectedTables = {
        ...addedConnectedTables,
        ...finalAddedConnectedTables,
      };
    } else {
      const { finalColumnsSchema, finalAddedConnectedTables } = getInnerSchema({
        innerBelongsToTable: connectedTableElem?.innerBelongsToTable ?? {},
        nestedLevel: relationNestedLevel - 2,
        addedConnectedTables,
      });
      allColumnSchema = [...allColumnSchema, ...finalColumnsSchema];
      addedConnectedTables = {
        ...addedConnectedTables,
        ...finalAddedConnectedTables,
      };
    }
  }

  return allColumnSchema;
};
export const getSelectTableFinalKey = ({
  tableId,
  relationID,
  innerRelationID,
  parentRelationIDList,
}: {
  tableId: string;
  relationID?: string;
  innerRelationID?: string;
  parentRelationIDList?: string[];
}) => {
  if (parentRelationIDList?.[0]) {
    // const revesedParentRelationIDList = parentRelationIDList?.reverse();
    const revesedParentRelationIDList = parentRelationIDList;
    const finalTableKey = `${tableId}${relationID ? `~${relationID}` : ''}${
      revesedParentRelationIDList?.[0] ? `~${revesedParentRelationIDList.join('~')}` : ''
    }`;
    return finalTableKey;
  }
  const finalTableKey = `${tableId}${relationID ? `~${relationID}` : ''}${
    innerRelationID ? `~${innerRelationID}` : ''
  }`;
  return finalTableKey;
};

export const getGroupOptionData = ({
  connectedTablesData,
  nestedLevel,
}: {
  connectedTablesData: { [key: string]: any };
  nestedLevel: number;
}) => {
  let updatedConnectedTablesData = {};
  let groupOptionData = {};
  Object.keys(connectedTablesData ?? {}).forEach((selectedTableKey) => {
    const selectedTableData = connectedTablesData[selectedTableKey];
    let innerGroupOptionData = {};
    if (nestedLevel > 0) {
      const { groupOptionData, updatedConnectedTablesData: innerUpdatedConnectedTablesData } =
        getGroupOptionData({
          connectedTablesData: selectedTableData?.innerBelongsToTable ?? {},
          nestedLevel: nestedLevel - 1,
        });
      innerGroupOptionData = groupOptionData;
      updatedConnectedTablesData = {
        ...updatedConnectedTablesData,
        ...innerUpdatedConnectedTablesData,
      };
    }
    const tableId = selectedTableData?.tableID;
    const relationID = selectedTableData?.relationID;
    const innerRelationID = selectedTableData?.innerRelationID;
    const finalTableKey = getSelectTableFinalKey({
      tableId: tableId,
      relationID: relationID,
      innerRelationID: innerRelationID,
      parentRelationIDList: selectedTableData?.parentRelationIDList,
    });
    groupOptionData = {
      ...groupOptionData,
      [finalTableKey]: {
        title: selectedTableData?.nameWithRelationType,
        name: selectedTableData?.nameWithRelationType,
        key: finalTableKey,
        options: {
          ...innerGroupOptionData,
        },
      },
    };
    updatedConnectedTablesData = {
      ...updatedConnectedTablesData,
      [finalTableKey]: selectedTableData,
    };
  });
  return { groupOptionData: groupOptionData, updatedConnectedTablesData };
};
