import { mls } from 'lib/multilanguagesupport';
import React, { useState } from 'react';
import styles from './editTableSettingsPopup.module.scss';
import { toast } from 'react-toastify';
import ColumnSettings from './ColumnSettings/ColumnSettings';
import { defaultColumnSchema } from '../data/erpData';

interface addNewColumnProps {
  handleAddColumn: Function;
  dataTableSchema: any;
  tableUpdates: any;
  setTableUpdates: Function;
}

const AddNewColumn = ({
  handleAddColumn,
  dataTableSchema,
  tableUpdates,
  setTableUpdates,
}: addNewColumnProps) => {
  const [isNewColumn, setIsNewColumn] = useState(false);
  const [columnData, setColumnData] = useState(defaultColumnSchema);
  const handleAddNewColumnButton = () => {
    setIsNewColumn(true);
  };
  const handleResetColumnData = () => {
    setIsNewColumn(false);
    setColumnData(defaultColumnSchema);
  };
  const handleTableUpdate = (tableUpdateData: any) => {
    setTableUpdates(tableUpdateData);
  };
  const handleTableUpdateByKey = ({ columnData }: { columnData: { [key: string]: any } }) => {
    let addColumns = tableUpdates?.addColumns ?? [];
    addColumns = [...addColumns, columnData];
    handleTableUpdate({
      ...tableUpdates,
      addColumns: addColumns,
    });
  };
  const addColumn = () => {
    if (!columnData?.columnName) {
      toast.error(mls('Please Enter Column Name!'));
      return;
    }
    handleAddColumn(columnData);
    handleTableUpdateByKey({ columnData });
    handleResetColumnData();
  };

  return (
    <div>
      {isNewColumn ? (
        <div className={styles.addNewColumnData}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '1rem',
            }}
          >
            <button
              onClick={() => addColumn()}
              className='btn btn-sm btn-primary mt-4'
              style={{ width: 120 }}
              type='button'
            >
              {mls('Add column')}
            </button>
            &nbsp; &nbsp;
            <button
              onClick={() => handleResetColumnData()}
              className='btn btn-sm btn-secondary mt-4'
              type='button'
              style={{ width: 120 }}
            >
              {mls('Cancel')}
            </button>
          </div>
          <ColumnSettings
            dataTableSchema={dataTableSchema}
            columnData={columnData}
            setColumnData={setColumnData}
            tableUpdates={tableUpdates}
            setTableUpdates={() => {}}
          >
            <>
              {/* <div>
              <button
                onClick={() => addColumn()}
                className='btn btn-sm btn-primary mt-4'
                style={{ width: 120 }}
                type='button'
              >
                {mls('Add column')}
              </button>
              &nbsp; &nbsp;
              <button
                onClick={() => handleResetColumnData()}
                className='btn btn-sm btn-secondary mt-4'
                type='button'
                style={{ width: 120 }}
              >
                {mls('Cancel')}
              </button>
            </div> */}
            </>
          </ColumnSettings>

          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              marginBottom: '1rem',
            }}
          >
            <button
              onClick={() => addColumn()}
              className='btn btn-sm btn-primary mt-4'
              style={{ width: 120 }}
              type='button'
            >
              {mls('Add column')}
            </button>
            &nbsp; &nbsp;
            <button
              onClick={() => handleResetColumnData()}
              className='btn btn-sm btn-secondary mt-4'
              type='button'
              style={{ width: 120 }}
            >
              {mls('Cancel')}
            </button>
          </div>
        </div>
      ) : (
        <button
          onClick={handleAddNewColumnButton}
          className='btn btn-sm btn-primary mt-4'
          style={{ width: 120 }}
        >
          {mls('Add column')}
        </button>
      )}
    </div>
  );
};

export default AddNewColumn;
