import clsx from 'clsx';
import { mls } from 'lib/multilanguagesupport';

export const OtpForm = ({ formik }) => {
  // // The OTP field was being captured as touched by last step of entering email, and was showing green tick from mounting.
  // // Setting the field as untouched manually to display the green tick correctly
  // useEffect(() => {
  //   formik.setFieldTouched('OTP', false);
  // }, []);

  return (
    <div className='fv-row mb-5'>
      <label className='form-label fw-bolder text-gray-900 fs-6'>{mls('OTP')}</label>
      <input
        type='number'
        placeholder='Enter OTP'
        onWheel={(e) => e.target.blur()}
        autoComplete='off'
        maxLength={6}
        {...formik.getFieldProps('OTP')}
        className={clsx(
          'form-control form-control-lg form-control-solid',
          { 'is-invalid': formik.touched.OTP && formik.errors.OTP }
          // {
          //   'is-valid': formik.touched.OTP && !formik.errors.OTP,
          // }
        )}
      />
      {formik.touched.OTP && formik.errors.OTP && (
        <div className='fv-plugins-message-container'>
          <div className='fv-help-block'>
            <span role='alert' className='text-danger'>
              {mls(formik.errors.OTP)}
            </span>
          </div>
        </div>
      )}
      <br />
      {/* <div className='primaryButtonFlexDiv'>
          <button
            // onClick={() => checkOtp({ OTP: formik.getFieldProps('OTP')?.value ?? '' })}
            type='submit'
            className='primaryButton'
            // disabled={formik.isSubmitting || !formik.isValid}
          >
            {mls('Submit')}
            {loading ? (
              <>
                &nbsp; &nbsp;
                <CircularProgress size={10} color='inherit' />
              </>
            ) : null}
          </button>
          <button
            type='button'
            onClick={() => handleBack()}
            className='primaryButton secondaryButtonColor'
          >
            {mls('Back')}
          </button>
        </div> */}
    </div>
  );
};
