import React from 'react';
import styles from './relatedFields.module.scss';
import { mls } from 'lib/multilanguagesupport';
import { defaultDataType } from 'lib/appComponent/componentSettings/RoleComponentFilter/data/defaultFilterSchema';
import { relationTypeObjects } from 'lib/appComponent/componentSettings/data/appComponentData';
import ArrayDropDown from 'lib/reusable-components/Components/DropDowns/ArrayDropDown';
import { BiX } from 'react-icons/bi';

interface relatedFiledDropDownProps {
  tableName: string;
  elem: { [key: string]: any };
  nestIndex: number;
  handleColumnValue: Function;
  allDataTableSchema: { [key: string]: any };
  relationType: string;
  mainTable: string;
}
const RelatedFiledDropDown = ({
  tableName,
  elem,
  nestIndex,
  handleColumnValue,
  allDataTableSchema,
  relationType,
  mainTable,
}: relatedFiledDropDownProps) => {
  const getColumnData = ({
    tableName,
    allDataTableSchema,
  }: {
    tableName: string;
    allDataTableSchema: { [key: string]: any };
  }) => {
    const selecteTable = allDataTableSchema[tableName];
    let selectedColumns: { [key: string]: any }[] = [];
    let relationColumns: { [key: string]: any }[] = [];
    Object.keys(selecteTable?.relations ?? {}).forEach((relationKey) => {
      const realationData = selecteTable?.relations[relationKey];
      // if (
      //   relationType === relationTypeObjects.hasMany.key ||
      //   relationType === relationTypeObjects.belongsToMany.key ||
      //   relationType === relationTypeObjects.hasOne.key
      // ) {
      //   if (
      //     realationData?.type === relationTypeObjects.belongsTo.key &&
      //     realationData?.target === mainTable
      //   ) {
      //     relationColumns = [
      //       ...relationColumns,
      //       {
      //         name: `${realationData?.relationName} ( ${realationData?.type} )`,
      //         relationId: realationData?.as,
      //         columnName: `${realationData?.relationName}`,
      //         dataType: realationData?.type,
      //       },
      //     ];
      //   }
      //   return;
      // }
      if (realationData?.type === relationTypeObjects.belongsTo.key) {
        relationColumns = [
          ...relationColumns,
          {
            name: `${realationData?.relationName} ( ${realationData?.type} )`,
            relationId: realationData?.as,
            columnName: `${realationData?.relationName}`,
            dataType: realationData?.type,
          },
        ];
      }
      // relationColumns = [
      //   ...relationColumns,
      //   {
      //     name: `${realationData?.relationName} ( ${realationData?.type} )`,
      //     relationId: realationData?.as,
      //     columnName: `${realationData?.relationName}`,
      //     dataType: realationData?.type,
      //   },
      // ];
    });
    // (selecteTable?.columnSchema ?? []).forEach((columnElem: { [key: string]: any }) => {
    //   if (
    //     // columnElem?.dataType === defaultDataType.belongsTo ||
    //     columnElem?.dataType === defaultDataType.user ||
    //     columnElem?.dataType === defaultDataType.multipleUsers
    //   ) {
    //     selectedColumns = [
    //       ...selectedColumns,
    //       { ...columnElem, name: `${columnElem?.name} ( ${columnElem?.dataType} )` },
    //     ];
    //   }
    // });

    return { selecteTable: selecteTable, columnData: [...relationColumns, ...selectedColumns] };
  };
  const { selecteTable, columnData } = getColumnData({ tableName: tableName, allDataTableSchema });

  const relatedFieldArray = elem?.relatedFieldArray ?? [];

  const setdropDownValue = (columnName: string) => {
    const selectedColumnData = columnData.find((elem) => elem?.columnName === columnName);
    if (
      selectedColumnData?.dataType === defaultDataType.belongsTo ||
      selectedColumnData?.dataType === relationTypeObjects.belongsTo.key ||
      selectedColumnData?.dataType === relationTypeObjects.belongsToMany.key ||
      selectedColumnData?.dataType === relationTypeObjects.hasMany.key ||
      selectedColumnData?.dataType === relationTypeObjects.hasOne.key
    ) {
      const valueElem = {
        relationId: selectedColumnData?.relationId,
        columnName: selectedColumnData?.columnName,
        dataType: selectedColumnData?.dataType,
        tableName,
      };
      const items = Array.from(relatedFieldArray);
      items.splice(nestIndex);

      handleColumnValue([...items, valueElem]);
    } else if (selectedColumnData?.dataType === defaultDataType.user) {
      const valueElem = {
        // relationId: selectedColumnData?.relationId,
        columnName: selectedColumnData?.columnName,
        dataType: selectedColumnData?.dataType,
        isUserID: true,
        tableName,
        // userIdColumnKey: '',
      };
      const items = Array.from(relatedFieldArray);
      items.splice(nestIndex);
      handleColumnValue([...items, valueElem]);
    } else if (selectedColumnData?.dataType === defaultDataType.multipleUsers) {
      const valueElem = {
        relationId: selectedColumnData?.relationId,
        columnName: selectedColumnData?.columnName,
        dataType: selectedColumnData?.dataType,
        isUserID: true,
        userIdColumnKey: selectedColumnData?.idField,
      };
      const items = Array.from(relatedFieldArray);
      items.splice(nestIndex);
      handleColumnValue([...items, valueElem]);
    }
  };
  const selectedValue = relatedFieldArray[nestIndex];

  const selectedTableName =
    selecteTable?.relations?.[selectedValue?.relationId]?.type ===
    relationTypeObjects.belongsToMany.key
      ? selecteTable?.relations?.[selectedValue?.relationId]?.target
      : selecteTable?.relations?.[selectedValue?.relationId]?.target;
  const removeColumn = () => {
    const items = Array.from(relatedFieldArray);
    items.splice(nestIndex);
    handleColumnValue([...items]);
  };
  const isRemove =
    selectedValue?.columnName &&
    !(
      relationType === relationTypeObjects.hasMany.key ||
      relationType === relationTypeObjects.belongsToMany.key ||
      relationType === relationTypeObjects.hasOne.key
    );
  return (
    <>
      <div
        className={`${styles.dropDownOuterContainer} ${
          isRemove ? styles.dropDownOuterContainerIsRemove : ''
        }`}
      >
        <div className={styles.dropDownContainer}>
          <ArrayDropDown
            array={columnData}
            dropDownValue={selectedValue?.columnName ?? ''}
            setdropDownValue={setdropDownValue}
            nameKey={'name'}
            mainKey={'columnName'}
            inputPlaceHolder={mls('Related Field')}
            ObjectInArray
          />
        </div>
        {isRemove ? (
          <div onClick={() => removeColumn()} className={styles.removeButton}>
            <BiX />
          </div>
        ) : null}
      </div>
      {selectedValue?.relationId ? (
        <>
          {selectedValue?.isUserID ? (
            <></>
          ) : (
            <>
              <RelatedFiledDropDown
                tableName={selectedTableName}
                elem={elem}
                nestIndex={nestIndex + 1}
                handleColumnValue={handleColumnValue}
                allDataTableSchema={allDataTableSchema}
                mainTable={mainTable}
                relationType={selectedValue?.dataType}
              />
            </>
          )}
        </>
      ) : null}
    </>
  );
};

export default RelatedFiledDropDown;
