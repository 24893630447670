export const selectMenuColorPalettes = [
  '#e6b8af', //new
  '#f4cccc', //new
  '#e7e7e7',
  '#fff2cc',
  '#d9ead3',
  '#d0e0e3',
  '#c9daf8',
  '#cfe2f3',
  '#d9d2e9',
  '#ead1dc',
  '#f3f3f3',
  '#d9d9d9',
  // '#ffffff', //new
];
export const selectMenuColorPalettes2 = [
  // '#FFFF00',
  '#009ef7', //new
  '#c4f119', //new
  '#FF0000',
  '#0000FF',
  '#008000',
  '#00FFFF',
  '#008080',
  '#3F00FF',
  '#000080',
  '#800020',
  '#800080',
  '#FF00FF',
  // '#FFA500',
  '#0d76cf', //new
  '#E0B0FF',
  '#7F00FF',
  // '#FFD700',
  '#cf30c2', //new
];
