import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useFormik } from 'formik';
import { EmailForm } from './components/EmailForm';
import { OtpForm } from './components/OtpForm';
import { SetPasswordForm } from './components/SetPasswordForm';
import { forgotPasswordSchema, passwordSchema } from './schema/Schema';
import { mls } from 'lib/multilanguagesupport';
import { _supistaApiPost } from 'lib/server-connection/connections';
// import { supistaApiPost } from './network/connections';

const initialValues = {
  email: '',
  OTP: '',
  setPassword: '',
  confirmPassword: '',
};

export function ForgotPassword() {
  const appID = 'supista';
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [OTPsent, setOTPsent] = useState(false);
  const [OTPvalidation, setOTPvalidation] = useState(false);
  const [hasErrors, setHasErrors] = useState(undefined);
  const formik = useFormik({
    initialValues,
    validationSchema: OTPvalidation ? passwordSchema : forgotPasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true);
      const emailData = {
        data: {
          emailID: values.email,
        },
      };
      const otpData = {
        data: {
          OTP: values.OTP,
        },
      };
      const passwordData = {
        data: {
          newPassword: values.setPassword,
          confirmNewPassword: values.confirmPassword,
        },
      };
      if (!OTPsent && !OTPvalidation) {
        _supistaApiPost(`auth/${appID}/requestOTP`, emailData)
          .then((response) => {
            setLoading(false);
            setSubmitting(false);
            setOTPsent(true);
            setHasErrors(false);
          })
          .catch(() => {
            setLoading(false);
            setSubmitting(false);
            setHasErrors(true);
          });
      } else if (OTPsent && !OTPvalidation) {
        _supistaApiPost(`auth/${appID}/validateOTP`, {
          data: {
            OTP: otpData.data.OTP.toString(),
          },
        })
          .then((response) => {
            // console.log(response);
            setStatus(response.message);
            setLoading(false);
            setHasErrors(false);
            if (response.hasOwnProperty('__d3__success')) setOTPvalidation(true);
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
            setStatus('The OTP is incorrect');
          });
      } else {
        _supistaApiPost(`auth/${appID}/setNewPassword`, passwordData)
          .then((response) => {
            // console.log(response);
            setStatus(response.message);
            setLoading(false);
            setHasErrors(false);
            if (response.hasOwnProperty('__d3__success')) history.push('/auth/login');
          })
          .catch(() => {
            setHasErrors(true);
            setLoading(false);
            setSubmitting(false);
          });
      }
    },
  });

  return (
    <>
      <form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='text-center mb-10'>
          {/* begin::Title */}
          {OTPsent && !OTPvalidation ? (
            <h1 className='text-dark mb-3'>{mls('Enter OTP')}</h1>
          ) : OTPvalidation ? (
            <h1 className='text-dark mb-3'>{mls('Enter new password')}</h1>
          ) : (
            <h1 className='text-dark mb-3'>{mls('Forgot Password ?')}</h1>
          )}
          {/* end::Title */}

          {/* begin::Link */}
          {OTPsent && !OTPvalidation ? (
            <div className='text-gray-400 fw-bold fs-4'>
              {mls('Enter the OTP to reset your password')}
            </div>
          ) : OTPvalidation ? (
            <div className='text-gray-400 fw-bold fs-4'>{mls('Enter your new password.')}</div>
          ) : (
            <div className='text-gray-400 fw-bold fs-4'>
              {mls('Enter your email to reset your password.')}
            </div>
          )}
          {/* end::Link */}
        </div>

        {formik.status && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>{mls(formik.status)}</div>
          </div>
        )}

        {/* begin::Title */}
        {hasErrors === true && (
          <div className='mb-lg-15 alert alert-danger'>
            <div className='alert-text font-weight-bold'>
              {mls('Sorry, looks like there are some errors detected, please try again.')}
            </div>
          </div>
        )}

        {hasErrors === false && !OTPvalidation && (
          <div className='mb-10 bg-light-info p-8 rounded'>
            <div className='text-info'>
              {mls('Sent the OTP to reset password. Please check your email')}
            </div>
          </div>
        )}
        {/* end::Title */}

        {/* begin::Form group */}
        {!OTPsent && !OTPvalidation && <EmailForm formik={formik} />}
        {/* end::Form group */}

        {/* begin::Form group */}
        {OTPsent && !OTPvalidation && <OtpForm formik={formik} />}
        {/* end::Form group */}

        {/* begin::Form group */}
        {OTPvalidation && <SetPasswordForm formik={formik} />}
        {/* end::Form group */}

        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-sm btn-primary fw-bolder me-4'
          >
            {loading ? (
              <>
                <span
                  className='spinner-border spinner-border-sm me-2'
                  role='status'
                  aria-hidden='true'
                ></span>
                {mls('Please Wait...')}
              </>
            ) : (
              <span className='indicator-label'>{mls('Submit')}</span>
            )}
          </button>
          <Link to='/auth/login'>
            <button
              type='button'
              id='kt_login_password_reset_form_cancel_button'
              className='btn btn-sm btn-light-primary fw-bolder'
              disabled={formik.isSubmitting || !formik.isValid}
            >
              {mls('Cancel')}
            </button>
          </Link>{' '}
        </div>
        {/* end::Form group */}
      </form>
    </>
  );
}
