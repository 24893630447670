export const defaultCode = `async function customizeERP(userData, apiOperations) {
    // Your Code Starts Here

}`;
export const defaultValidationCode = `function validateData(data) {
    return data;
}

function validateSchema(data) {
    return data;
}

function changeData (data) {
    return data;
}

/**
 * @param {data}: { errors, formData, formSchema }
 */
 async function customizeERP(userData, apiOperations) {
    const { __d3__formData, __d3__error, __d3__schema } = userData
    // Your Code Starts Here
    let newData;
    newData = validateData(userData);
    newData = validateSchema(userData);
    newData = changeData (userData);

    return newData;
}`;
export const crudOpType = {
  DEFAULT: {
    key: 'DEFAULT',
    name: 'DEFAULT',
  },
  CUSTOM: {
    key: 'CUSTOM',
    name: 'CUSTOM',
  },
};
export const relationTypeObjects = {
  hasOne: {
    key: 'hasOne',
    name: 'hasOne',
  },
  hasMany: { key: 'hasMany', name: 'hasMany' },
  belongsTo: { key: 'belongsTo', name: 'belongsTo' },
  belongsToMany: { key: 'belongsToMany', name: 'belongsToMany' },
};

export const buttonTypeObject = {
  CRUD: {
    key: 'CRUD',
    name: 'CRUD Button',
  },
  actionButton: {
    key: 'actionButton',
    name: 'Action Button',
  },
  tablewiseAction: {
    key: 'tablewiseAction',
    name: 'Tablewise Action Button',
  },
  uploadCSV: {
    key: 'uploadCSV',
    name: 'Upload CSV',
  },
  bulkCreate: {
    key: 'bulkCreate',
    name: 'Bulk Create',
  },
  cronJob: {
    key: 'cronJob',
    name: 'Scheduler',
  },
  formValidation: {
    key: 'formValidation',
    name: 'Form Customization',
  },
};
export const componenetType = {
  table: {
    key: 'table',
    name: 'Table',
  },
  calendar: {
    key: 'calendar',
    name: 'Calendar',
  },
  kanban: {
    key: 'kanban',
    name: 'Kanban',
  },
};

export const crudTypeButtonObject = {
  GET: {
    key: 'GET',
    label: 'Read',
  },
  POST: {
    key: 'POST',
    label: 'Create',
  },
  PUT: {
    key: 'PUT',
    label: 'Update',
  },
  DELETE: {
    key: 'DELETE',
    label: 'Delete',
  },
  // FORMVALIDATION: {
  //   key: 'FORMVALIDATION',
  //   label: 'Form Validation',
  // },
};
export const columnBooleanTypeObject = {
  AllTrueWithExc: {
    key: 'AllTrueWithExc',
    name: 'All True With Exc',
  },
  AllFalseWithExc: {
    key: 'AllFalseWithExc',
    name: 'All False With Exc',
  },
};
export const defaultTeamAccessObject = {
  type: columnBooleanTypeObject?.AllTrueWithExc?.key,
  ColumnWiseRoles: [],
  filter: {},
};

export const filterTypeObject = {
  teamFilter: {
    key: 'teamFilter',
  },
  columnFilter: {
    key: 'columnFilter',
  },
};

export const codeContainerTypes = {
  code: {
    key: 'code',
    name: 'Code',
  },
  input: {
    key: 'input',
    name: 'Input',
    isLowerResolutionTab: true,
  },
  output: {
    key: 'output',
    name: 'Output',
  },
};
export const outputTypeObject = {
  error: {
    key: 'error',
    class: 'errorColorClassIndicator',
  },
  success: {
    key: 'success',
    class: 'successColorClassIndicator',
  },
  normal: {
    key: 'normal',
    class: 'normalColorClassIndicator',
  },
};
export const defaultOutputData = { msg: {}, outputType: outputTypeObject.normal };
export const specialDataTypeName = {
  MAIN: {
    key: 'MAIN',
    name: 'Main Table',
  },
  belongsTo: {
    key: 'belongsTo',
    name: 'Belongs To Relation',
  },

  hasMany: {
    key: 'hasMany',
    name: 'Has Many Relation',
  },
  hasOne: {
    key: 'hasOne',
    name: 'Has One Relation',
  },
  belongsToMany: {
    key: 'belongsToMany',
    name: 'Belongs To Many Relation',
  },
};
export const specialDataType = [
  relationTypeObjects.hasOne.key,
  relationTypeObjects.hasMany.key,
  relationTypeObjects.belongsToMany.key,
  relationTypeObjects.belongsTo.key,
];
export const actionButtonColorPalettes = [
  '#e6b8af', //new
  '#f4cccc', //new
  '#e7e7e7',
  '#fff2cc',
  '#d9ead3',
  '#d0e0e3',
  '#c9daf8',
  '#cfe2f3',
  '#d9d2e9',
  '#ead1dc',
  '#f3f3f3',
  '#d9d9d9',
  // '#ffffff', //new
];
